// Inspired from Stackoverflow
// https://stackoverflow.com/questions/53071774/reactjs-delay-onchange-while-typing
import { useEffect, useState } from 'react';

export function useDebounce<T>(currentValue: T, delay = 0): T {
    const [debounceValue, setDebounceValue] = useState<T>(currentValue);

    useEffect(() => {
        const timeoutId = setTimeout(
            () => setDebounceValue(currentValue),
            delay,
        );
        return () => clearTimeout(timeoutId);
    }, [currentValue, delay]);

    return debounceValue;
}
