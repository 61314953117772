import type { BrowserPlugin } from '@snowplow/browser-tracker-core';
import {
    buildStructEvent,
    PayloadBuilder,
    StructuredEvent,
} from '@snowplow/tracker-core';
import { getElementId, getEventTarget } from '../utils';

export function TripletexSectionPlugin(): BrowserPlugin {
    return {
        activateBrowserPlugin: (tracker) => {
            const handleClick: EventListener = (event) => {
                const payloadBuilder = getPayloadBuilderFromClickEvent(event);

                if (payloadBuilder) {
                    tracker.core.track(payloadBuilder);
                }
            };

            document.addEventListener('click', handleClick);
        },
    };
}

export function getPayloadBuilderFromClickEvent(
    event: Event,
): PayloadBuilder | void {
    const target = getEventTarget(event);
    const section = target
        ?.closest<HTMLDivElement>('.tlxSection-head')
        ?.closest<HTMLDivElement>('.section');

    if (section) {
        return buildStructEvent(getSectionToggleEvent(section));
    }
}

export function getSectionToggleEvent(section: HTMLElement): StructuredEvent {
    // The section has already collapsed by the time we get the event
    const collapse = section.classList.contains('collapsed');

    return {
        category: 'section',
        action: collapse ? 'collapse' : 'expand',
        label: getElementId(section),
    };
}
