export type ProjectDTO = {
    id: number;
    name: string;
    number: string;
    customer?: {
        name: string;
    };
    projectManager?: {
        firstName: string;
        lastName: string;
    };
};

export const projectCategory = 'project';
