import { offset, shift, size, useFloating } from '@floating-ui/react';
import { Icon, Portal } from '@tlx/atlas';
import classNames from 'classnames';
import React, {
    CSSProperties,
    forwardRef,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useComposedRefs } from '../Topbar/useComposedRefs';
import { CompanyLogo } from './CompanyLogo';
import { getFilteredCompanies } from './getFilteredCompanies';
import { getNextListItemFromEventKeyName } from './getNextListItemFromEventKeyName';
import { CompanyDTO } from './types';

export type CompanyChooserProps = {
    companies: CompanyDTO[];
    currentCompany: CompanyDTO;
    disabled?: boolean;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export function CompanyChooser({
    companies,
    currentCompany,
    disabled,
    open,
    setOpen,
}: CompanyChooserProps) {
    const { formatMessage } = useIntl();
    const buttonRef = useRef<HTMLButtonElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);

    const floating = useFloating({
        placement: 'bottom-start',
        middleware: [
            size({
                apply({ availableWidth, elements }) {
                    elements.floating.style.maxWidth = `${availableWidth}px`;
                },
            }),
            shift({ padding: 8 }),
            offset(8),
        ],
    });

    const popupPositioningStyle: CSSProperties = {
        position: floating.strategy,
        top: floating.y ?? '',
        left: floating.x ?? '',
    };

    const composedButtonRef = useComposedRefs(
        floating.refs.setReference,
        buttonRef,
    );
    const composedPopupRef = useComposedRefs(
        floating.refs.setFloating,
        popupRef,
    );

    useLayoutEffect(() => {
        if (open) {
            floating.update();
        }
        // Recalculate the position of the popup when the data changes,
        // because the data could change the width of the popup.
        // floating isn't a stable reference
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, companies]);

    return (
        <>
            <CompanyChooserButton
                ref={composedButtonRef}
                currentCompany={currentCompany}
                disabled={disabled}
                onClick={() => setOpen((open) => !open)}
                aria-expanded={open}
                aria-label={formatMessage({
                    id: open
                        ? 'text_close_company_chooser'
                        : 'text_open_company_chooser',
                })}
            />
            <CompanyChooserPopup
                ref={composedPopupRef}
                style={popupPositioningStyle}
                hidden={!open}
                onKeyDown={(event) => {
                    // Focus button after pressing escape key
                    if (event.key === 'Escape') {
                        buttonRef.current?.focus();
                        setOpen(false);
                    }
                }}
                onBlur={(event) => {
                    // Blur only if we actually clicked outside the popup or the button
                    if (
                        !(
                            buttonRef.current?.contains(event.relatedTarget) ||
                            popupRef.current?.contains(event.relatedTarget)
                        )
                    ) {
                        setOpen(false);
                    }
                }}
            >
                {open && (
                    <CompanyChooserListBox
                        companies={companies}
                        currentCompanyId={currentCompany.id}
                    />
                )}
            </CompanyChooserPopup>
        </>
    );
}

export const CompanyChooserPopup = forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement>
>(function CompanyChooserPopup(props, ref) {
    return (
        <Portal>
            <div
                ref={ref}
                className="tlx-top-bar-company-chooser-popup atl-bg-white atl-p-4 atl-rounded"
                data-testid="company-chooser-popup"
                {...props}
            />
        </Portal>
    );
});

type CompanyChooserButtonProps =
    React.ButtonHTMLAttributes<HTMLButtonElement> & {
        currentCompany: CompanyDTO;
    };

export const CompanyChooserButton = forwardRef<
    HTMLButtonElement,
    CompanyChooserButtonProps
>(function CompanyChooserButton({ currentCompany, ...props }, ref) {
    return (
        <button
            ref={ref}
            aria-haspopup="true"
            data-testid="company-chooser-button"
            className="atl-flex atl-items-center atl-rounded tlx-company-chooser__button"
            {...props}
        >
            {currentCompany.parent && (
                <CompanyLogo
                    name={currentCompany.parent.displayName}
                    className="tlx-company-chooser__companyLogo"
                />
            )}
            <CompanyLogo
                name={currentCompany.displayName}
                className="tlx-company-chooser__companyLogo"
            />

            <CompanyName
                isSuspended={currentCompany.isSuspended}
                name={currentCompany.displayName}
                className="tlx-company-chooser__companyName tlx-company-chooser-button__companyName"
            />
        </button>
    );
});

export function CompanyChooserListBox({
    companies,
    currentCompanyId,
}: {
    companies: CompanyDTO[];
    currentCompanyId: number;
}) {
    const [query, setQuery] = useState('');
    const filteredCompanies = useMemo(
        () => getFilteredCompanies(companies, query),
        [companies, query],
    );

    const [activeCompanyId, setActiveCompanyId] = useState(currentCompanyId);
    const inputRef = useRef<HTMLInputElement>(null);

    function handleSubmit(event: React.FormEvent) {
        event.preventDefault();

        const activeCompany = filteredCompanies.find(
            (company) => company.id === activeCompanyId,
        );

        const firstCompany =
            filteredCompanies.length === 1 ? filteredCompanies[0] : undefined;

        const company = activeCompany || firstCompany;

        if (company !== undefined) {
            window.open(company.url, '_blank');
        }
    }

    function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
        const key = `${event.shiftKey ? 'Shift' : ''}${event.key}`;
        const company = getNextListItemFromEventKeyName(
            filteredCompanies,
            activeCompanyId,
            key,
        );

        // Don't move cursor in input when pressing up/down or lose focus when tabbing
        if (
            [
                'ArrowUp',
                'ArrowDown',
                'PageUp',
                'PageDown',
                'Home',
                'End',
                'ShiftTab',
                'Tab',
            ].includes(key)
        ) {
            event.preventDefault();
        }

        if (company) {
            setActiveCompanyId(company.id);
        }
    }

    useEffect(() => {
        inputRef.current?.focus();
    }, []);

    return (
        <>
            <CompanyChooserSearchForm
                ref={inputRef}
                value={query}
                onChange={(event) => setQuery(event.target.value)}
                onSubmit={handleSubmit}
                onKeyDown={handleKeyDown}
                aria-activedescendant={`company-chooser-item-${activeCompanyId}`}
            />
            <CompanyList>
                {companies.length > 0 ? (
                    filteredCompanies.map((company) => (
                        <CompanyListItem
                            key={company.id}
                            company={company}
                            isActive={company.id === activeCompanyId}
                            isCurrent={company.id === currentCompanyId}
                            isFiltered={query.length > 0}
                        />
                    ))
                ) : (
                    <>
                        <CompanyListItemSkeleton />
                        <CompanyListItemSkeleton />
                        <CompanyListItemSkeleton />
                    </>
                )}
            </CompanyList>
        </>
    );
}

export const CompanyChooserSearchForm = forwardRef<
    HTMLInputElement,
    React.InputHTMLAttributes<HTMLInputElement> & {
        onSubmit?: React.FormEventHandler<HTMLFormElement>;
    }
>(function CompanyChooserSearchForm({ onSubmit, ...props }, ref) {
    const { formatMessage } = useIntl();

    return (
        <form onSubmit={onSubmit} autoComplete="off">
            <input
                ref={ref}
                type="search"
                placeholder={formatMessage({ id: 'text_search_for_company' })}
                role="combobox"
                aria-autocomplete="list"
                aria-haspopup="listbox"
                aria-controls="company-chooser-list"
                className="atl-w-full atl-mb-4 atl-rounded atl-text-sm atl-bg-bg tlx-top-bar-company-chooser-popup__input"
                data-testid="company-chooser-search-input"
                {...props}
            />
        </form>
    );
});

function CompanyName({
    name,
    className,
    isSuspended,
}: {
    name: string;
    className?: string;
    isSuspended: boolean;
}) {
    const cssClassNames = classNames(
        'tlx-company-chooser__companyName',
        isSuspended && 'atl-text-grey-60',
        className,
    );
    return (
        <div className={cssClassNames} title={name}>
            {name}
        </div>
    );
}

export function CompanyList(props: React.HTMLAttributes<HTMLDivElement>) {
    return (
        <div
            className="atl-m-0 atl-p-0 tlx-top-bar-company-chooser-popup__list"
            id="company-chooser-list"
            role="listbox"
            aria-label="Companies"
            {...props}
        />
    );
}

export function CompanyListItem({
    company,
    isActive,
    isCurrent,
    isFiltered,
}: {
    company: CompanyDTO;
    isActive?: boolean;
    isCurrent?: boolean;
    isFiltered?: boolean;
}) {
    const ref = useRef<HTMLAnchorElement>(null);

    useEffect(() => {
        if (isActive) {
            ref.current?.scrollIntoView({ block: 'nearest' });
        }
    }, [isActive]);

    return (
        <a
            ref={ref}
            href={company.url}
            id={`company-chooser-item-${company.id}`}
            aria-selected={isActive}
            aria-current={isCurrent}
            role="option"
            target="_blank"
            tabIndex={-1}
            className="atl-flex atl-items-center atl-px-8 atl-rounded tlx-company-list__item"
            onMouseDown={(event) => {
                // Don't trigger blur when clicking link
                event.preventDefault();
            }}
        >
            {company.parent && (
                <CompanyLogo
                    name={company.parent.displayName}
                    className="tlx-company-chooser__companyLogo"
                    hidden={!isFiltered}
                />
            )}

            <CompanyLogo
                name={company.displayName}
                className="tlx-company-chooser__companyLogo"
            />

            {isFiltered && company.parent && (
                <CompanyName
                    name={company.parent.displayName}
                    isSuspended={company.isSuspended}
                />
            )}

            <CompanyName
                name={company.displayName}
                isSuspended={company.isSuspended}
                className="atl-mr-auto"
            />
            <Icon className="atl-ml-8 atl-text-grey-60 tlx-company-list__icon">
                open_in_new
            </Icon>
        </a>
    );
}

export function CompanyListItemSkeleton() {
    return (
        <div className="atl-flex atl-items-center atl-px-8 tlx-company-list__item tlx-company-list__item--skeleton">
            <div className="atl-rounded-full tlx-company-logo tlx-company-list__skeleton" />
            <div className="atl-flex-auto atl-rounded atl-ml-8 tlx-company-list__skeleton tlx-company-list__skeleton--name" />
        </div>
    );
}
