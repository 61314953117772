import {
    HTTPError,
    createAPIRequest,
    createAPIResponse,
} from '@tlx/astro-shared';
import { useCallback, useEffect, useRef, useState } from 'react';

export const SUMMARY_FETCH_SIZE = 3;
export const DRILL_DOWN_FETCH_SIZE = 10;

export function useApiSearch<T>(
    endpoint: string,
    query: string,
    searchParams?: URLSearchParams,
    // can be overriden for testing purposes
    // defaults to window.fetch if no override provided
    fetch = window.fetch,
) {
    const [response, setResponse] = useState<{
        loading: boolean;
        hasMore: boolean;
        initialData: T[] | undefined;
        subsequentData: T[] | undefined;
    }>({
        loading: false,
        hasMore: false,
        initialData: undefined,
        subsequentData: undefined,
    });
    // keeps a reference to the current search,
    // so we can cancel it if the query changes
    const currentSearch = useRef<{ cancel: () => void }>();
    const from = useRef(0);

    // a search closure which can be cancelled
    const doSearch = useCallback(
        (
            apiEndpoint: string,
            userQuery: string,
            from: number,
            count: number,
            extraParams?: URLSearchParams,
        ) => {
            currentSearch.current?.cancel();

            if (userQuery.trim() === '') {
                return;
            }

            let cancelled = false;

            async function fetchData() {
                setResponse((response) => ({
                    initialData: from === 0 ? undefined : response.initialData,
                    // if this is a drill-down search (from > 0), keep the data
                    subsequentData:
                        from > 0 ? response.subsequentData : undefined,
                    loading: true,
                    hasMore: false,
                }));

                try {
                    // append pagination params to the given request parameters
                    const params = new URLSearchParams(extraParams);
                    params.set('count', count.toString());
                    params.set('from', from.toString());
                    params.set('query', userQuery);

                    const urlWithParams = `${apiEndpoint}?${params}`;
                    const request = createAPIRequest(urlWithParams);
                    const fetchResponse = await fetch(request);
                    const responseBody = await createAPIResponse<{
                        values: T[];
                        from: number;
                        fullResultSize: number;
                        count: number;
                    }>(request, fetchResponse);

                    // only update the state if this search was not cancelled
                    if (!cancelled) {
                        // if the "from" index is bigger than 0, this is not the first page we
                        // are fetching for this query, therefore isDrillDown will be true
                        const isALoadMoreCall = from > 0;

                        // hasMore has to be computed in the frontend, since the backend
                        // doesn't offer a way get this information easily
                        const hasMore =
                            responseBody.count > 0 &&
                            responseBody.from + responseBody.count <
                                responseBody.fullResultSize;

                        // update the state with the new data and reset the loading flag
                        setResponse((currentResponse) => {
                            // if we have data use it, otherwise use an empty array
                            let subsequentData: T[] | undefined =
                                currentResponse.subsequentData;
                            let initialData: T[] | undefined =
                                currentResponse.initialData;

                            if (isALoadMoreCall) {
                                subsequentData = [
                                    ...(currentResponse.subsequentData || []),
                                    ...responseBody.values,
                                ];
                            } else {
                                initialData = responseBody.values;
                            }

                            return {
                                loading: false,
                                initialData,
                                subsequentData,
                                hasMore,
                            };
                        });
                    }
                } catch (error) {
                    // if there was an error, reset the data to undefined
                    setResponse({
                        loading: false,
                        initialData: undefined,
                        subsequentData: undefined,
                        hasMore: false,
                    });

                    // We don't care about 401 errors, since they are expected when a user is unauthorized
                    if (isAuthorizationError(error)) {
                        return;
                    }

                    // Log other execptions to Sentry
                    window.Sentry?.captureException(error);
                }
            }

            // trigger the fetching
            fetchData();

            return {
                cancel: () => {
                    cancelled = true;
                },
            };
        },
        [],
    );

    const loadMore = () => {
        // if the response is not finished loading, do nothing and exit
        if (response.loading) {
            return;
        }

        // and start the search
        currentSearch.current = doSearch(
            endpoint,
            query,
            from.current === 0 ? SUMMARY_FETCH_SIZE : from.current,
            from.current === 0
                ? DRILL_DOWN_FETCH_SIZE - SUMMARY_FETCH_SIZE
                : DRILL_DOWN_FETCH_SIZE,
            searchParams,
        );

        // increase the current page index
        from.current += DRILL_DOWN_FETCH_SIZE;
    };

    const goBack = () => {
        // nothing to go back to
        if (from.current === 0) {
            return;
        }

        // reset the "from" index
        from.current = 0;

        // cancel any running search
        currentSearch.current?.cancel();

        // revert the response to the first page
        setResponse((response) => ({
            hasMore: true, // can be set to true since we are going back from a drill-down
            loading: false,
            initialData: response.initialData,
            subsequentData: undefined,
        }));
    };

    // when the query changes
    useEffect(() => {
        // reset the "from" index
        from.current = 0;

        // and start the search
        currentSearch.current = doSearch(
            endpoint,
            query,
            from.current,
            SUMMARY_FETCH_SIZE,
            searchParams,
        );
        return () => {
            // as a cleanup cancel the running search
            currentSearch.current?.cancel();
        };
    }, [query, searchParams, endpoint, doSearch]);

    let data: T[] | undefined;
    if (response.initialData !== undefined) {
        data = [];
        data.push(...response.initialData);
        if (response.subsequentData !== undefined) {
            data.push(...response.subsequentData);
        }
    }

    return {
        loading: response.loading,
        hasMore: response.hasMore,
        data,
        loadMore,
        goBack,
    };
}

function isAuthorizationError(error: unknown): boolean {
    return error instanceof HTTPError && error.response.status === 401;
}
