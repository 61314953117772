// Basically <a href> but instead of directly navigating, we dispatch tlx:navigate
// which can be handled somewhere else in the code, and perhaps preventDefault()-ed.
// That would typically be used to implement soft navigation.
export function Link({
    href,
    children,
    onClick: additionalOnClick,
    ...props
}: React.HTMLProps<HTMLAnchorElement>) {
    function onClick(ev: React.MouseEvent<HTMLAnchorElement>) {
        // If the <Link> tag has its own onClick handler, we call that one first.
        additionalOnClick?.(ev);
        if (props.target === '_blank') {
            return;
        }

        const navigateEvent = new CustomEvent('tlx:navigate', {
            detail: {
                href,
                shouldRestoreFilter: getLinkShouldRestoreFilter(ev),
            },
            cancelable: true,
        });

        // Do not refresh the page when users use ctrl/cmd to open the link in a new tab.
        if (!ev.ctrlKey && !ev.metaKey) {
            window.dispatchEvent(navigateEvent);
        }

        // Some other component in the system (maybe frameless) might have
        // intercepted and cancelled the custom event - in that case, we
        // should cancel our event too.
        if (navigateEvent.defaultPrevented) {
            ev.preventDefault();
        }
    }

    // TRIP-31728: If the link has a data-restore-filter attribute, the monolith/micro-frontends handle it
    // See this PR: https://bitbucket.org/tripletex/tripletex/pull-requests/24683
    // Example: Favorites menu items should not have this data attribute, since the favorites URLs should not be overridden by the filter
    function getLinkShouldRestoreFilter(
        event: React.MouseEvent<HTMLAnchorElement>,
    ): boolean {
        return (
            event.target instanceof HTMLAnchorElement &&
            'restoreFilter' in event.target.dataset
        );
    }

    return (
        <a {...props} href={href} onClick={onClick}>
            {children}
        </a>
    );
}
