import React, { useState } from 'react';
import { useDebounce } from '../../hooks/useDebounce';

export function useDebouncedState(
    initialValue = '',
    delay = 0,
): [string, React.Dispatch<React.SetStateAction<string>>] {
    const [currentValue, setCurrentValue] = useState(initialValue);
    const debounceValue = useDebounce(currentValue, delay);

    return [
        currentValue !== '' ? debounceValue : currentValue,
        setCurrentValue,
    ];
}
