import type { BrowserPlugin } from '@snowplow/browser-tracker-core';
import { buildStructEvent, PayloadBuilder } from '@snowplow/tracker-core';

const EVENTS: Record<string, string | undefined> = {
    'tour-me-cancel': 'cancel',
    'tour-me-finish': 'finish',
    'tour-me-run': 'run',
    'tour-me-step': 'step',
};

type TourMeEvent = CustomEvent<{ tourId: string }>;

export function TourMePlugin(): BrowserPlugin {
    return {
        activateBrowserPlugin: (tracker) => {
            const handleEvent: EventListener = (event) => {
                const payloadBuilder = getPayloadBuilderFromTourMeEvent(event);

                if (payloadBuilder) {
                    tracker.core.track(payloadBuilder);
                }
            };

            for (const event of Object.keys(EVENTS)) {
                window.addEventListener(event, handleEvent);
            }
        },
    };
}

export function getPayloadBuilderFromTourMeEvent(
    event: Event,
): PayloadBuilder | void {
    if (!isTourMeEvent(event)) {
        return;
    }

    return buildStructEvent({
        category: 'tour-me',
        action: getAction(event),
        label: event.detail.tourId,
    });
}

function isTourMeEvent(event: Event): event is TourMeEvent {
    return event.type in EVENTS;
}

function getAction(event: TourMeEvent): string {
    return event.type.substring(event.type.lastIndexOf('-') + 1);
}
