import { createAPIRequest } from '@tlx/astro-shared';
import { useEffect, useRef, useState } from 'react';

export type UseFetchReturn<T> = {
    data?: T;
    error?: Error;
    refresh(optimisticNewValue?: T): void;
    ready: boolean;
};

export function useFetch<T>(url: string | null): UseFetchReturn<T> {
    const [ready, setReady] = useState(false);
    const [data, setData] = useState<T | undefined>();
    const [error, setError] = useState<Error | undefined>();
    const unmounted = useRef(false);

    async function doFetch(url: string) {
        try {
            const response = await window.fetch(createAPIRequest(url));
            if (response.status === 404) {
                setError(new Error('Not found'));
                setReady(true);
                return;
            }
            const data = await response.json();

            if (!unmounted.current) {
                setData(data.value);
                setReady(true);
            }
        } catch (error) {
            if (!unmounted.current) {
                if (error instanceof Error) {
                    setError(error);
                } else {
                    setError(new Error(String(error)));
                }
                setReady(true);
            }
        }
    }

    useEffect(() => {
        unmounted.current = false;

        if (url === null) {
            return;
        }

        doFetch(url);

        return () => {
            unmounted.current = true;
        };
    }, [url]);

    function refresh(temporaryNewValue?: T) {
        if (url === null) {
            return;
        }

        if (temporaryNewValue !== undefined) {
            setData(temporaryNewValue);
        }

        doFetch(url);
    }

    return { data, error, refresh, ready };
}
