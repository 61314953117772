import { ListResponse, createAPIRequest, fetcher } from '@tlx/astro-shared';
import { useEffect } from 'react';
import useSWR from 'swr';
import { BANNERS_UPDATE } from '../../events';
import { BannerDTO } from './types';

export type UseBannerReturn = {
    ready: boolean;
    banner: BannerDTO | undefined;
    onCancelBanner: (id: number) => void;
};
export function useBanner(): UseBannerReturn {
    const { data, mutate, isLoading } = useSWR<ListResponse<BannerDTO>>(
        '/v2/banner/active',
        fetcher,
    );

    useEffect(() => {
        function refresh() {
            mutate();
        }
        document.addEventListener(BANNERS_UPDATE, refresh);
        return () => document.removeEventListener(BANNERS_UPDATE, refresh);
    }, [mutate]);

    return {
        ready: !isLoading,
        banner: data?.values[0], // Response type is list response, but the backend will only return one banner
        async onCancelBanner(id: number) {
            const request = createAPIRequest(`/v2/banner/${id}/:cancel`, {
                method: 'put',
            });
            await fetch(request);
            mutate();
        },
    };
}
