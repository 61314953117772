import { ListResponse } from '@tlx/astro-shared';
import { useState } from 'react';
import { notifications } from './notifications';
import { NotificationDTO } from './types';

// Messages/notifications are paginated in a way similar to other paginated APIs in Tripletex.
// But the pagination is slightly different; it's based on lastSeenMessageId instead of from/count.
// (We also have to call the :updateReadCursor API endpoint after fetching messages.)
export function getLastSeenMessageId(
    pages: ListResponse<{ id: number }>[],
): number | undefined {
    const notifications = pages.map((page) => page.values).flat();
    if (notifications.length > 0) {
        return notifications[notifications.length - 1].id;
    }
    return undefined;
}

export function hasMore<T>(pages: ListResponse<T>[]) {
    if (pages.length === 0) {
        return true;
    }
    const lastPage = pages[pages.length - 1];
    return lastPage.fullResultSize > lastPage.from + lastPage.count;
}

export function useMessagesFromServer(): {
    messages: NotificationDTO[];
    hasMore: boolean;
    loadMore(): void;
    reset(): void;
    empty(): void;
} {
    const [isLoading, setIsLoading] = useState(false);
    const [pages, setPages] = useState<ListResponse<NotificationDTO>[]>([]);
    const messages: NotificationDTO[] = pages.map((page) => page.values).flat();

    function reset() {
        setPages([]);
    }

    function empty() {
        notifications.updateHiddenCursor().then(() => {
            setPages([]);
        });
    }

    function loadMore() {
        const lastSeenMessageId = getLastSeenMessageId(pages);
        setIsLoading(true);
        notifications
            .getMessagesFromServer(lastSeenMessageId)
            .then((newMessages) => {
                setIsLoading(false);
                setPages([...pages, newMessages]);
            });
    }

    const state = {
        reset,
        hasMore: !isLoading && hasMore(pages),
        loadMore,
        messages,
        empty,
    };

    return state;
}
