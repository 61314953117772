import { useState, useEffect } from 'react';
import useSWR from 'swr';

async function fetchVersion(url: string): Promise<string | null> {
    const response = await window.fetch(url, { method: 'HEAD' });
    const version = response.headers.get('etag');

    return version;
}

export function useVersionCheck() {
    const [currentVersion, setCurrentVersion] = useState<string | null>(null);
    const url = import.meta.url;
    const { data: version } = useSWR(url, fetchVersion, {
        fallbackData: null,
        refreshInterval: 10 * 60 * 1000, // 10 minutes
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    });

    useEffect(() => {
        if (version !== currentVersion) {
            setCurrentVersion(version);

            if (currentVersion !== null) {
                window.dispatchEvent(new CustomEvent('spacesuit.stale'));
            }
        }
    }, [version, currentVersion]);
}
