import { getContextId } from '@tlx/astro-shared';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ACTION_LOG_ADD } from '../../events';
import { useUserInfo } from '../../hooks/useUserInfo';
import {
    ActionLogMessage,
    ActionLogMessageFormat,
    TopbarActionLogProps,
} from './TopbarActionLog';

type StoredMessage = {
    pageName: string;
    date: string;
    message: string;
    format?: ActionLogMessageFormat;
};

type StoredMessages = {
    saved: number;
    messages: StoredMessage[];
};
const AUTO_CLOSE_TIMEOUT = 3000;

export function useTopbarActionLog(): TopbarActionLogProps {
    const timerRef = useRef<number | undefined>();
    const [isOpen, setIsOpen] = useState(false);
    const [showAllResults, setShowAllResults] = useState(false);

    const { messages } = useMessages();

    const onClickShowAll = () => {
        setShowAllResults(true);
    };

    const clearTimer = useCallback(() => {
        if (timerRef.current) {
            window.clearTimeout(timerRef.current);
            timerRef.current = undefined;
        }
    }, [timerRef]);

    const openPopover = useCallback(() => {
        clearTimer();
        setIsOpen(true);
    }, [clearTimer]);

    const closePopover = useCallback(() => {
        window.sessionStorage.removeItem('tlx.infoPopup-openedTimestamp');
        clearTimer();
        setIsOpen(false);
        setShowAllResults(false);
    }, [clearTimer]);

    const handleBlur = useCallback(() => {
        if (timerRef.current) {
            return;
        }
        closePopover();
    }, [closePopover, timerRef]);

    // If a message was very recently added, we open the popover.
    useEffect(() => {
        const openedTimestamp = window.sessionStorage.getItem(
            'tlx.infoPopup-openedTimestamp',
        );
        if (openedTimestamp) {
            const timeDiff = Date.now() - parseInt(openedTimestamp, 10);
            if (timeDiff < AUTO_CLOSE_TIMEOUT) {
                openPopover();

                const fadeoutTimerId = window.setTimeout(() => {
                    closePopover();
                }, AUTO_CLOSE_TIMEOUT);
                timerRef.current = fadeoutTimerId;
            }
        }
    }, [closePopover, messages, openPopover]);

    return {
        actions: messages,
        isOpen,
        openPopover,
        closePopover,
        handleBlur,
        clearTimer,
        showAllResults,
        onClickShowAll,
    };
}

function useMessages() {
    const [messages, setMessages] = useState<ActionLogMessage[]>([]);

    const loginEmployeeId = useUserInfo().data?.employeeId ?? undefined;
    const storeTag = 'tlx.infoPopup_' + getContextId() + '_' + loginEmployeeId;

    useEffect(() => {
        if (loginEmployeeId === undefined) {
            return;
        }

        const data =
            window.sessionStorage.getItem(storeTag) ??
            '{ "messages": [], "saved": 0 }';

        const parsed: StoredMessages = JSON.parse(data);

        const timeDiff = Date.now() - parsed.saved;
        // Remove if it is older than 12 hours.
        if (timeDiff > 60 * 60 * 12 * 1000) {
            window.sessionStorage.removeItem(storeTag);
            return;
        }

        const parsedMessages = parsed.messages ?? [];

        setMessages(
            parsedMessages.map(({ pageName, date, message, format }) => ({
                pageName,
                date: new Date(date),
                message,
                format,
            })),
        );
    }, [loginEmployeeId, storeTag]);

    useEffect(() => {
        const handleAdd = (event: CustomEvent<ActionLogMessage>) => {
            const newMessages = [event.detail, ...messages];
            setMessages(newMessages);

            const storedMessages: StoredMessages = {
                saved: Date.now(),
                messages: newMessages.map(
                    ({ pageName, date, message, format }) => ({
                        pageName,
                        date: date.toISOString(),
                        message,
                        format,
                    }),
                ),
            };

            window.sessionStorage.setItem(
                storeTag,
                JSON.stringify(storedMessages),
            );
            window.sessionStorage.setItem(
                'tlx.infoPopup-openedTimestamp',
                Date.now().toString(),
            );
        };

        window.addEventListener(ACTION_LOG_ADD, handleAdd);

        return () => {
            window.removeEventListener(ACTION_LOG_ADD, handleAdd);
        };
    }, [storeTag, messages]);

    return {
        messages,
    };
}
