import { captureException } from '@sentry/browser';
import type { BrowserPlugin } from '@snowplow/browser-tracker-core';
import { navigation } from '../navigation-api-ponyfill';

export function PageViewPlugin(): BrowserPlugin {
    return {
        activateBrowserPlugin: (tracker) => {
            let previousUrl = window.location.href;

            const trackPageView = (url: string) => {
                tracker.setCustomUrl(url);
                tracker.setReferrerUrl(previousUrl);
                tracker.trackPageView();
            };

            const handleNavigate: NavigateEventListener = (event) => {
                try {
                    const url = event.destination.url;

                    if (isPageTransition(previousUrl, url)) {
                        trackPageView(url);
                    }

                    previousUrl = url;
                } catch (error) {
                    captureException(error, {
                        extra: {
                            event,
                        },
                    });
                }
            };

            navigation.addEventListener('navigate', handleNavigate);

            // Track initial page view
            trackPageView(window.location.href);
        },
    };
}

export function isPageTransition(from: string, to: string): boolean {
    // If we don't have a previous URL, we can't compare it to the current URL.
    if (from === undefined) {
        return true;
    }

    // If the pathnames are different, we're navigating to a different page.
    const fromUrl = new URL(from, window.location.origin);
    const toUrl = new URL(to, window.location.origin);

    if (fromUrl.pathname !== toUrl.pathname) {
        return true;
    }

    if (isCreatePageTransition(fromUrl, toUrl)) {
        return true;
    }

    if (isProjectMenuTransition(fromUrl, toUrl)) {
        return true;
    }

    return false;
}

/**
 * Sadly most of our create and update pages share the same pathname,
 * so we need to check query parameters to determine if we are
 * navigating between create and update pages.
 */
function isCreatePageTransition(fromUrl: URL, toUrl: URL): boolean {
    // Get first from search parameter key
    const fromKey: string | undefined = fromUrl.searchParams
        .keys()
        .next().value;

    // There are no search parameters
    if (fromKey === undefined) {
        return false;
    }

    const fromValue = fromUrl.searchParams.get(fromKey);
    const toValue = toUrl.searchParams.get(fromKey);

    // Key is not present in both URLs
    if (toValue === null || fromValue === null) {
        return false;
    }

    // If we are navigating from a create page to an update page
    if (fromValue === '0' && toValue > fromValue) {
        return true;
    }

    // If we are navigating from an update page to a create page
    if (toValue === '0' && fromValue > toValue) {
        return true;
    }

    return false;
}

/**
 * The projectMenu page is used for both creating a new project, a new offer
 * and a new order. We need to calculate a special key to differentiate between
 * these cases.
 *
 * - New project: /execute/projectMenu?projectId=0
 * - New order/invoice: /execute/projectMenu?projectId=0&isProject=false
 * - New offer: /execute/projectMenu?projectId=0&isProject=false&isOffer=true
 */
function isProjectMenuTransition(fromUrl: URL, toUrl: URL): boolean {
    const fromKey = getProjectMenuKey(fromUrl);
    const toKey = getProjectMenuKey(toUrl);

    if (fromKey === undefined || toKey === undefined) {
        return false;
    }

    return fromKey !== toKey;
}

function getProjectMenuKey(parsedUrl: URL): string | undefined {
    const params = parsedUrl.searchParams;

    if (params.get('projectId') === '0') {
        if (params.get('isProject') === 'false') {
            if (params.get('isOffer') === 'true') {
                return 'createOffer';
            } else {
                return 'createOrder';
            }
        } else {
            return 'createProject';
        }
    }
}
