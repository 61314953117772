import { offset, shift, useFloating } from '@floating-ui/react';
import { Button, Icon, Portal, TestableProps } from '@tlx/atlas';
import classNames from 'classnames';
import React, {
    CSSProperties,
    ReactNode,
    forwardRef,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useKeyboardShortcut } from '../../hooks/useKeyboardShortcut';
import { keyboardCommands } from '../../utils/keyboardCommands';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { Link } from '../Link/Link';
import { TopbarButton } from '../Topbar/Topbar';
import {
    TopbarMobilePopover,
    TopbarMobilePopoverCloseButton,
    TopbarMobilePopoverContent,
    TopbarMobilePopoverHeader,
} from '../Topbar/TopbarMobilePopover';
import { useComposedRefs } from '../Topbar/useComposedRefs';
import {
    SearchLoadMoreContextProvider,
    useSearchLoadMoreCheck,
    useSearchLoadMoreContext,
    useSearchSkeleton,
} from './useSearchLoadMoreContext';

interface SearchDesktopContainerProps {
    defaultQuery: string;
    isOpen?: boolean;
    setIsOpen: (isOpen: boolean) => void;
    search: (query: string) => void;
    children?: ReactNode;
}

export function SearchDesktopContainer({
    defaultQuery,
    isOpen,
    setIsOpen,
    search,
    children,
}: SearchDesktopContainerProps) {
    const inputRef = useRef<HTMLInputElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);

    const [currentQuery, setCurrentQuery] = useState(defaultQuery);

    // close popup when doing soft navigation
    useEffect(() => {
        const closePopup = () => setIsOpen(false);
        window.addEventListener('tlx:navigate', closePopup);
        return () => {
            window.removeEventListener('tlx:navigate', closePopup);
        };
    }, [setIsOpen]);

    const [searchInputIsFocused, setSearchInputIsFocused] = useState(false);

    const floating = useFloating({
        placement: 'bottom-end',
        middleware: [shift({ padding: 8 }), offset(8)],
    });

    const popupPositioningStyle: CSSProperties = {
        position: floating.strategy,
        top: floating.y ?? '',
        left: floating.x ?? '',
    };

    const composedInputRef = useComposedRefs(
        floating.refs.setReference,
        inputRef,
    );
    const composedPopupRef = useComposedRefs(
        floating.refs.setFloating,
        popupRef,
    );

    useLayoutEffect(() => {
        if (isOpen) {
            floating.update();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    useEffect(() => {
        setIsOpen(defaultQuery.trim() !== '');
    }, [setIsOpen, defaultQuery]);

    useKeyboardShortcut(keyboardCommands.openSearch, () => {
        inputRef.current?.select();
    });

    useEffect(() => {
        const preventTabNavigation = (event: KeyboardEvent) => {
            if (!isOpen) {
                return;
            }
            // when the popup is open, capture the "tab" key navigation and
            // prevent propagation if we reach the last
            // focusable element inside the popup
            if (event.key === 'Tab' && event.target instanceof HTMLElement) {
                const currentActiveElement = event.composedPath()[0];
                const focusableElements =
                    popupRef.current?.querySelectorAll(
                        'button, a, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
                    ) ?? [];

                if (focusableElements.length === 0) {
                    return;
                }
                const lastFocusableElement =
                    focusableElements[focusableElements.length - 1];
                const firstFocusableElement = focusableElements[0];

                // handle reaching the last element
                if (
                    lastFocusableElement instanceof HTMLElement &&
                    lastFocusableElement === currentActiveElement &&
                    !event.shiftKey
                ) {
                    event.preventDefault();
                }

                // handle reaching the first element
                if (
                    firstFocusableElement instanceof HTMLElement &&
                    firstFocusableElement === currentActiveElement &&
                    event.shiftKey
                ) {
                    event.preventDefault();
                    inputRef.current?.focus();
                }
            }
        };
        document.addEventListener('keydown', preventTabNavigation);
        return () =>
            document.removeEventListener('keydown', preventTabNavigation);
    }, [isOpen]);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (
        event,
    ) => {
        if (event.target.value.length >= 3) {
            search(event.target.value);
        }
        setCurrentQuery(event.target.value);
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
        event,
    ) => {
        if (event.key === 'Enter') {
            search(currentQuery);
        }
        if (!isOpen) {
            return;
        }
        // when the popup is open, capture the "tab" key navigation and
        // move the focus to the first focusable element inside the popup
        if (event.key === 'Tab' && !event.shiftKey) {
            const firstFocusableElement = popupRef.current?.querySelector(
                'button, a, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
            );

            if (firstFocusableElement instanceof HTMLElement) {
                firstFocusableElement.focus();
                event.preventDefault();
            }
        }
    };

    return (
        <>
            <SearchForm
                ref={composedInputRef}
                className={classNames('tlx-search-form__container', {
                    'tlx-search-form__container--open':
                        searchInputIsFocused || isOpen,
                })}
                value={currentQuery}
                onChange={handleChange}
                onBlur={(event) => {
                    setSearchInputIsFocused(false);
                    if (!popupRef.current?.contains(event.relatedTarget)) {
                        setIsOpen(false);
                    }
                }}
                onFocus={() => {
                    setSearchInputIsFocused(true);
                    setIsOpen(defaultQuery.trim() !== '');
                }}
                onKeyDown={handleKeyDown}
            />
            <SearchPopup
                style={popupPositioningStyle}
                className={classNames({ 'tlx-search__popup--open': isOpen })}
                ref={composedPopupRef}
                hidden={!isOpen}
                tabIndex={-1}
                data-testid="search-popup"
                onBlur={(event) => {
                    if (!popupRef.current?.contains(event.relatedTarget)) {
                        setIsOpen(false);
                    }
                }}
                onKeyDown={(event) => {
                    // Focus button after pressing escape key
                    if (event.key === 'Escape') {
                        inputRef.current?.focus();
                        setIsOpen(false);
                    }
                }}
            >
                <SearchLoadMoreContextProvider>
                    {children}
                </SearchLoadMoreContextProvider>
                <SearchNoResults />
            </SearchPopup>
        </>
    );
}

interface SearchMobileContainerProps {
    defaultQuery: string;
    isOpen?: boolean;
    setIsOpen: (isOpen: boolean) => void;
    search: (query: string) => void;
    children?: ReactNode;
}

export function SearchMobileContainer({
    defaultQuery,
    isOpen,
    setIsOpen,
    search,
    children,
}: SearchMobileContainerProps) {
    const { formatMessage } = useIntl();

    // Close popup when doing soft navigation
    useEffect(() => {
        const closePopup = () => setIsOpen(false);

        window.addEventListener('tlx:navigate', closePopup);

        return () => {
            window.removeEventListener('tlx:navigate', closePopup);
        };
    }, [setIsOpen]);

    // Focus search field after opening
    const setInputRef = (node: HTMLInputElement | null) => {
        node?.focus();
    };

    return (
        <div>
            <TopbarButton
                label={formatMessage({ id: 'text_search' })}
                icon="search"
                data-testid="search-opener-button"
                onClick={() => {
                    setIsOpen(true);
                }}
            />
            {isOpen && (
                <TopbarMobilePopover>
                    <TopbarMobilePopoverHeader>
                        <TopbarMobilePopoverCloseButton
                            onClose={() => setIsOpen(false)}
                        />
                    </TopbarMobilePopoverHeader>
                    <TopbarMobilePopoverContent className="atl-px-24">
                        <SearchForm
                            className="tlx-search-mobile-popup__form"
                            defaultValue={defaultQuery}
                            onChange={(event) => search(event.target.value)}
                            ref={setInputRef}
                        />
                        {children}
                    </TopbarMobilePopoverContent>
                </TopbarMobilePopover>
            )}
        </div>
    );
}

type SearchFormProps = Pick<
    React.InputHTMLAttributes<HTMLInputElement>,
    Exclude<keyof React.InputHTMLAttributes<HTMLInputElement>, 'type'>
>;

export const SearchForm = forwardRef<HTMLInputElement, SearchFormProps>(
    function SearchForm(
        { className, value, onBlur, onChange, onFocus, onKeyDown },
        ref,
    ) {
        const { formatMessage } = useIntl();
        const searchMessage = formatMessage({ id: 'text_search' });

        const handleSubmit: React.FormEventHandler = (event) =>
            event.preventDefault();

        return (
            <form className={className} onSubmit={handleSubmit}>
                <Icon className="tlx-search-form__icon">search</Icon>
                <input
                    ref={ref}
                    type="search"
                    className="tlx-search-form__input"
                    data-testid="search-input"
                    aria-label={searchMessage}
                    placeholder={searchMessage}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onKeyDown={onKeyDown}
                />
            </form>
        );
    },
);

export const SearchPopup = forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement>
>(function SearchPopup({ ...props }, ref) {
    return (
        <Portal>
            <div
                {...props}
                className={classNames('tlx-search__popup', props.className)}
                ref={ref}
            />
        </Portal>
    );
});

export function SearchResultItem({ children }: { children?: ReactNode }) {
    return (
        <ErrorBoundary>
            <div
                className={
                    'atl-flex atl-items-center atl-gap-16 atl-pt-8 atl-pb-8'
                }
                data-testid="search-result-item"
            >
                {children}
            </div>
        </ErrorBoundary>
    );
}

type SearchResultItemLinkProps = React.HTMLProps<HTMLAnchorElement> & {
    href: string;
    category: string;
};

export function SearchResultItemLink(props: SearchResultItemLinkProps) {
    const url = new URL(props.href, window.location.origin);

    /**
     * Add search category to the url so we know what category the user
     * clicked on when we navigate to the search result and track it using
     * Snowplow.
     *
     */
    url.searchParams.set('search-category', props.category);

    return (
        <Link
            {...props}
            href={url.toString()}
            className="tlx-search__item-detail-link"
            data-testid="search-result-item-link"
        />
    );
}

export function SearchResultItemIcon({
    className,
    children,
}: {
    className?: string;
    children: string;
}) {
    return (
        <Icon
            className={classNames(
                'atl-rounded-full atl-p-8 tlx-search__result-icon',
                className,
            )}
        >
            {children}
        </Icon>
    );
}

export function SearchResultItemSkeletonIcon({
    className,
    children,
}: {
    className?: string;
    children?: string;
}) {
    return (
        <div
            className={classNames(
                'atl-rounded-full atl-p-8 tlx-search__result-icon tlx-search__result-skeleton atl-flex-none',
                className,
            )}
        >
            {children}
        </div>
    );
}

export function SearchResultItemTitle({ children }: { children?: ReactNode }) {
    return (
        <div className="atl-break-all atl-font-bold atl-text-base">
            {children}
        </div>
    );
}

export function SearchResultItemTextSkeleton() {
    return (
        <div className="atl-flex atl-justify-center atl-w-full atl-flex-col tlx-search__result--text">
            <div className="atl-font-bold atl-text-base tlx-search__result-skeleton tlx-search__result-skeleton--title-text" />
            <div className="atl-font-bold atl-text-base tlx-search__result-skeleton tlx-search__result-skeleton--fields-text" />
        </div>
    );
}

export function SearchResultItemField({
    translationKey,
    children,
}: {
    translationKey: string;
    children?: ReactNode;
}) {
    const { formatMessage } = useIntl();

    let hasValidChildren = false;
    React.Children.forEach(children, (child) => {
        if (typeof child === 'string') {
            hasValidChildren = child.trim() !== '';
        } else if (typeof child !== 'undefined') {
            hasValidChildren = true;
        }
    });

    return hasValidChildren ? (
        <>
            <span className="atl-text-sm atl-font-medium atl-mr-2">
                {`${formatMessage({ id: translationKey })}:`}
            </span>
            <span className="atl-text-sm atl-break-all atl-mr-4">
                {children}
            </span>
        </>
    ) : null;
}

export function SearchResultTitle({ children }: { children?: ReactNode }) {
    return (
        <div
            data-testid="search-category-title"
            className="atl-justify-between atl-items-center atl-flex atl-text-base atl-font-bold atl-pl-8 atl-pb-8"
        >
            {children}
        </div>
    );
}

export function SearchResultTitleLink({
    href,
    children,
}: {
    href: string;
    children?: ReactNode;
}) {
    return (
        <Link
            className="tlx-search__go-to-link"
            href={href}
            data-testid="search-result-title-link"
        >
            {children}
        </Link>
    );
}

export function GoBackButton({ onGoBack }: { onGoBack?: () => void }) {
    const { formatMessage } = useIntl();

    const { isLoadMorePage, setLoadMoreCategory } = useSearchLoadMoreContext();
    if (!isLoadMorePage) {
        return null;
    }
    return (
        <Button
            onClick={(event) => {
                // Focus on popup when go back button is clicked
                const popupRef: HTMLElement | null =
                    event.currentTarget instanceof HTMLButtonElement
                        ? event.currentTarget.closest(
                              '[data-testid="search-popup"]',
                          )
                        : null;
                popupRef?.focus();

                onGoBack?.();
                setLoadMoreCategory(undefined);
            }}
            data-testid="search-go-back"
            variant="icon"
            className="atl-rounded-full atl-p-8 atl-mr-16"
            aria-label={formatMessage({ id: 'text_go_back' })}
        >
            <Icon>arrow_back</Icon>
        </Button>
    );
}

export function LoadMoreButton({
    'data-testid': testId,
    onLoadMore,
    category,
}: { onLoadMore?: () => void; category: string } & TestableProps) {
    const { formatMessage } = useIntl();
    const { setLoadMoreCategory } = useSearchLoadMoreContext();

    return (
        <button
            className="tlx-search__show-more"
            data-testid={testId}
            onClick={(event) => {
                setLoadMoreCategory(category);
                onLoadMore?.();
                if (!(event.target instanceof HTMLElement)) {
                    return;
                }
                const previousSibling = event.target.previousElementSibling;
                if (previousSibling instanceof HTMLElement) {
                    previousSibling.querySelector('a')?.focus();
                }
            }}
        >
            {formatMessage({ id: 'text_show_more_ellipsis' })}
        </button>
    );
}

export function SearchResult({
    category,
    children,
}: {
    category: string;
    children?: ReactNode;
}) {
    const { shouldNotRenderItself } = useSearchLoadMoreCheck(category);

    if (shouldNotRenderItself) {
        return null;
    }

    return (
        <div className="tlx-search__result" data-testid="search-result">
            {children}
            <SearchResultSeparator />
        </div>
    );
}

export function SearchResultItems({ children }: { children?: ReactNode }) {
    return <div className={'atl-p-8 atl-gap-16'}>{children}</div>;
}

export function SearchResultSeparator() {
    return (
        <div className="tlx-search__result-separator atl-pt-16 atl-pb-16">
            <hr className="tlx-search__result-separator--line" />
        </div>
    );
}

export function SearchNoResults() {
    const { formatMessage } = useIntl();
    return (
        <div
            data-testid="search-no-results"
            className="tlx-search__no-results atl-text-base atl-font-bold atl-p-8"
        >
            {formatMessage({ id: 'text_no_results' })}
        </div>
    );
}

export function SearchResultSkeleton() {
    const { skeletonSize } = useSearchSkeleton();
    return (
        <div data-testid="search-result-skeleton">
            {Array.from({ length: skeletonSize }, (_, index) => (
                <SearchResultItem key={index}>
                    <SearchResultItemSkeletonIcon />
                    <SearchResultItemTextSkeleton />
                </SearchResultItem>
            ))}
        </div>
    );
}
