import { useIntl } from 'react-intl';

export function MaintenanceOverlay({
    isSuspended,
    loginUrl,
}: {
    isSuspended: boolean;
    loginUrl?: string;
}) {
    if (!isSuspended) {
        return null;
    }

    return (
        <div className="tlx-maintenance-overlay">
            <Background />
            <Sidebar loginUrl={loginUrl} />
        </div>
    );
}

function Background() {
    return (
        <div className="tlx-maintenance-overlay-background">
            <img
                src="https://cdn.tripletex.no/astro/images/remi-desk.jpg"
                alt="En utvikler jobber med vedlikehold av Tripletex"
                className="tlx-maintenance-overlay-background__image"
            />
            <div className="tlx-maintenance-overlay-background__overlay"></div>
            <Illustration />
        </div>
    );
}

function Illustration() {
    return (
        <>
            <div className="tlx-maintenance-overlay-illustration__paralellogram">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 241.782 220"
                >
                    <polygon points="154.653 220 241.782 220 87.129 0 0 0 154.653 220" />
                </svg>
            </div>
            <div className="tlx-maintenance-overlay-illustration__angle">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 175.824 200"
                >
                    <polygon points="175.824 200 0 200 0 0 87.912 0 87.912 112.088 175.824 112.088 175.824 200" />
                </svg>
            </div>
            <div className="tlx-maintenance-overlay-illustration__quarter">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
                    <g style={{ opacity: 0 }}>
                        <path d="M200,400C89.72,400,0,310.279,0,199.999S89.72,0,200,0s200,89.72,200,199.999-89.719,200.001-200,200.001Zm0-316.231c-64.09,0-116.23,52.141-116.23,116.23s52.14,116.231,116.23,116.231,116.23-52.141,116.23-116.231-52.141-116.23-116.23-116.23Z" />
                    </g>
                    <path
                        className="tlx-maintenance-overlay-illustration__quarter-path"
                        d="M0,200H83.77c0-64.09,52.141-116.23,116.23-116.23V0C89.719,0,0,89.72,0,200Z"
                    />
                </svg>
            </div>
        </>
    );
}

function Sidebar({ loginUrl }: { loginUrl?: string }) {
    const { formatMessage } = useIntl();

    return (
        <div className="tlx-maintenance-overlay-sidebar">
            <div className="tlx-maintenance-overlay-sidebar__logo">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="147.987"
                    height="50"
                    viewBox="0 0 147.987 50"
                >
                    <path
                        d="M76.167,34.194c-.494-2.964-1.976-5.335-4.544-6.816-.988-.593-2.173-.889-3.359-1.087-1.185-.099-2.371,0-3.458,.296-4.939,1.383-7.409,6.619-6.026,11.163,.296,1.087,.889,2.173,1.581,3.161,.692,.889,1.679,1.679,2.667,2.272,.988,.593,2.173,.889,3.359,1.087,1.185,.099,2.371,0,3.458-.296,1.679-.494,3.161-1.383,4.248-2.766,1.185-1.482,1.877-3.26,2.173-5.038l-4.05,1.185c-.395,1.581-1.383,2.766-3.26,3.359-2.272,.692-4.939-.099-5.927-2.667l13.139-3.853Zm-13.929,.79c-.296-1.581,.692-4.149,3.556-5.038,2.865-.889,5.137,.79,5.73,2.371l-9.286,2.667Z"
                        fill="#0f131a"
                    />
                    <path
                        d="M105.507,34.194c-.494-2.964-1.976-5.335-4.544-6.816-.988-.593-2.173-.889-3.359-1.087-1.185-.099-2.371,0-3.458,.296-4.939,1.383-7.409,6.619-6.026,11.163,.296,1.185,.889,2.173,1.581,3.161,.692,.889,1.679,1.679,2.667,2.272s2.173,.889,3.359,1.087c1.185,.099,2.371,0,3.458-.296,1.679-.494,3.161-1.383,4.248-2.766,1.185-1.482,1.877-3.26,2.173-5.038l-4.05,1.185c-.395,1.581-1.383,2.766-3.26,3.359-2.272,.692-4.939-.099-5.927-2.667l13.139-3.853Zm-13.929,.79c-.296-1.581,.692-4.149,3.556-5.038s5.137,.79,5.73,2.371l-9.286,2.667Z"
                        fill="#0f131a"
                    />
                    <path
                        d="M105.409,44.073h4.939l3.853-5.829,3.853,5.829h4.939l-6.323-8.99,5.73-8.002h-4.841l-3.359,5.236-3.359-5.236h-4.939l5.73,8.002-6.224,8.99Z"
                        fill="#0f131a"
                    />
                    <path
                        d="M48.605,35.379c0,4.347-3.161,8.99-9.089,8.99-1.778,0-3.458-.494-5.038-1.383v7.014h-4.05v-14.621h0c0-4.347,3.161-8.99,9.089-8.99s9.089,4.643,9.089,8.99Zm-4.05,0c0-2.964-2.272-5.236-5.038-5.236s-4.939,2.075-5.038,4.939h0v.889c.099,1.284,.692,2.371,1.581,3.26s2.173,1.284,3.359,1.284c2.865,.099,5.137-2.173,5.137-5.137"
                        fill="#0f131a"
                    />
                    <path
                        d="M55.125,20.956h-3.754v22.919h4.05V20.956h-.296Z"
                        fill="#0f131a"
                    />
                    <path
                        d="M22.524,26.883h4.05v16.992h-4.05V26.883Zm1.976-2.47c.494,0,.889-.099,1.284-.395s.692-.593,.79-.988c.198-.395,.198-.889,.099-1.284s-.296-.79-.593-1.185c-.296-.296-.692-.494-1.185-.593-.395-.099-.889,0-1.284,.099-.395,.198-.79,.494-.988,.79-.296,.395-.395,.79-.395,1.284,0,.593,.198,1.185,.692,1.581,.395,.494,.988,.692,1.581,.692Z"
                        fill="#0f131a"
                    />
                    <path
                        d="M13.337,35.28c.099-2.964,2.371-5.038,5.137-5.038,.296,0,.692,0,.988,.099v-3.853c-.296,0-.692-.099-.988-.099-6.026,0-9.187,4.643-9.187,9.089h0v8.397h4.149l-.099-8.595Z"
                        fill="#0f131a"
                    />
                    <path
                        d="M8.298,26.192H4.05v-5.236H0v22.919H4.05v-13.534h4.248v-4.149Z"
                        fill="#0f131a"
                    />
                    <path
                        d="M87.133,26.192h-4.248v-5.236h-4.05v22.919h4.05v-13.534h4.248v-4.149Z"
                        fill="#0f131a"
                    />
                    <path
                        d="M142.554,16.708v-.889h5.433c-.099-1.383-.692-2.667-1.679-3.655-.889-.889-2.173-1.482-3.458-1.679-1.284-.099-2.667,.198-3.754,.889s-1.976,1.778-2.371,2.964-.395,2.569,0,3.853c.494,1.185,1.284,2.272,2.47,2.964,1.087,.692,2.47,.988,3.754,.79s2.47-.79,3.359-1.778,1.482-2.173,1.581-3.458h-5.335Z"
                        fill="#58b3cd"
                    />
                    <path
                        d="M131.489,26.784h.889v5.433c1.383-.099,2.667-.692,3.655-1.679,.889-.889,1.482-2.173,1.679-3.458,.099-1.284-.198-2.667-.889-3.754-.692-1.087-1.778-1.976-2.964-2.371-1.284-.395-2.569-.395-3.853,0-1.185,.494-2.272,1.284-2.964,2.47-.692,1.087-.988,2.47-.79,3.754s.79,2.47,1.778,3.359,2.173,1.482,3.458,1.581v-5.335Z"
                        fill="#1ab96d"
                    />
                    <path
                        d="M132.378,5.94l-.692-.692,3.853-3.853c-1.087-.988-2.569-1.482-4.05-1.383-1.482,.099-2.865,.79-3.853,1.877-.988,1.087-1.581,2.569-1.482,4.05s.593,2.865,1.679,3.952c1.087,1.087,2.47,1.679,3.952,1.679s2.964-.494,4.05-1.482,1.778-2.371,1.877-3.853c.099-1.482-.395-2.964-1.383-4.05l-3.952,3.754Z"
                        fill="#010a59"
                    />
                </svg>
            </div>
            <div className="tlx-maintenance-overlay-sidebar__content">
                <div className="tlx-maintenance-overlay-sidebar__title">
                    {formatMessage({
                        id: 'text_company_maintenance',
                        defaultMessage: 'Ongoing manintanance in this account',
                    })}
                </div>
                <p className="tlx-maintenance-overlay-sidebar__lead">
                    {formatMessage({
                        id: 'text_company_maintenance_information',
                        defaultMessage:
                            'This may take some time, please log in again later with the button below.',
                    })}
                </p>
                <div className="tlx-maintenance-overlay-sidebar__buttons">
                    <a
                        href={loginUrl}
                        className="atl-button atl-button--primary"
                        data-testid="maintenance-overlay-login-button"
                    >
                        {formatMessage({
                            id: 'button_return_to_login',
                            defaultMessage: 'Log in to Tripletex',
                        })}
                    </a>
                </div>
            </div>
        </div>
    );
}
