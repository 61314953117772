import { UseSearchCategoryReturn } from '../types';

import { useIntl } from 'react-intl';
import {
    GoBackButton,
    LoadMoreButton,
    SearchResult,
    SearchResultItem,
    SearchResultItemField,
    SearchResultItemIcon,
    SearchResultItemLink,
    SearchResultItems,
    SearchResultItemTitle,
    SearchResultSkeleton,
    SearchResultTitle,
    SearchResultTitleLink,
} from '../SearchContainer';
import { useResultTitleTranslation } from '../useResultTitleTranslation';
import { getUrlWithContext } from '../util';
import { voucherCategory, VoucherDTO } from './types';

export function VoucherResult(props: UseSearchCategoryReturn<VoucherDTO>) {
    const translation = useResultTitleTranslation('text_voucher');

    const { data, hasMore, loading, loadMore, goBack } = props;

    const hasData = data !== undefined && data.length > 0;
    if (!hasData && !loading) {
        return null;
    }

    return (
        <SearchResult category={voucherCategory}>
            <SearchResultTitle>
                <div className="atl-flex atl-items-center">
                    <GoBackButton onGoBack={goBack} />
                    {translation.title}
                </div>
                <SearchResultTitleLink
                    href={getUrlWithContext('/execute/viewJournal')}
                >
                    {translation.overviewLinkText}
                </SearchResultTitleLink>
            </SearchResultTitle>
            <SearchResultItems>
                {data?.map((item: VoucherDTO) => (
                    <VoucherResultItem key={item.id} data={item} />
                ))}
                {loading && <SearchResultSkeleton />}
                {!loading && hasMore && (
                    <LoadMoreButton
                        data-testid={`search-show-more`}
                        onLoadMore={loadMore}
                        category={voucherCategory}
                    />
                )}
            </SearchResultItems>
        </SearchResult>
    );
}

export function VoucherResultItem({ data }: { data: VoucherDTO }) {
    const { formatMessage } = useIntl();

    return (
        <SearchResultItem>
            <SearchResultItemIcon className="tlx-search__result-voucher-icon">
                receipt
            </SearchResultItemIcon>
            <SearchResultItemLink
                href={data.urlDetails}
                category={voucherCategory}
            >
                <SearchResultItemTitle>{`${formatMessage({
                    id: 'text_voucher_number',
                })}: ${data.number}-${data.year}`}</SearchResultItemTitle>
                <SearchResultItemField translationKey={'text_description'}>
                    {data.description}
                </SearchResultItemField>
            </SearchResultItemLink>
        </SearchResultItem>
    );
}
