import { BrowserPlugin } from '@snowplow/browser-tracker-core';
import { SelfDescribingJson } from '@snowplow/tracker-core';
import { ResponseWrapper, fetcher } from '@tlx/astro-shared';

export async function GlobalContextsPlugin(): Promise<BrowserPlugin> {
    const contexts: SelfDescribingJson[] = await Promise.all([
        fetchGlobalContext('/v2/snowplow/globalCompanyContext'),
        fetchGlobalContext('/v2/snowplow/globalEmployeeContext'),
    ]);

    return {
        contexts() {
            return contexts;
        },
    };
}

async function fetchGlobalContext(url: string) {
    const data = await fetcher<ResponseWrapper<SelfDescribingJson>>(url);

    return data.value;
}
