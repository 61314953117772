import { UseSearchCategoryReturn } from '../types';
import { orderOfferCategory, OrderOfferDTO } from './types';

import { useIntl } from 'react-intl';
import {
    GoBackButton,
    LoadMoreButton,
    SearchResult,
    SearchResultItem,
    SearchResultItemField,
    SearchResultItemIcon,
    SearchResultItemLink,
    SearchResultItems,
    SearchResultItemTitle,
    SearchResultSkeleton,
    SearchResultTitle,
    SearchResultTitleLink,
} from '../SearchContainer';
import { getUrlWithContext } from '../util';

function useOrderOfferResultIntl() {
    const { formatMessage } = useIntl();
    const displayKey = 'text_offers';
    const overviewLinkText = `${formatMessage({
        id: 'text_go_to_x',
    })} ${formatMessage({ id: displayKey }).toLowerCase()}`;

    const title = formatMessage({ id: displayKey });
    return { title, overviewLinkText };
}

export function OrderOfferResult(
    props: UseSearchCategoryReturn<OrderOfferDTO>,
) {
    const translation = useOrderOfferResultIntl();

    const { data, hasMore, loading, loadMore, goBack } = props;

    const hasData = data !== undefined && data.length > 0;
    if (!hasData && !loading) {
        return null;
    }

    return (
        <SearchResult category={orderOfferCategory}>
            <SearchResultTitle>
                <div className="atl-flex atl-items-center">
                    <GoBackButton onGoBack={goBack} />
                    {translation.title}
                </div>
                <SearchResultTitleLink
                    href={getUrlWithContext('/execute/listOffers')}
                >
                    {translation.overviewLinkText}
                </SearchResultTitleLink>
            </SearchResultTitle>
            <SearchResultItems>
                {data?.map((item: OrderOfferDTO) => (
                    <OrderOfferResultItem key={item.id} data={item} />
                ))}
                {loading && <SearchResultSkeleton />}
                {!loading && hasMore && (
                    <LoadMoreButton
                        data-testid={`search-show-more`}
                        category={orderOfferCategory}
                        onLoadMore={loadMore}
                    />
                )}
            </SearchResultItems>
        </SearchResult>
    );
}

export function OrderOfferResultItem({ data }: { data: OrderOfferDTO }) {
    const url = getUrlWithContext('/execute/projectMenu', {
        projectId: data.id.toString(),
    });
    const { formatMessage } = useIntl();
    return (
        <SearchResultItem>
            <SearchResultItemIcon className="tlx-search__result-orderoffer-icon">
                content_copy
            </SearchResultItemIcon>
            <SearchResultItemLink href={url} category={orderOfferCategory}>
                <SearchResultItemTitle>
                    {`${formatMessage({ id: 'text_offer_number' })}: ${
                        data.number
                    }`}
                </SearchResultItemTitle>
                <SearchResultItemField translationKey={'text_customer'}>
                    {data.customer?.name}
                </SearchResultItemField>
            </SearchResultItemLink>
        </SearchResultItem>
    );
}
