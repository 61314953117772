import { initializeTourMe } from '@tlx/tourme-core';
import { createElement } from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { getElement, redirector } from '../../hooks/useTourMe';
import { initializeTracker } from '../../snowplow/index';
import { createLink } from '../../utils/createLink';
import { Banner } from '../Banner/Banner';
import { Callouts } from '../Callouts/Callouts';
import { HelpCenter } from '../HelpCenter/HelpCenter';
import { KeyboardShortcutsDialog } from '../KeyboardShortcutsDialog/KeyboardShortcutsDialog';
import { MaintenanceOverlay } from '../MaintenanceOverlay/MaintenanceOverlay';
import { Search } from '../Search/Search';
import { SessionExpiredDialog } from '../SessionExpiredDialog/SessionExpiredDialog';
import { SidebarContainer, SidebarMenu } from '../Sidebar/Sidebar';
import { FavoriteButton } from '../Topbar/FavoriteButton';
import {
    DuplicateTabButton,
    TopBarProfile,
    TopbarBurgerButton,
    TopbarButtons,
    TopbarCompanyChooser,
    TopbarContainer,
    TopbarLogo,
    TopbarSearch,
} from '../Topbar/Topbar';
import { TopbarActionLog } from '../TopbarActionLog/TopbarActionLog';
import { TopbarErrorMessages } from '../TopbarErrorMessages/TopbarErrorMessages';
import { TopbarNotifications } from '../TopbarNotifications/TopbarNotifications';
import {
    Layout,
    LayoutActionBar,
    LayoutCallouts,
    LayoutContent,
    LayoutGlobalMessages,
    LayoutMain,
    LayoutScrollContainer,
    LayoutSidebar,
    LayoutTopbar,
} from './Layout';
import { useLayout } from './useLayout';

// @ts-expect-error styles is a string
import styles from '../../index.css?inline';
import { TermsOfService } from '../TermsOfService/TermsOfService';
import { UnsupportedBrowserWarning } from '../UnsupportedBrowserWarning/UnsupportedBrowserWarning';

export class LayoutElement extends HTMLElement {
    intervalId: number | undefined;

    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
    }

    connectedCallback() {
        createLink(
            'https://cdn.tripletex.no/google-fonts/rubik-400-500-800.css',
        );
        createLink(
            'https://cdn.tripletex.no/google-fonts/material-icons-outlined.css',
        );
        initializeTourMe({ getElement, redirector });
        initializeTracker();
        setupPortalContainers();

        this.render();
    }

    render() {
        if (this.shadowRoot !== null) {
            const root = createRoot(this.shadowRoot);
            root.render(createElement(Container));
        } else {
            console.error('this.shadowRoot is null');
            return null;
        }
    }
}

function Container() {
    const props = useLayout();

    if (!props.ready) {
        return null;
    }

    return (
        <IntlProvider locale={props.locale} messages={props.messages}>
            <style dangerouslySetInnerHTML={{ __html: styles }} />
            <Layout {...props}>
                <LayoutTopbar>
                    <TopbarContainer>
                        <TopbarBurgerButton
                            expanded={props.sidebarExpanded}
                            onClick={props.handleHamburgerButtonClick}
                        />
                        <TopbarLogo
                            href={props.sidebar.menu.data?.defaultContentUrl}
                        />
                        <TopbarCompanyChooser {...props.companyChooser} />
                        <TopbarSearch>
                            <Search />
                        </TopbarSearch>
                        <TopbarButtons>
                            <HelpCenter {...props.helpCenter} />
                            <FavoriteButton {...props.favorites} />
                            <slot name="export-button" />
                            <TopbarNotifications
                                {...props.topbarNotifications}
                            />
                            <TopbarActionLog {...props.topbarActionLog} />
                            <TopbarErrorMessages
                                {...props.topbarErrorMessages}
                            />
                            <DuplicateTabButton />
                            <TopBarProfile {...props.profile} />
                        </TopbarButtons>
                    </TopbarContainer>
                </LayoutTopbar>
                <LayoutGlobalMessages>
                    {props.banner.banner && (
                        <Banner
                            banner={props.banner.banner}
                            onCancelBanner={props.banner.onCancelBanner}
                        />
                    )}
                </LayoutGlobalMessages>
                <LayoutMain>
                    <LayoutSidebar>
                        <SidebarContainer>
                            <SidebarMenu {...props.sidebar} />
                        </SidebarContainer>
                    </LayoutSidebar>
                    <LayoutScrollContainer>
                        <UnsupportedBrowserWarning />
                        {props.callouts.callouts.length > 0 && (
                            <LayoutCallouts>
                                <Callouts {...props.callouts} />
                            </LayoutCallouts>
                        )}
                        <LayoutContent>
                            <slot name="content" />
                        </LayoutContent>
                    </LayoutScrollContainer>
                </LayoutMain>
                <LayoutActionBar>
                    <slot name="actionbar" />
                </LayoutActionBar>
            </Layout>
            <TermsOfService />
            <KeyboardShortcutsDialog />
            <SessionExpiredDialog
                isLoggedOut={props.session.isLoggedOut}
                loginUrl={props.session.loginUrl}
            />
            <MaintenanceOverlay
                isSuspended={props.session.isSuspended}
                loginUrl={props.session.loginUrl}
            />
        </IntlProvider>
    );
}

// Set up two wrapper divs: one for userland portals and one for Spacesuit portals.
// The spacesuit portals should always be on visually top of the userland portals.
function setupPortalContainers() {
    if (document.getElementById('portals') === null) {
        const style = document.createElement('style');
        style.textContent = styles;

        const defaultPortals = document.createElement('div');
        defaultPortals.style.position = 'absolute';
        defaultPortals.style.zIndex = '0';
        defaultPortals.id = 'default-portals';

        const spacesuitPortals = document.createElement('div');
        spacesuitPortals.style.position = 'absolute';
        spacesuitPortals.style.zIndex = '0';
        spacesuitPortals.id = 'spacesuit-portals';

        const shadow = spacesuitPortals.attachShadow({ mode: 'open' });
        shadow.appendChild(style);

        const portals = document.createElement('div');
        portals.id = 'portals';
        portals.appendChild(defaultPortals);
        portals.appendChild(spacesuitPortals);

        document.body.appendChild(portals);
    }
}
