export type OrderDTO = {
    id: number;
    number: string;
    customer?: {
        name: string;
    };
    contact?: {
        firstName: string;
        lastName: string;
    };
    orderDate: string;
};

export const orderCategory = 'order';
