import { useApiSearch } from '../useSearch';
import { MenuItemDTO } from './types';

const endpoint = `/v2/internal/menu/items`;
const searchParams = new URLSearchParams({
    fields: 'title,testId,url',
});

export function useMenuSearch(query: string) {
    return useApiSearch<MenuItemDTO>(endpoint, query, searchParams);
}
