export type InvoiceDTO = {
    id: number;
    invoiceNumber: number;
    amount: number;
    customer?: {
        name: string;
    };
};

export const invoiceCategory = 'invoice';
