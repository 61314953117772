import { useEffect, useRef, useState } from 'react';
import { TopbarErrorMessagesProps } from './TopbarErrorMessages';
import { ERROR_MESSAGES_ADD, ERROR_MESSAGES_CLEAR } from '../../events';

export function useTopbarErrorMessages(): TopbarErrorMessagesProps {
    const [messages, setMessages] = useState<string[]>([]);
    const [isOpen, setIsOpen] = useState(false);
    const fadeoutTimer = useRef<number>();

    useEffect(() => {
        function handleAdd(event: CustomEvent<{ message: string }>) {
            setMessages((existingMessages) => [
                event.detail.message,
                ...existingMessages,
            ]);
            openPopover(true);
        }

        function handleClear() {
            setMessages([]);
            closePopover();
        }

        window.addEventListener(ERROR_MESSAGES_ADD, handleAdd);
        window.addEventListener(ERROR_MESSAGES_CLEAR, handleClear);

        return () => {
            window.removeEventListener(ERROR_MESSAGES_ADD, handleAdd);
            window.removeEventListener(ERROR_MESSAGES_CLEAR, handleClear);
        };
    }, []);

    function clearTimer() {
        if (fadeoutTimer.current) {
            window.clearTimeout(fadeoutTimer.current);
        }
    }

    function openPopover(fadeOut: boolean) {
        clearTimer();
        setIsOpen(true);
        if (fadeOut) {
            fadeoutTimer.current = window.setTimeout(() => {
                closePopover();
            }, 5000);
        }
    }

    function closePopover() {
        clearTimer();
        setIsOpen(false);
    }

    return {
        errorMessages: messages,
        isOpen,
        openPopover,
        closePopover,
        clearTimer,
    };
}
