import { useFetch, UseFetchReturn } from '../../hooks/useFetch';
import { MenuDTO } from './types';
import { isNarrowScreen } from '../../utils/url';

export function useMainMenu(): UseFetchReturn<MenuDTO> {
    const params = new URLSearchParams({
        simple: 'false',
        narrowScreen: isNarrowScreen().toString(),
        contentUrl: '',
    });
    const url = `/v2/internal/menu?${params}`;
    const response = useFetch<MenuDTO>(url);
    if (response.error) {
        window.Sentry?.captureException(response.error, { extra: { url } });
    }
    return response;
}
