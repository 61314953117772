import { useEffect, useState } from 'react';
import { useCurrentRelativeUrl } from '../../hooks/useCurrentRelativeUrl';
import { useFetch } from '../../hooks/useFetch';
import { isNarrowScreen } from '../../utils/url';
import { CompanyChooserDTO, CompanyDTO } from './types';

export type UseCompanyChooserReturn = {
    companies: CompanyDTO[];
    currentCompany: CompanyDTO | null;
    hasAccessToMultipleCompanies: boolean;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    ready: boolean;
};

export function useCompanyChooser(): UseCompanyChooserReturn {
    const [open, setOpen] = useState(false);
    const [companiesUrl, setCompaniesUrl] = useState(getCompaniesUrl());
    const [companyChooserUrl, setCompanyChooserUrl] = useState(
        getCompanyChooserUrl(),
    );
    const currentRelativeUrl = useCurrentRelativeUrl();

    useEffect(() => {
        setCompaniesUrl(getCompaniesUrl());
        setCompanyChooserUrl(getCompanyChooserUrl());
    }, [open, currentRelativeUrl]);

    const companiesResponse = useFetch<CompanyDTO[]>(
        open ? companiesUrl : null,
    );

    const companyChooserResponse =
        useFetch<CompanyChooserDTO>(companyChooserUrl);

    const companies = companiesResponse.data || [];
    const currentCompany = companyChooserResponse.data?.currentCompany || null;
    const hasAccessToMultipleCompanies =
        companyChooserResponse.data?.hasAccessToMultipleCompanies === true;

    return {
        companies,
        currentCompany,
        hasAccessToMultipleCompanies,
        open,
        setOpen,
        ready: companyChooserResponse.ready,
    };
}

function getCompaniesUrl() {
    const narrowScreen = isNarrowScreen();
    const url = '/v2/internal/company-chooser/companies';
    if (narrowScreen) {
        return `${url}?narrowScreen=true`;
    }
    return url;
}

function getCompanyChooserUrl() {
    const narrowScreen = isNarrowScreen();
    const url = '/v2/internal/company-chooser';
    if (narrowScreen) {
        return `${url}?narrowScreen=true`;
    }
    return url;
}
