import { offset, shift, useFloating } from '@floating-ui/react';
import { InlineMarkdown } from '@tlx/astro-shared';
import { Button, CloseIcon, Portal } from '@tlx/atlas';
import classNames from 'classnames';
import React, { CSSProperties, forwardRef, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Sanitize } from '../Sanitize/Sanitize';
import { TopbarButton } from '../Topbar/Topbar';
import { useComposedRefs } from '../Topbar/useComposedRefs';

export type ActionLogMessageFormat = 'html' | 'markdown';

export type ActionLogMessage = {
    pageName: string;
    date: Date;
    message: string;
    format?: ActionLogMessageFormat;
};

export type TopbarActionLogProps = {
    actions: ActionLogMessage[];
    isOpen: boolean;
    showAllResults: boolean;
    onClickShowAll: React.MouseEventHandler<HTMLButtonElement>;
    openPopover(fadeOut: boolean): void;
    closePopover(): void;
    handleBlur(): void;
    clearTimer(): void;
};

let pageBeingUnloaded = false;

export function TopbarActionLog({
    actions,
    isOpen,
    openPopover,
    closePopover,
    handleBlur,
    clearTimer,
    showAllResults,
    onClickShowAll,
}: TopbarActionLogProps) {
    const { formatMessage } = useIntl();
    const floating = useFloating({
        placement: 'bottom-start',
        // The 11px value is need to align the popup with the other popup on Topbar
        middleware: [shift({ padding: 11 }), offset(11)],
    });

    const popupPositioningStyle: CSSProperties = {
        position: floating.strategy,
        top: floating.y ?? '',
        left: floating.x ?? '',
    };

    const buttonRef = useRef<HTMLButtonElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);

    const combinedButtonRefs = useComposedRefs(
        buttonRef,
        floating.refs.setReference,
    );
    const combinedPopupRefs = useComposedRefs(
        popupRef,
        floating.refs.setFloating,
    );

    function onBlur(
        event: React.FocusEvent<HTMLButtonElement | HTMLDivElement>,
    ) {
        // Firefox fires a blur event when the page is being unloaded.
        // Skip it to avoid side effects like sessionStorage being updated.
        if (pageBeingUnloaded) {
            return;
        }

        const clickInsidePopup = popupRef?.current?.contains(
            event.relatedTarget,
        );

        const clickInsideButton = buttonRef?.current?.contains(
            event.relatedTarget,
        );

        if (!clickInsidePopup && !clickInsideButton) {
            handleBlur();
        }
    }

    useEffect(() => {
        function beforeUnload() {
            pageBeingUnloaded = true;
        }
        if (isOpen) {
            window.addEventListener('beforeunload', beforeUnload);
        }
        return () => {
            window.removeEventListener('beforeunload', beforeUnload);
        };
    }, [isOpen]);

    useEffect(() => {
        if (isOpen && popupRef.current !== null) {
            floating.update();
            popupRef.current.focus({ preventScroll: true });
        }
    }, [isOpen, actions, popupRef.current]);

    useEffect(() => {
        if (isOpen) {
            window.addEventListener('resize', handleBlur);
            return () => {
                window.removeEventListener('resize', handleBlur);
            };
        }
    }, [isOpen, handleBlur]);

    return (
        <>
            <TopbarButton
                label={formatMessage({ id: 'text_actions' })}
                icon="error_outline"
                data-testid={'header-action-log-button'}
                onClick={() => {
                    if (isOpen) {
                        closePopover();
                    } else {
                        openPopover(false);
                    }
                }}
                ref={combinedButtonRefs}
                onBlur={onBlur}
            />
            <Portal>
                <TopbarActionLogPopup
                    style={popupPositioningStyle}
                    hidden={!isOpen}
                    onBlur={onBlur}
                    onMouseOver={clearTimer}
                    ref={combinedPopupRefs}
                    actions={actions}
                    showAllResults={showAllResults}
                    onClickShowAll={onClickShowAll}
                    closePopover={closePopover}
                />
            </Portal>
        </>
    );
}

export const TopbarActionLogPopup = forwardRef(function HelpCenterPopup(
    {
        hidden,
        style,
        onBlur,
        onMouseOver,
        actions,
        showAllResults,
        onClickShowAll,
        closePopover,
    }: {
        hidden?: boolean;
        style?: CSSProperties;
        onBlur?: React.FocusEventHandler<HTMLDivElement>;
        onMouseOver?: React.MouseEventHandler<HTMLDivElement>;
        actions: ActionLogMessage[];
        showAllResults: boolean;
        onClickShowAll: React.MouseEventHandler<HTMLButtonElement>;
        closePopover: () => void;
    },
    ref: React.Ref<HTMLDivElement>,
) {
    const { formatMessage } = useIntl();

    const innerRef = useRef<HTMLDivElement>(null);
    const composedRef = useComposedRefs(ref, innerRef);

    // If showAllResults is false, then only show the most recent action
    const actionsToShow = actions.slice(0, showAllResults ? actions.length : 1);

    return (
        <section
            data-testid="action-log-popup"
            className={classNames(
                'tlx-topbar-action-log__actions-popup atl-bg-white atl-rounded atl-p-16',
                hidden ? 'atl-hidden' : undefined,
            )}
            style={style}
            tabIndex={-1}
            ref={composedRef}
            onMouseOver={onMouseOver}
            onBlur={onBlur}
        >
            <div className="atl-flex atl-items-center atl-justify-between atl-gap-8">
                <h2 className="atl-text-lg atl-font-medium atl-my-8">
                    {formatMessage({ id: 'text_actions' })}
                </h2>
                <Button
                    variant="icon"
                    data-testid="close-button"
                    aria-label="close"
                    onClick={closePopover}
                >
                    <CloseIcon />
                </Button>
            </div>
            {actions.length > 0 ? (
                <ul className="tlx-topbar-action-log__actions-list">
                    {actionsToShow.map((action, index) => (
                        <TopbarActionLogEntry
                            key={index}
                            action={action}
                        ></TopbarActionLogEntry>
                    ))}
                </ul>
            ) : (
                <p data-testid="action-log-no-actions">
                    {formatMessage({ id: 'text_no_action_log' })}
                </p>
            )}
            {!showAllResults && actions.length > 1 && (
                <div className="atl-mt-16">
                    <ShowMoreButton
                        popupRef={innerRef}
                        onClick={onClickShowAll}
                    />
                </div>
            )}
        </section>
    );
});

function ShowMoreButton({
    popupRef,
    onClick,
}: {
    popupRef: React.RefObject<HTMLDivElement>;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}) {
    const { formatMessage } = useIntl();

    // Restore focus to the popupRef (parent popup) when the button unmounts
    useEffect(() => {
        const section = popupRef.current;
        return () => {
            section?.focus({ preventScroll: true });
        };
    }, [popupRef]);

    return (
        <Button
            variant="secondary"
            onClick={onClick}
            data-testid="show-all-actions-button"
        >
            {formatMessage({ id: 'text_show_more' })}
        </Button>
    );
}

// convert a Date object to hours and minutes with zero padding
// for example 03:05
function dateAsHoursAndMinutes(date: Date) {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${hours}:${minutes}`;
}

function TopbarActionLogEntry({ action }: { action: ActionLogMessage }) {
    return (
        <li className="atl-my-16">
            <div className="atl-flex atl-gap-24" data-testid="action-log-entry">
                <div className="tlx-topbar-action-log__action-log-entry">
                    <h3
                        className="atl-m-0 atl-text-base atl-font-medium"
                        data-testid="action-log-pagename"
                    >
                        {action.pageName}
                    </h3>
                    <TopbarActionLogEntryMessage
                        message={action.message}
                        format={action.format}
                    />
                </div>
                <div className="atl-ml-auto atl-text-right atl-text-grey-80 atl-text-sm">
                    {dateAsHoursAndMinutes(action.date)}
                </div>
            </div>
        </li>
    );
}

function TopbarActionLogEntryMessage({
    message,
    format,
}: {
    message: string;
    format?: ActionLogMessageFormat;
}) {
    if (format === 'markdown') {
        return (
            <div className="atl-text-sm" data-testid="action-log-message">
                <InlineMarkdown>{message}</InlineMarkdown>
            </div>
        );
    }

    return (
        <Sanitize
            className="atl-text-sm atl-m-0"
            data-testid="action-log-message"
            htmlContent={message}
        />
    );
}
