import React from 'react';

export interface ErrorBoundaryProps {
    fallback?:
        | React.ReactElement
        | ((state: ErrorBoundaryState) => React.ReactElement);
    children: React.ReactNode;
}

export interface ErrorBoundaryState {
    error: Error | null;
    componentStack: React.ErrorInfo['componentStack'] | null;
}

/**
 * Simplified version of Sentry.ErrorBoundary that catches unhandled exceptions during render, reports the error to Sentry and optionally renders a fallback.
 *
 * @see https://github.com/getsentry/sentry-javascript/blob/develop/packages/react/src/errorboundary.tsx
 */
export class ErrorBoundary extends React.Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    state = { error: null, componentStack: null };

    componentDidCatch(error: Error, { componentStack }: React.ErrorInfo): void {
        window.Sentry?.captureException(error, {
            contexts: {
                react: { componentStack },
            },
        });

        this.setState({ error, componentStack });
    }

    render() {
        if (this.state.error !== null) {
            const fallback =
                typeof this.props.fallback === 'function'
                    ? this.props.fallback(this.state)
                    : this.props.fallback;

            return React.isValidElement(fallback) ? fallback : null;
        }

        return this.props.children;
    }
}
