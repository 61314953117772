import { UseFetchReturn } from '../../hooks/useFetch';
import { MenuDTO } from '../MainMenu/types';
import { TrialAccountProps } from '../TrialAccount/TrialAccountWidget';
import { useEffect, useState } from 'react';
import { parseISO } from 'date-fns';
import { getRemainingTime } from '../TrialAccount/trialAccountUtils';

function useCurrentTime(enableClock: boolean): Date {
    // update it once a minute
    const [currentTime, setCurrentTime] = useState(new Date());
    useEffect(() => {
        if (enableClock) {
            const focusHandler = () => setCurrentTime(new Date());
            const intervalRef = window.setInterval(() => {
                setCurrentTime(new Date());
            }, 60 * 1000);
            window.addEventListener('focus', focusHandler);
            return () => {
                window.clearInterval(intervalRef);
                window.removeEventListener('focus', focusHandler);
            };
        }
    }, [enableClock]);
    return currentTime;
}

export function useTrialAccountProps(
    menu: UseFetchReturn<MenuDTO>,
): TrialAccountProps {
    const showTrialWidget = menu.data?.showTrialWidget ?? false;
    const trialExpiresDate = menu.data?.trialExpiresDate ?? undefined;
    const currentTime = useCurrentTime(showTrialWidget);

    const endDate =
        trialExpiresDate !== undefined
            ? parseISO(trialExpiresDate)
            : new Date();

    const { remainingDays, remainingHours, remainingMinutes } =
        getRemainingTime(endDate, currentTime);

    if (menu.data === undefined || !menu.data.showTrialWidget) {
        return {
            packageName: '',
            remainingDays: 0,
            remainingHours: 0,
            remainingMinutes: 0,
            purchaseUrl: '',
        };
    }

    return {
        packageName: menu.data?.packageName ?? '',
        remainingDays,
        remainingHours,
        remainingMinutes,
        purchaseUrl: menu.data?.purchaseUrl ?? '',
    };
}
