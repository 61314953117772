import { ChatIcon } from '@tlx/atlas';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

export function ActiveChatButton({
    unreadCount,
    openChat,
    hidden,
    style,
}: {
    unreadCount: number;
    openChat: () => void;
    hidden?: boolean;
    style?: React.CSSProperties;
}) {
    const { locale, formatMessage } = useIntl();

    return (
        <button
            className={classNames('tlx-help-center-active-chat-button', {
                'tlx-help-center-active-chat-button--locale-en':
                    locale === 'en',
                'tlx-help-center-active-chat-button--two-digit':
                    unreadCount > 9,
                'tlx-help-center-active-chat-button--three-digit':
                    unreadCount > 99,
            })}
            style={style}
            hidden={hidden}
            data-testid="open-active-chat-button"
            onClick={openChat}
        >
            <div className="tlx-help-center-active-chat-button__label">
                {formatMessage({
                    id: 'text_help_center_active_chat',
                })}
            </div>
            <div className="tlx-help-center-active-chat-button__icon">
                <ChatIcon />
                <UnreadCount count={unreadCount} />
            </div>
        </button>
    );
}

function UnreadCount({ count }: { count: number }) {
    if (count === 0) {
        return null;
    }

    return (
        <div className="tlx-help-center-active-chat-button__unread-count">
            {formatCount(count)}
        </div>
    );
}

function formatCount(count: number): string | null {
    if (count < 2) return null;
    if (count < 100) return count.toString();

    return '99+';
}
