export function getNextListItemFromEventKeyName<T extends { id: number }>(
    items: T[],
    currentId: number | undefined,
    keyWithModifier: string,
): T | undefined {
    if (items.length === 0) {
        return;
    }

    switch (keyWithModifier) {
        // Select next company when pressing down arrow
        case 'ArrowDown':
        case 'Tab': {
            if (currentId === undefined) {
                return items[0];
            }

            const activeCompanyIndex = items.findIndex(
                (company) => company.id === currentId,
            );
            const nextIndex = Math.min(
                items.length - 1,
                activeCompanyIndex + 1,
            );

            return items[nextIndex];
        }
        // Select previous company when pressing up arrow
        case 'ArrowUp':
        case 'ShiftTab': {
            if (currentId === undefined) {
                return items[0];
            }

            const activeCompanyIndex = items.findIndex(
                (company) => company.id === currentId,
            );

            const nextIndex = Math.max(0, activeCompanyIndex - 1);

            return items[nextIndex];
        }
        // Select first company when pressing page up or home key
        case 'PageUp':
        case 'Home': {
            return items[0];
        }
        // Select last company when pressing page up or home key
        case 'PageDown':
        case 'End': {
            return items[items.length - 1];
        }
    }
}
