import { offset, shift, useFloating } from '@floating-ui/react';
import { Icon, Portal } from '@tlx/atlas';
import classNames from 'classnames';
import React, {
    CSSProperties,
    forwardRef,
    useEffect,
    useLayoutEffect,
    useRef,
} from 'react';
import { useIntl } from 'react-intl';
import { TopbarButton } from '../Topbar/Topbar';
import { useComposedRefs } from '../Topbar/useComposedRefs';
import { Sanitize } from '../Sanitize/Sanitize';

export type TopbarErrorMessagesProps = {
    errorMessages: string[];
    isOpen: boolean;
    openPopover(fadeOut: boolean): void;
    closePopover(): void;
    clearTimer(): void;
};

export function TopbarErrorMessages({
    errorMessages,
    isOpen,
    openPopover,
    closePopover,
    clearTimer,
}: TopbarErrorMessagesProps) {
    const { formatMessage } = useIntl();
    const floating = useFloating({
        placement: 'bottom-start',
        // The 11px value is need to align the popup with the other popup on Topbar
        middleware: [shift({ padding: 11 }), offset(11)],
    });

    const popupPositioningStyle: CSSProperties = {
        position: floating.strategy,
        top: floating.y ?? '',
        left: floating.x ?? '',
    };

    const buttonRef = useRef<HTMLButtonElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);

    const combinedButtonRefs = useComposedRefs(
        buttonRef,
        floating.refs.setReference,
    );
    const combinedPopupRefs = useComposedRefs(
        popupRef,
        floating.refs.setFloating,
    );

    function onBlur(
        event: React.FocusEvent<HTMLButtonElement | HTMLDivElement>,
    ) {
        const clickInsidePopup = popupRef?.current?.contains(
            event.relatedTarget,
        );

        const clickInsideButton = buttonRef?.current?.contains(
            event.relatedTarget,
        );

        if (!clickInsidePopup && !clickInsideButton) {
            closePopover();
        }
    }

    useLayoutEffect(() => {
        if (isOpen) {
            floating.update();
            popupRef.current?.focus({ preventScroll: true });
        }
    }, [isOpen]);

    useEffect(() => {
        function handler() {
            closePopover();
        }

        if (isOpen) {
            window.addEventListener('resize', handler);
            return () => {
                window.removeEventListener('resize', handler);
            };
        }
    }, [isOpen]);

    return (
        <>
            <TopbarButton
                label={formatMessage({ id: 'text_error' })}
                icon={'warning_amber'}
                data-testid="header-errors-button"
                className="tlx-topbar__button--errors"
                onClick={() => (isOpen ? closePopover() : openPopover(false))}
                ref={combinedButtonRefs}
                onBlur={onBlur}
            />
            <Portal>
                <TopbarErrorMessagesPopup
                    style={popupPositioningStyle}
                    hidden={!isOpen}
                    onBlur={onBlur}
                    onMouseOver={clearTimer}
                    ref={combinedPopupRefs}
                    errorMessages={errorMessages}
                />
            </Portal>
        </>
    );
}

function NoErrorMessages() {
    const { formatMessage } = useIntl();

    return (
        <>
            <div className="atl-flex atl-items-center atl-gap-8">
                <Icon className="atl-text-green-120">check_circle</Icon>
                <h2 className="atl-text-lg atl-font-medium atl-my-8">
                    {formatMessage({ id: 'text_error' })}
                </h2>
            </div>
            <p>{formatMessage({ id: 'text_no_error_messages' })}</p>
        </>
    );
}

export const TopbarErrorMessagesPopup = forwardRef(function HelpCenterPopup(
    {
        hidden,
        style,
        onBlur,
        onMouseOver,
        errorMessages,
    }: {
        hidden?: boolean;
        style?: CSSProperties;
        onBlur?: React.FocusEventHandler<HTMLDivElement>;
        onMouseOver?: React.MouseEventHandler<HTMLDivElement>;
        errorMessages: string[];
    },
    ref: React.Ref<HTMLDivElement>,
) {
    const { formatMessage } = useIntl();

    return (
        <section
            className={classNames(
                'tlx-topbar-error-messages__error-messages-popup atl-bg-white atl-rounded atl-p-16',
                hidden ? 'atl-hidden' : undefined,
            )}
            style={style}
            tabIndex={-1}
            ref={ref}
            onBlur={onBlur}
            onMouseOver={onMouseOver}
        >
            {errorMessages.length > 0 ? (
                <>
                    <div className="atl-flex atl-items-center atl-gap-8">
                        <Icon className="atl-text-red-120">warning_amber</Icon>
                        <h2 className="atl-text-lg atl-font-medium atl-my-8">
                            {formatMessage({ id: 'validation_failed' })}
                        </h2>
                    </div>
                    <ul className="tlx-topbar-error-messages__error-messages-list">
                        {errorMessages.map((errorMessage, index) => (
                            <TopbarErrorMessage
                                key={index}
                                message={errorMessage}
                            />
                        ))}
                    </ul>
                </>
            ) : (
                <NoErrorMessages />
            )}
        </section>
    );
});

function TopbarErrorMessage({ message }: { message: string }) {
    return (
        <li className="atl-my-8 atl-text-sm tlx-topbar-error-messages__error-message-entry">
            <Sanitize htmlContent={message}></Sanitize>
        </li>
    );
}
