import { ContactDTO } from './types';
import { useApiSearch } from '../useSearch';

const endpoint = `/v2/contact/query`;
const searchParams = new URLSearchParams({
    fields: 'id,firstName,lastName,customer(name),email',
});

export function useContactSearch(query: string) {
    return useApiSearch<ContactDTO>(endpoint, query, searchParams);
}
