import { ActionLogMessage } from './components/TopbarActionLog/TopbarActionLog';

export const BANNERS_UPDATE = 'tlx:banners:update';
export const ACTION_LOG_ADD = 'tlx:action-log:add';
export const ERROR_MESSAGES_ADD = 'tlx:error-messages:add';
export const ERROR_MESSAGES_CLEAR = 'tlx:error-messages:clear';
export const EXPAND_MENU_ITEM = 'tlx:menu-item:expand';
export const OPEN_HELP_CENTER = 'tlx:help-center:open';
export const CLOSE_HELP_CENTER = 'tlx:help-center:close';
export const HELP_CENTER_SEARCH = 'tlx:help-center:search';
export const AI_ASSISTANT = 'tlx:ai-assistant';
export const OPEN_KEYBOARD_SHORTCUTS = 'tlx:keyboard-shortcuts:open';
export const ZENDESK_OPEN_CHAT = 'zendesk:open-chat';
export const ZENDESK_USER_EVENT = 'zendesk:user-event';
export const ACCOUNT_HELP_SEARCH = 'tlx:account-help:search';

export type AssistantEvent = CustomEvent<{ action: string }>;
export type ZendeskOpenChatEvent = CustomEvent<{
    initialChat: string | null;
    tags: string[];
}>;
export type AccountHelpEvent = CustomEvent<{ query: string }>;

declare global {
    interface WindowEventMap {
        [AI_ASSISTANT]: AssistantEvent;
        [BANNERS_UPDATE]: CustomEvent<void>;
        // Custom events sent from the monolith
        [ACTION_LOG_ADD]: CustomEvent<ActionLogMessage>;
        [ERROR_MESSAGES_ADD]: CustomEvent<{ message: string }>;
        [ERROR_MESSAGES_CLEAR]: CustomEvent<void>;
        [OPEN_HELP_CENTER]: CustomEvent<void>;
        [CLOSE_HELP_CENTER]: CustomEvent<void>;
        [EXPAND_MENU_ITEM]: CustomEvent<{ id: string }>;
        [HELP_CENTER_SEARCH]: CustomEvent<void>;
        [ZENDESK_OPEN_CHAT]: ZendeskOpenChatEvent;
        [ZENDESK_USER_EVENT]: CustomEvent<{ category: string; action: string }>;
    }
}
