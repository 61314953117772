// Heavily inspired by VSCode's keybindings.json
export function getKeyboardEventKeybinding(event: KeyboardEvent): string {
    const segments: string[] = [];

    if (event.ctrlKey) {
        segments.push('ctrl');
    }

    if (event.shiftKey) {
        segments.push('shift');
    }

    if (event.altKey) {
        segments.push('alt');
    }

    if (event.metaKey) {
        segments.push('cmd');
    }

    segments.push(event.key);

    return segments.join('+');
}
