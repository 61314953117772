import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

export type TrialAccountProps = {
    packageName: string;
    remainingDays: number;
    remainingHours: number;
    remainingMinutes: number;
    purchaseUrl: string;
};

function RemainingText(props: TrialAccountProps) {
    return (
        <>
            <b>{props.remainingDays}</b>{' '}
            <span>
                <FormattedMessage
                    id={
                        props.remainingDays === 1
                            ? 'text_day_lower'
                            : 'text_days_lower'
                    }
                />
            </span>{' '}
            <FormattedMessage id="text_and" /> <b>{props.remainingHours}</b>{' '}
            <span>
                <FormattedMessage
                    id={
                        props.remainingHours === 1
                            ? 'text_hour_lower'
                            : 'text_hours_lower'
                    }
                />
            </span>{' '}
            <span>
                <FormattedMessage id="text_time_left" />
            </span>
        </>
    );
}

// Same as above, but instead of days/hours it shows hours/minutes
function SameDayRemainingText(props: TrialAccountProps) {
    return (
        <>
            <b>{props.remainingHours}</b>{' '}
            <span>
                <FormattedMessage
                    id={
                        props.remainingHours === 1
                            ? 'text_hour_lower'
                            : 'text_hours_lower'
                    }
                />
            </span>{' '}
            <FormattedMessage id="text_and" /> <b>{props.remainingMinutes}</b>{' '}
            <span>
                <FormattedMessage
                    id={
                        props.remainingMinutes === 1
                            ? 'text_minute_lower'
                            : 'text_minutes_lower'
                    }
                />
            </span>{' '}
            <span>
                <FormattedMessage id="text_time_left" />
            </span>
        </>
    );
}

export function TrialAccountTopWidget(props: TrialAccountProps) {
    return (
        <div className="atl-flex atl-gap-8 atl-flex-col atl-text-base atl-my-24">
            <div className="atl-text-center">
                {props.remainingDays > 0 ? (
                    <RemainingText {...props} />
                ) : (
                    <SameDayRemainingText {...props} />
                )}
            </div>
            <a
                href={props.purchaseUrl}
                data-testid="trial-widget-top-buy-now-button"
                className="atl-button atl-button--primary atl-justify-center tlx-trial-account-widget-top__buy-button"
            >
                <FormattedMessage
                    id="text_buy_plan"
                    values={{ plan: props.packageName }}
                />
            </a>
        </div>
    );
}

export function TrialAccountBottomWidget(props: TrialAccountProps) {
    return (
        <div className="tlx-trial-account-widget atl-mt-32">
            <h1 className="atl-m-0">
                <span className="atl-text-base atl-font-normal">
                    <FormattedMessage
                        id={'text_now_testing'}
                        values={{ plan: props.packageName }}
                    />
                </span>
            </h1>
            <div className="atl-flex atl-gap-8 atl-justify-center">
                {props.remainingDays > 0 ? (
                    <>
                        <DaysRemaining value={props.remainingDays} isPrimary />
                        <HoursRemaining value={props.remainingHours} />
                    </>
                ) : (
                    <>
                        <HoursRemaining
                            value={props.remainingHours}
                            isPrimary
                        />
                        <MinutesRemaining value={props.remainingMinutes} />
                    </>
                )}
            </div>
            <a
                href={props.purchaseUrl}
                data-testid="trial-widget-bottom-buy-now-button"
                className="atl-button atl-button--primary atl-w-full atl-justify-center atl-box-border"
            >
                <FormattedMessage id={'text_buy_now'} />
            </a>
        </div>
    );
}

function DaysRemaining({
    value,
    isPrimary,
}: {
    value: number;
    isPrimary: boolean;
}) {
    const { formatMessage } = useIntl();
    const label = formatMessage({
        id: value === 1 ? 'text_day_upper' : 'text_days_upper',
    });

    return <TimeBox value={value} label={label} isPrimary={isPrimary} />;
}

function HoursRemaining({
    value,
    isPrimary,
}: {
    value: number;
    isPrimary?: boolean;
}) {
    const { formatMessage } = useIntl();
    const label = formatMessage({
        id: value === 1 ? 'text_hour_upper' : 'text_hours_upper',
    });

    return <TimeBox value={value} label={label} isPrimary={isPrimary} />;
}

function MinutesRemaining({
    value,
    isPrimary,
}: {
    value: number;
    isPrimary?: boolean;
}) {
    const { formatMessage } = useIntl();
    const label = formatMessage({
        id: value === 1 ? 'text_minute_upper' : 'text_minutes_upper',
    });

    return <TimeBox value={value} label={label} isPrimary={isPrimary} />;
}

function TimeBox({
    value,
    label,
    isPrimary,
}: {
    value: number;
    label: string;
    isPrimary?: boolean;
}) {
    return (
        <div
            className={classNames(
                'tlx-trial-account-widget__time-box',
                'atl-text-blue-100',
                isPrimary ? 'atl-border-blue-100' : 'atl-border-blue-20',
            )}
        >
            <div className="tlx-trial-account-widget__time-value atl-font-medium">
                {value}
            </div>
            <div className="tlx-trial-account-widget__time-label atl-font-normal">
                {label}
            </div>
        </div>
    );
}
