import { getContextId } from '@tlx/astro-shared';

export function getUrlWithContext(
    url: string,
    params?: { [name: string]: string },
) {
    const urlWithParams = new URL(url, window.origin);
    urlWithParams.searchParams.set('contextId', getContextId() || '');
    if (params !== undefined) {
        Object.entries(params).forEach(([key, value]) =>
            urlWithParams.searchParams.set(key, value),
        );
    }
    return urlWithParams.toString();
}
