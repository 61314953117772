const excludedWords = ['AS', 'ANS', 'DA'];

export const supportedCharacters =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZÆØÅÄÖ'.split('');

export function getCompanyInitials(name: string): string {
    return name
        .split(' ')
        .filter((word) => !excludedWords.includes(word))
        .map((word) => word.charAt(0).toLocaleUpperCase())
        .filter((char) => supportedCharacters.includes(char))
        .join('')
        .slice(0, 2);
}
