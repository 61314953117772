import {
    GoBackButton,
    LoadMoreButton,
    SearchResult,
    SearchResultItem,
    SearchResultItemField,
    SearchResultItemIcon,
    SearchResultItemLink,
    SearchResultItems,
    SearchResultItemTitle,
    SearchResultSkeleton,
    SearchResultTitle,
    SearchResultTitleLink,
} from '../SearchContainer';
import { UseSearchCategoryReturn } from '../types';
import { getUrlWithContext } from '../util';

import { useResultTitleTranslation } from '../useResultTitleTranslation';
import { contactCategory, ContactDTO } from './types';

export function ContactResult(props: UseSearchCategoryReturn<ContactDTO>) {
    const translation = useResultTitleTranslation('text_contacts');

    const { data, hasMore, loading, loadMore, goBack } = props;

    const hasData = data !== undefined && data.length > 0;
    if (!hasData && !loading) {
        return null;
    }

    return (
        <SearchResult category={contactCategory}>
            <SearchResultTitle>
                <div className="atl-flex atl-items-center">
                    <GoBackButton onGoBack={goBack} />
                    {translation.title}
                </div>
                <SearchResultTitleLink
                    href={getUrlWithContext('/execute/listContacts')}
                >
                    {translation.overviewLinkText}
                </SearchResultTitleLink>
            </SearchResultTitle>
            <SearchResultItems>
                {data?.map((item: ContactDTO) => (
                    <ContactResultItem key={item.id} data={item} />
                ))}
                {loading && <SearchResultSkeleton />}
                {!loading && hasMore && (
                    <LoadMoreButton
                        data-testid={`search-show-more`}
                        onLoadMore={loadMore}
                        category={contactCategory}
                    />
                )}
            </SearchResultItems>
        </SearchResult>
    );
}

export function ContactResultItem({ data }: { data: ContactDTO }) {
    const url = getUrlWithContext('/execute/employeeMenu', {
        employeeId: data.id.toString(),
    });
    return (
        <SearchResultItem>
            <SearchResultItemIcon className="tlx-search__result-contact-icon">
                people
            </SearchResultItemIcon>
            <SearchResultItemLink href={url} category={contactCategory}>
                <SearchResultItemTitle>{`${data.firstName || ''} ${
                    data.lastName || ''
                }`}</SearchResultItemTitle>
                <SearchResultItemField translationKey={'text_customer'}>
                    {data.customer?.name}
                </SearchResultItemField>
                <SearchResultItemField translationKey={'text_email2'}>
                    {data.email}
                </SearchResultItemField>
            </SearchResultItemLink>
        </SearchResultItem>
    );
}
