import { useEffect, useState } from 'react';

export function useActiveDocumentationComponent(): number | undefined {
    const [documentationComponent, setDocumentationComponent] = useState<
        number | undefined
    >(getDocumentationComponent);

    useEffect(() => {
        const timer = window.setInterval(() => {
            setDocumentationComponent(getDocumentationComponent());
        }, 500);

        return () => window.clearInterval(timer);
    }, []);

    return documentationComponent;
}

function getDocumentationComponent(): number | undefined {
    const documentationComponent = window.getActiveDocumentationComponent
        ? Number(window.getActiveDocumentationComponent())
        : undefined;

    if (documentationComponent === -1) {
        return undefined;
    }

    return documentationComponent;
}
