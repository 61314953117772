import { useEffect, useRef } from 'react';

export type Props = {
    hasMore?: boolean;
    loadMore?: () => void;
};
export function LoadMoreTarget({ hasMore, loadMore }: Props) {
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!hasMore) {
            return;
        }

        const target = ref.current;

        if (target === null) {
            return;
        }

        const onIntersection = (entries: IntersectionObserverEntry[]): void => {
            if (loadMore && entries.some((entry) => entry.isIntersecting)) {
                loadMore();
            }
        };

        const observer = new IntersectionObserver(onIntersection, {
            rootMargin: '250px',
        });

        observer.observe(target);

        return () => {
            observer.unobserve(target);
        };
    }, [hasMore, loadMore]);

    return <div ref={ref}></div>;
}
