import { createAPIRequest, createAPIResponse } from '@tlx/astro-shared';
import { SupportedLocale } from '@tlx/intl';
import { useState } from 'react';
import { useCurrentRelativeUrl } from '../../hooks/useCurrentRelativeUrl';
import { UseFetchReturn, useFetch } from '../../hooks/useFetch';
import { FavoriteDTO, FavoriteMenuDTO } from '../MainMenu/types';

export type FavoriteData = {
    rank: number;
    name: string;
    pageUrl: string;
};

export type UseFavoritesReturn = {
    favorites: UseFetchReturn<FavoriteMenuDTO>;
    isCurrentFavorite: UseFetchReturn<boolean>;
    currentFavorite: FavoriteDTO | undefined;
    isAddFavoriteModalShown: boolean;
    isModifyFavoriteModalShown: boolean;
    closeFavoriteModal(): void;
    createFavorite(data: FavoriteData): Promise<void>;
    updateFavorite(id: number, data: FavoriteData): Promise<void>;
    deleteFavorite(id: number): Promise<void>;
    onClickAddFavorite(): void;
    onClickModifyFavorite(): void;
    ready: boolean;
};

async function createFavorite({
    rank,
    name,
    pageUrl,
}: FavoriteData): Promise<number> {
    const request = createAPIRequest('/v2/internal/favorites', {
        method: 'post',
        body: new URLSearchParams({ rank: rank.toString(), name, pageUrl }),
    });
    const response = await fetch(request);
    const data = await createAPIResponse<{ value: number }>(request, response);
    return data.value;
}

async function updateFavorite(
    id: number,
    { rank, name, pageUrl }: FavoriteData,
): Promise<void> {
    const request = createAPIRequest('/v2/internal/favorites/' + id, {
        method: 'put',
        body: new URLSearchParams({ rank: rank.toString(), name, pageUrl }),
    });
    const response = await fetch(request);
    await createAPIResponse(request, response);
}

async function deleteFavorite(id: number): Promise<void> {
    const request = createAPIRequest('/v2/internal/favorites/' + id, {
        method: 'delete',
    });
    const response = await fetch(request);
    await createAPIResponse(request, response);
}

function sanitizeRelativeUrl(url: string): string {
    /**
     * While base is optional there's a bug in Safari <= 14 that prevents us from passing undefined
     *
     * @see https://bugs.webkit.org/show_bug.cgi?id=216841
     */
    const result = new URL(url, window.origin);
    result.searchParams.delete('favoriteId');
    result.searchParams.delete('favouriteId'); // Naming inconsistencies in legacy code

    return result.pathname + result.search + result.hash;
}

export function useFavorites(locale: SupportedLocale): UseFavoritesReturn {
    const favorites = useFetch<FavoriteMenuDTO>('/v2/internal/favorites');

    const [isAddFavoriteModalShown, setIsAddFavoriteModalShown] =
        useState(false);
    const [isModifyFavoriteModalShown, setIsModifyFavoriteModalShown] =
        useState(false);

    const currentRelativeUrl = useCurrentRelativeUrl();
    const sanitizedRelativeUrl = sanitizeRelativeUrl(currentRelativeUrl);

    async function deleteFavoriteAndRefresh(id: number) {
        await deleteFavorite(id);

        // If we're currently on the favorite page that is being deleted,
        // remove the favorite ID from the current URL.
        history.replaceState(history.state, '', sanitizedRelativeUrl);

        if (favorites.data === undefined) {
            favorites.refresh();
        } else {
            favorites.refresh({
                ...favorites.data,
                favoriteList: favorites.data.favoriteList.filter(
                    (favorite) => favorite.id !== id,
                ),
            });
        }
    }

    function compare(a: FavoriteDTO, b: FavoriteDTO): number {
        const collator = new Intl.Collator(locale);

        return collator.compare(a.name, b.name);
    }

    async function createFavoriteAndRefresh(data: FavoriteData) {
        const newId = await createFavorite(data);
        if (favorites.data === undefined) {
            favorites.refresh();
        } else {
            favorites.refresh({
                ...favorites.data,
                favoriteList: [
                    {
                        id: newId,
                        ...data,
                    },
                    ...favorites.data.favoriteList,
                ].sort(compare),
            });
        }
    }

    async function updateFavoriteAndRefresh(id: number, data: FavoriteData) {
        await updateFavorite(id, data);
        if (favorites.data === undefined) {
            favorites.refresh();
        } else {
            favorites.refresh({
                ...favorites.data,
                favoriteList: favorites.data.favoriteList
                    .map((favorite) =>
                        favorite.id === id ? { id, ...data } : favorite,
                    )
                    .sort(compare),
            });
        }
    }

    let isCurrentFavorite: UseFetchReturn<boolean>;
    let currentFavorite: FavoriteDTO | undefined = undefined;
    if (favorites.data === undefined) {
        isCurrentFavorite = {
            data: undefined,
            error: undefined,
            refresh() {
                /* do nothing */
            },
            ready: false,
        };
    } else if (favorites.error !== undefined) {
        isCurrentFavorite = {
            data: false,
            error: favorites.error,
            refresh() {
                /* do nothing */
            },
            ready: true,
        };
    } else {
        const foundIndex = favorites.data.favoriteList.findIndex((favorite) => {
            const sanitized = sanitizeRelativeUrl(favorite.pageUrl);
            return currentRelativeUrl === sanitized;
        });

        if (foundIndex !== -1) {
            currentFavorite = favorites.data.favoriteList[foundIndex];
        }

        isCurrentFavorite = {
            error: undefined,
            data: foundIndex !== -1,
            refresh() {
                /* do nothing */
            },
            ready: true,
        };
    }

    return {
        favorites,
        isCurrentFavorite,
        currentFavorite,
        isAddFavoriteModalShown,
        isModifyFavoriteModalShown,
        closeFavoriteModal() {
            setIsAddFavoriteModalShown(false);
            setIsModifyFavoriteModalShown(false);
        },
        createFavorite: createFavoriteAndRefresh,
        updateFavorite: updateFavoriteAndRefresh,
        deleteFavorite: deleteFavoriteAndRefresh,
        onClickAddFavorite() {
            setIsAddFavoriteModalShown(true);
        },
        onClickModifyFavorite() {
            setIsModifyFavoriteModalShown(true);
        },
        ready: favorites.ready,
    };
}
