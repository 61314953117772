import { useEffect, useState } from 'react';

// As of now, there's no way to know if the browser supports paint holding or not.
// So we check  the user agent string.
// Blink-based browsers (Chrome, Edge,...) support paint holding.
function browserSupportsPaintHolding(): boolean {
    const isFirefox = navigator.userAgent.includes('Firefox');
    const isSafari =
        navigator.userAgent.includes('Safari') &&
        !navigator.userAgent.includes('Chrome');
    return !isFirefox && !isSafari;
}

// In Chrome/Blink, the total timeout is 500ms, but we use 350ms here to have some margin.
const chromeDelay = 350;

// If the browser supports paint holding, we want until all API requests are finished
// before doing the first paint. (But after a maximum deadline, we _have_ to paint (in order
// to prevent a flash of white screen), so we paint a loading state instead of the finished result.)
export function useDelayedStart() {
    const [delayedStart, setDelayedStart] = useState(false);
    useEffect(() => {
        const delay = browserSupportsPaintHolding() ? chromeDelay : 0;
        setTimeout(() => {
            setDelayedStart(true);
        }, delay);
    }, []);
    return delayedStart;
}
