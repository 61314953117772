import { useApiSearch } from '../useSearch';
import { ProjectDTO } from './types';

const endpoint = `/v2/project/query`;
const searchParams = new URLSearchParams({
    fields: 'id,name,number,customer(name),projectManager(firstName,lastName)',
});

export function useProjectSearch(query: string) {
    return useApiSearch<ProjectDTO>(endpoint, query, searchParams);
}
