import { useApiSearch } from '../useSearch';
import { PurchaseOrderDTO } from './types';

const endpoint = `/v2/purchaseOrder/query`;
const searchParams = new URLSearchParams({
    fields: 'id,number,supplier(name),project(displayName)',
});

export function usePurchaseOrderSearch(query: string) {
    return useApiSearch<PurchaseOrderDTO>(endpoint, query, searchParams);
}
