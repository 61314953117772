import classNames from 'classnames';
import { getCompanyInitials } from './getCompanyInitials';
import { getCompanyColor } from './getCompanyColor';

export function CompanyLogo({
    name,
    className,
    hidden,
}: {
    name: string;
    className?: string;
    hidden?: boolean;
}) {
    const initials = getCompanyInitials(name);
    const backgroundColor = getCompanyColor(name);

    return (
        <div
            className={classNames(
                'atl-flex atl-items-center atl-justify-center atl-text-sm atl-text-grey-100 atl-flex-none atl-font-medium atl-rounded-full tlx-company-logo',
                className,
            )}
            style={{ backgroundColor }}
            title={name}
            aria-hidden="true"
            hidden={hidden}
        >
            {initials}
        </div>
    );
}
