import { UseSearchCategoryReturn } from '../types';
import { activityCategory, ActivityDTO } from './types';

import {
    GoBackButton,
    LoadMoreButton,
    SearchResult,
    SearchResultItem,
    SearchResultItemField,
    SearchResultItemIcon,
    SearchResultItemLink,
    SearchResultItems,
    SearchResultItemTitle,
    SearchResultSkeleton,
    SearchResultTitle,
    SearchResultTitleLink,
} from '../SearchContainer';
import { useResultTitleTranslation } from '../useResultTitleTranslation';
import { getUrlWithContext } from '../util';

export function ActivityResult(props: UseSearchCategoryReturn<ActivityDTO>) {
    const translation = useResultTitleTranslation('text_tasks');

    const { data, hasMore, loading, loadMore, goBack } = props;

    const hasData = data !== undefined && data.length > 0;
    if (!hasData && !loading) {
        return null;
    }

    return (
        <SearchResult category={activityCategory}>
            <SearchResultTitle>
                <div className="atl-flex atl-items-center">
                    <GoBackButton onGoBack={goBack} />
                    {translation.title}
                </div>
                <SearchResultTitleLink
                    href={getUrlWithContext('/execute/listTasks', {
                        ownerId: '-1',
                    })}
                >
                    {translation.overviewLinkText}
                </SearchResultTitleLink>
            </SearchResultTitle>
            <SearchResultItems>
                {data?.map((item: ActivityDTO) => (
                    <ActivityResultItem key={item.id} data={item} />
                ))}
                {loading && <SearchResultSkeleton />}
                {!loading && hasMore && (
                    <LoadMoreButton
                        data-testid={`search-show-more`}
                        category={activityCategory}
                        onLoadMore={loadMore}
                    />
                )}
            </SearchResultItems>
        </SearchResult>
    );
}

export function ActivityResultItem({ data }: { data: ActivityDTO }) {
    const url = getUrlWithContext('/execute/activityExtMenu', {
        activityId: data.id.toString(),
    });
    return (
        <SearchResultItem>
            <SearchResultItemIcon className="tlx-search__result-activity-icon">
                check_circle
            </SearchResultItemIcon>
            <SearchResultItemLink href={url} category={activityCategory}>
                <SearchResultItemTitle>{data.name}</SearchResultItemTitle>
                <SearchResultItemField
                    translationKey={'text_task_number_short'}
                >
                    {data.number}
                </SearchResultItemField>
            </SearchResultItemLink>
        </SearchResultItem>
    );
}
