import type { BrowserPlugin } from '@snowplow/browser-tracker-core';
import {
    buildSelfDescribingEvent,
    PayloadBuilder,
    SelfDescribingJson,
} from '@snowplow/tracker-core';
import { ZENDESK_USER_EVENT } from '../../events';

interface ZendeskEvent extends SelfDescribingJson {
    schema: 'iglu:no.tripletex/zendesk_widget/jsonschema/1-0-0';
    data: {
        /**
         * The action that was performed, e.g. Opened Chat, Closed Chat
         */
        action: string;

        /**
         * The event category, e.g. Chat, Widget, Help Center
         */
        category: string;
    };
}

// Since we're using a custom launcher, we get some redundant events from Zendesk.
const IGNORED_ACTIONS = ['Chat Opened', 'Chat Shown'];

export function ZendeskPlugin(): BrowserPlugin {
    return {
        activateBrowserPlugin: (tracker) => {
            const handleEvent: EventListener = (event) => {
                const payloadBuilder = getPayloadBuilderFromZendeskEvent(event);

                if (payloadBuilder) {
                    tracker.core.track(payloadBuilder);
                }
            };

            window.addEventListener(ZENDESK_USER_EVENT, handleEvent);
        },
    };
}

export function getPayloadBuilderFromZendeskEvent(
    event: Event,
): PayloadBuilder | void {
    if (!isZendeskEvent(event) || isIgnoredEvent(event.detail)) {
        return;
    }

    return buildSelfDescribingEvent({
        event: getZendeskEvent(event.detail),
    });
}

export function getZendeskEvent({
    category,
    action,
}: ZendeskUserEvent): ZendeskEvent {
    return {
        schema: 'iglu:no.tripletex/zendesk_widget/jsonschema/1-0-0',
        data: {
            category,
            action,
        },
    };
}

function isZendeskEvent(event: Event): event is CustomEvent<ZendeskUserEvent> {
    return event.type === ZENDESK_USER_EVENT;
}

function isIgnoredEvent({ action }: ZendeskUserEvent): boolean {
    return IGNORED_ACTIONS.includes(action);
}
