export function getEventTarget(event: Event): Element | null {
    // We might be inside a Shadow DOM so we can't use event.target
    const target = event.composedPath()[0];

    if (!(target instanceof Element)) {
        return null;
    }

    return target;
}

export function getClasses(element: HTMLElement): string[] {
    return Array.from(element.classList);
}

export function getElementId(element: HTMLElement): string {
    return (
        element.dataset.trackingid ||
        element.dataset.testid ||
        element.getAttribute('name') ||
        element.id ||
        element.className
    );
}

export function getFormId(element: HTMLElement): string {
    const form = element.closest('form');

    if (form !== null) {
        return (
            form.getAttribute('data-trackingid') ??
            form.getAttribute('id') ??
            ''
        );
    } else {
        // We have some fake forms in Tripletex such as popup dialogs that we'd like to track
        const formId = element
            .closest('[data-formid]')
            ?.getAttribute('data-formid');

        if (formId) {
            return formId;
        }
    }

    return '';
}
