import { Alert, AlertCloseButton, AlertContent, AlertProps } from '@tlx/atlas';
import { CalloutDTO } from './types';
import { Sanitize } from '../Sanitize/Sanitize';

export type CalloutsProps = {
    callouts: CalloutDTO[];
    cancelCallout(id: number): void;
};

const SeveverityAlertVariant: Record<
    CalloutDTO['severity'],
    AlertProps['variant']
> = {
    CONFIRMATION: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error',
};

export function Callouts({ callouts, cancelCallout }: CalloutsProps) {
    return (
        <div className="tlx-callouts">
            {callouts.map((callout) => (
                <Alert
                    key={callout.id}
                    variant={SeveverityAlertVariant[callout.severity]}
                >
                    <Message callout={callout} />
                    <CancelButton
                        callout={callout}
                        onClick={() => cancelCallout(callout.id)}
                    />
                </Alert>
            ))}
        </div>
    );
}

function Message({ callout }: { callout: CalloutDTO }) {
    return (
        <AlertContent className="tlx-callouts__message">
            <Sanitize
                htmlContent={callout.message}
                data-testid="callout-content"
            />
        </AlertContent>
    );
}

function CancelButton({
    callout,
    onClick,
}: {
    callout: CalloutDTO;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}) {
    if (callout.severity !== 'INFO' && callout.severity !== 'WARNING') {
        return null;
    }

    return (
        <AlertCloseButton
            data-testid="callout-close-button"
            onClick={onClick}
        />
    );
}
