import { useEffect, useState } from 'react';
import { useAuthConfig } from './useAuthConfig';
import { useUserInfo } from './useUserInfo';

export type UseSessionReturn = {
    isLoggedOut: boolean;
    isSuspended: boolean;
    loginUrl?: string;
};

export function useSession(): UseSessionReturn {
    const isLoggedOut = useIsLoggedOut();
    const isSuspended = useIsSuspended();
    const loginUrl = useLoginUrl();

    useSessionIframes();
    useSessionRefresh();

    return { isLoggedOut, isSuspended, loginUrl };
}

function useIsLoggedOut(): boolean {
    const { data, isLoading } = useUserInfo();
    const isLoggedIn = data !== undefined;
    const [isLoggedOut, setIsLoggedOut] = useState(false);

    // Don't change the state if we are updating the user info
    useEffect(() => {
        if (isLoading) {
            return;
        }

        setIsLoggedOut(!isLoggedIn);
    }, [isLoading, isLoggedIn]);

    return isLoggedOut;
}

function useIsSuspended(): boolean {
    const { isSuspended } = useUserInfo();

    return isSuspended;
}

function useLoginUrl(): string | undefined {
    const authConfig = useAuthConfig();

    if (!authConfig) {
        return;
    }

    return authConfig.loginUrl;
}

function useSessionIframes() {
    const authConfig = useAuthConfig();
    const loggedInWithConnect = useLoggedInWithVismaConnect();

    useEffect(() => {
        if (!authConfig || !loggedInWithConnect) {
            return;
        }

        const rp = window.document.createElement('iframe');
        rp.id = 'vismaConnectSessionRefreshIframeRP';
        rp.src = authConfig.refreshSessionIframeUrl;
        rp.style.display = 'none';

        const op = window.document.createElement('iframe');
        op.id = 'vismaConnectSessionRefreshIframeOP';
        op.src = authConfig.checkSessionIframeUrl;
        op.style.display = 'none';

        window.document.body.appendChild(rp);
        window.document.body.appendChild(op);

        return () => {
            window.document.body.removeChild(rp);
            window.document.body.removeChild(op);
        };
    }, [authConfig, loggedInWithConnect]);
}

type RefreshMessage = 'tlx-refresh-session';

function useSessionRefresh() {
    const { refreshUserInfo } = useUserInfo();

    // Refresh user info when we get notified from a hidden iframe
    useEffect(() => {
        const handleMessage = (event: MessageEvent<RefreshMessage>) => {
            if (event.origin !== window.origin) {
                return;
            }

            if (event.data === 'tlx-refresh-session') {
                refreshUserInfo();
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [refreshUserInfo]);
}

function useLoggedInWithVismaConnect(): boolean {
    const { data } = useUserInfo();
    const loggedInWithConnect = data ? data.loggedInWithConnect : false;

    // If the user was ever logged in with Visma Connect we keep our iframes active
    const [wasLoggedInWithConnect, setWasLoggedInWithConnect] = useState(false);

    useEffect(() => {
        if (loggedInWithConnect) {
            setWasLoggedInWithConnect(true);
        }
    }, [loggedInWithConnect]);

    return wasLoggedInWithConnect;
}
