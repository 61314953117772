import { Icon, Portal } from '@tlx/atlas';
import React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

export type TopbarMobilePopoverProps = {
    children?: React.ReactNode;
    ['data-testid']?: string;
};

export function TopbarMobilePopover({
    children,
    ['data-testid']: dataTestId,
}: TopbarMobilePopoverProps) {
    return (
        <Portal>
            <div className="tlx-topbar-mobile-popover" data-testid={dataTestId}>
                {children}
            </div>
        </Portal>
    );
}

export function TopbarMobilePopoverCloseButton({
    onClose,
}: {
    onClose(): void;
}) {
    const { formatMessage } = useIntl();

    return (
        <button
            className="tlx-topbar-mobile-popover__close-button"
            data-testid="mobile-popover-close-button"
            onClick={onClose}
        >
            <Icon>arrow_back</Icon>
            {formatMessage({ id: 'text_close' })}
        </button>
    );
}

export function TopbarMobilePopoverHeader({
    children,
}: {
    children: React.ReactNode;
}) {
    return <div className="tlx-topbar-mobile-popover__header">{children}</div>;
}

export function TopbarMobilePopoverContent({
    className,
    children,
}: {
    className?: string;
    children: React.ReactNode;
}) {
    return (
        <div
            className={classNames(
                'tlx-topbar-mobile-popover__content',
                className,
            )}
        >
            {children}
        </div>
    );
}
