import { getContextId } from '@tlx/astro-shared';
import DOMPurify from 'dompurify';
import { HTMLProps, useMemo } from 'react';
import { addContextId } from '../../utils/addContextId';

const ALLOWED_DOMAINS = [
    'altinn.no',
    'calendar.google.com',
    'docs.google.com',
    'survey.alchemer.eu',
    'tripletex.no',
    'usabi.li',
    'youtube.com',
    'skatteetaten.no',
];

// We remove all DOMPurify hooks first, to prevent duplicate hook registrations.
// (Duplication can happen in HMR during development.)
DOMPurify.removeAllHooks();
DOMPurify.addHook('beforeSanitizeAttributes', function (node) {
    if (node instanceof HTMLAnchorElement) {
        node.removeAttribute('class');
        node.removeAttribute('id');
        node.removeAttribute('tabindex');

        if (node.href.startsWith('javascript:')) {
            // Regex: replace javascript:nav.link(...)
            // Examples:
            // javascript:nav.link('/execute/inboxVoucher?mode=1')
            // javascript:nav.link('/execute/updateCompany', '_blank')
            // javascript:nav.link('/execute/updateCompany', '_blank', 'someFragment')
            let replaced = decodeURIComponent(node.href)
                .replace(
                    /^javascript:nav\.link\('(.*?)', '(.*?)', '(.*?)'\)$/,
                    (_, url, target, fragment) => {
                        if (target === '_blank') {
                            node.target = '_blank';
                        }
                        return url + '#' + fragment;
                    },
                )
                .replace(
                    /^javascript:nav\.link\('(.*?)', '(.*?)'\)$/,
                    (_, url, target) => {
                        if (target === '_blank') {
                            node.target = '_blank';
                        }
                        return url;
                    },
                )
                .replace(/^javascript:nav\.link\('(.*?)'\)$/, '$1');

            const contextId = getContextId();
            if (contextId !== null) {
                replaced = addContextId(replaced, contextId);
            }
            node.href = replaced;
        }

        // Check if the URL is external, and if so, remove the href attribute.
        // We only want to support links to internal pages.
        const currentOrigin = window.location.origin;
        const linkUrl = new URL(node.href, currentOrigin);
        const linkOrigin = linkUrl.origin;

        const isOwnDomain = linkOrigin === currentOrigin;

        // If somebody tries to open a link in for example an iframe, we disallow that
        if (
            node.target !== '' &&
            node.target !== '_blank' &&
            node.target !== '_self'
        ) {
            node.target = '_blank';
        }

        // Check for tripletex.no, www.tripletex.no, hjelp.tripletex.no and so on
        const isAllowedExternalUrl = ALLOWED_DOMAINS.some((domain) =>
            linkUrl.host.endsWith(domain),
        );

        if (!isOwnDomain && !isAllowedExternalUrl) {
            node.removeAttribute('href');
            node.tabIndex = -1;
            node.dataset.disabledLink = 'true';
        }
    } else if (node instanceof HTMLDivElement) {
        if (
            node.getAttribute('id')?.startsWith('tourme-button-') &&
            node.classList.contains('tourme')
        ) {
            /* empty */
        } else {
            node.removeAttribute('id');
            node.removeAttribute('class');
            node.removeAttribute('tabindex');
        }
    }
});

export function Sanitize({
    className,
    htmlContent,
    'data-testid': dataTestId,
    onClick,
    ...props
}: {
    className?: string;
    htmlContent: string;
    'data-testid'?: string;
} & Omit<HTMLProps<HTMLDivElement>, 'children'>) {
    const sanitizedHtml = useMemo(
        () =>
            DOMPurify.sanitize(htmlContent, {
                ALLOWED_TAGS: ['a', 'br', 'p', 'div', 'em'],
                ALLOWED_ATTR: ['href', 'target', 'id', 'class', 'tabindex'],
            }),
        [htmlContent],
    );

    return (
        <div
            {...props}
            data-testid={dataTestId}
            onClick={(event) => {
                onClick?.(event);
                if (event.target instanceof HTMLAnchorElement) {
                    if (event.target.dataset.disabledLink === 'true') {
                        event.preventDefault();
                        event.stopPropagation();
                        return;
                    }

                    if (event.target.target === '_blank') {
                        return;
                    }

                    const href = event.target.href;

                    const navigateEvent = new CustomEvent('tlx:navigate', {
                        detail: {
                            href,
                        },
                        cancelable: true,
                    });

                    // Do not refresh the page when users use ctrl/cmd to open the link in a new tab.
                    if (!event.ctrlKey && !event.metaKey) {
                        window.dispatchEvent(navigateEvent);
                    }

                    // Some other component in the system (maybe frameless) might have
                    // intercepted and cancelled the custom event - in that case, we
                    // should cancel our event too.
                    if (navigateEvent.defaultPrevented) {
                        event.preventDefault();
                    }
                }
            }}
            className={className}
            dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
        />
    );
}
