import { useApiSearch } from '../useSearch';
import { OrderOfferDTO } from './types';

const endpoint = `/v2/order/offer/query`;
const searchParams = new URLSearchParams({
    fields: 'id,number,customer(name)',
});

export function useOrderOfferSearch(query: string) {
    return useApiSearch<OrderOfferDTO>(endpoint, query, searchParams);
}
