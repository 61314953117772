import { useApiSearch } from '../useSearch';
import { SupplierDTO } from './types';

const endpoint = `/v2/supplier/query`;
const searchParams = new URLSearchParams({
    fields: 'id,name,organizationNumber,supplierNumber',
});

export function useSupplierSearch(query: string) {
    return useApiSearch<SupplierDTO>(endpoint, query, searchParams);
}
