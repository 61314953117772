import { useIntl } from 'react-intl';

function twoDigits(digit: number) {
    if ((digit + '').length === 1) {
        return '0' + digit;
    }
    return digit;
}

export function Timeago({ date: dateString }: { date: string }): JSX.Element {
    const { formatMessage } = useIntl();

    const date = new Date(dateString);
    const millis = date.getTime();
    const durationSeconds = Math.floor((Date.now() - millis) / 1000);
    const hour = 60 * 60;

    // Below one minute
    if (durationSeconds < 60) {
        return <>{formatMessage({ id: 'text_now' })}</>;
    }

    // Below one hour
    if (durationSeconds < hour) {
        return (
            <>
                {Math.floor(durationSeconds / 60) +
                    ' ' +
                    formatMessage({ id: 'text_minute_short' })}
            </>
        );
    }

    // Below two hours
    if (durationSeconds < hour * 2) {
        return <>{'1 ' + formatMessage({ id: 'text_hour' })}</>;
    }

    // Below 24 hours
    if (durationSeconds < hour * 24) {
        return (
            <>
                {Math.floor(durationSeconds / 60 / 60) +
                    ' ' +
                    formatMessage({ id: 'text_hours_lower' })}
            </>
        );
    }

    // Below 48 hours
    if (durationSeconds < hour * 24 * 2) {
        return <>{'1 ' + formatMessage({ id: 'text_day_lower' })}</>;
    }

    // below 7 days
    if (durationSeconds < hour * 24 * 7) {
        return (
            <>
                {Math.floor(durationSeconds / 60 / 60 / 24) +
                    ' ' +
                    formatMessage({ id: 'text_days_lower' })}
            </>
        );
    }

    return (
        <>{twoDigits(date.getDate()) + '.' + twoDigits(date.getMonth() + 1)}</>
    );
}
