import { UseFetchReturn } from '../../hooks/useFetch';
import {
    MainMenu,
    MainMenuContainer,
    MainMenuError,
    MainMenuLoading,
} from '../MainMenu/MainMenu';
import { MenuDTO } from '../MainMenu/types';
import { UseFavoritesReturn } from '../Favorites/useFavorites';
import { useMainMenu } from '../MainMenu/useMainMenu';
import {
    TrialAccountBottomWidget,
    TrialAccountTopWidget,
} from '../TrialAccount/TrialAccountWidget';
import { useTrialAccountProps } from './useTrialAccountProps';

export type SidebarProps = {
    favorites: UseFavoritesReturn;
    menu: UseFetchReturn<MenuDTO>;
    ready: boolean;
};

export function useSidebar(favorites: UseFavoritesReturn): SidebarProps {
    const menu = useMainMenu();
    const ready = favorites.ready && menu.ready;
    return {
        favorites,
        menu,
        ready,
    };
}

export function SidebarContainer({ children }: { children: React.ReactNode }) {
    return <div className="atl-p-8">{children}</div>;
}

export function SidebarMenu({
    menu,
    favorites,
}: {
    menu: UseFetchReturn<MenuDTO>;
    favorites: UseFavoritesReturn;
}) {
    const trialAccount = useTrialAccountProps(menu);

    // Hard-coded for now, but these will be A/B tested in the future
    const showTrialWidgetOnTop = false;
    const showTrialWidgetOnBottom = true;

    return (
        <>
            {menu.error !== undefined && <MainMenuError error={menu.error} />}
            {menu.data === undefined && menu.error === undefined && (
                <MainMenuLoading />
            )}
            {menu.data !== undefined &&
                showTrialWidgetOnTop &&
                menu.data.showTrialWidget && (
                    <TrialAccountTopWidget {...trialAccount} />
                )}
            {menu.data !== undefined && (
                <MainMenuContainer>
                    <MainMenu menu={menu.data} favorites={favorites} />
                </MainMenuContainer>
            )}
            {menu.data !== undefined &&
                showTrialWidgetOnBottom &&
                menu.data.showTrialWidget && (
                    <TrialAccountBottomWidget {...trialAccount} />
                )}
        </>
    );
}
