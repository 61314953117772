import { OrderDTO } from './types';
import { useApiSearch } from '../useSearch';

const endpoint = `/v2/order/query`;
const searchParams = new URLSearchParams({
    fields: 'id,number,customer(name),contact(lastName,firstName),orderDate',
});

export function useOrderSearch(query: string) {
    return useApiSearch<OrderDTO>(endpoint, query, searchParams);
}
