import {
    GoBackButton,
    LoadMoreButton,
    SearchResult,
    SearchResultItem,
    SearchResultItemField,
    SearchResultItemIcon,
    SearchResultItemLink,
    SearchResultItems,
    SearchResultItemTitle,
    SearchResultSkeleton,
    SearchResultTitle,
    SearchResultTitleLink,
} from '../SearchContainer';
import { UseSearchCategoryReturn } from '../types';
import { useResultTitleTranslation } from '../useResultTitleTranslation';
import { getUrlWithContext } from '../util';
import { employeeCategory, EmployeeDTO } from './types';

export function EmployeeResult(props: UseSearchCategoryReturn<EmployeeDTO>) {
    const translation = useResultTitleTranslation('text_employees');

    const { data, hasMore, loading, loadMore, goBack } = props;

    const hasData = data !== undefined && data.length > 0;
    if (!hasData && !loading) {
        return null;
    }

    return (
        <SearchResult category={employeeCategory}>
            <SearchResultTitle>
                <div className="atl-flex atl-items-center">
                    <GoBackButton onGoBack={goBack} />
                    {translation.title}
                </div>
                <SearchResultTitleLink
                    href={getUrlWithContext('/execute/listEmployees', {
                        departmentId: '-1',
                    })}
                >
                    {translation.overviewLinkText}
                </SearchResultTitleLink>
            </SearchResultTitle>
            <SearchResultItems>
                {data?.map((item: EmployeeDTO) => (
                    <EmployeeResultItem key={item.id} data={item} />
                ))}
                {loading && <SearchResultSkeleton />}
                {!loading && hasMore && (
                    <LoadMoreButton
                        data-testid={`search-show-more`}
                        onLoadMore={loadMore}
                        category={employeeCategory}
                    />
                )}
            </SearchResultItems>
        </SearchResult>
    );
}

export function EmployeeResultItem({ data }: { data: EmployeeDTO }) {
    const url = getUrlWithContext('/execute/employeeMenu', {
        employeeId: data.id.toString(),
    });
    return (
        <SearchResultItem>
            <SearchResultItemIcon className="tlx-search__result-employee-icon">
                face
            </SearchResultItemIcon>
            <SearchResultItemLink href={url} category={employeeCategory}>
                <SearchResultItemTitle>
                    {`${data.employeeNumber || ''} ${data.firstName || ''} ${
                        data.lastName || ''
                    }`}
                </SearchResultItemTitle>
                <div>
                    <SearchResultItemField
                        translationKey={'heading_department'}
                    >
                        {data.department?.name}
                    </SearchResultItemField>
                    <SearchResultItemField translationKey={'text_email2'}>
                        {data.email}
                    </SearchResultItemField>
                    <SearchResultItemField
                        translationKey={'text_phone_number_mobile'}
                    >
                        {data.phoneNumberMobile}
                    </SearchResultItemField>
                </div>
            </SearchResultItemLink>
        </SearchResultItem>
    );
}
