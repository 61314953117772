import { useApiSearch } from '../useSearch';
import { EmployeeDTO } from './types';

const endpoint = `/v2/employee/query`;
const searchParams = new URLSearchParams({
    fields: 'id,employeeNumber,firstName,lastName,department(name),email,phoneNumberMobile',
});

export function useEmployeeSearch(query: string) {
    return useApiSearch<EmployeeDTO>(endpoint, query, searchParams);
}
