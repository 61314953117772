/**
 * According to the legacy customer listing, these are the possible values for customer category.
 *
 * @see listCustomers.jsp
 */
export enum CustomerCategoryId {
    TEST = 286,
    GRATIS = 287,
    BETALING = 288,
    AGRO = 24551,
    MAMUT = 26532,
}

export type CustomerCategoryDTO = {
    id: CustomerCategoryId;
};

export type CustomerDTO = {
    id: number;
    name: string;
    customerNumber: number;
    organizationNumber?: string;

    /**
     * Customer category, this is only defined for Tripletex AS.
     */
    category3?: CustomerCategoryDTO | null;
};

export const customerCategory = 'customer';
