import { useApiSearch } from '../useSearch';
import { TravelExpenseDTO } from './types';

const endpoint = `/v2/travelExpense/query`;
const searchParams = new URLSearchParams({
    fields: 'id,travelDetails(destination,departureDate,purpose),employee(firstName,lastName),number,amount',
});

export function useTravelExpenseSearch(query: string) {
    return useApiSearch<TravelExpenseDTO>(endpoint, query, searchParams);
}
