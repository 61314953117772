import {
    ResponseWrapper,
    createAPIRequest,
    createAPIResponse,
} from '@tlx/astro-shared';
import useSWRImmutable from 'swr/immutable';

type AuthConfig = {
    loginUrl: string;
    logoutUrl: string;
    refreshSessionIframeUrl: string;
    checkSessionIframeUrl: string;
};

export function useAuthConfig(): AuthConfig | undefined {
    const { data } = useSWRImmutable<ResponseWrapper<AuthConfig>>(
        '/v2/internal/auth/config',
        fetcher,
    );

    return data?.value;
}

async function fetcher<T>(url: string) {
    const request = createAPIRequest(url);
    const response = await window.fetch(request);

    return createAPIResponse<T>(request, response);
}
