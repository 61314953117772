import { getContextId } from '@tlx/astro-shared';
import { Tour, TourRunOptions } from '@tlx/tourme-core';
import { MenuDTO, MenuItemDTO } from '../components/MainMenu/types';
import GoldSharkRackbeatTour from '../tour-me/gold-shark.json';
import { addContextId } from '../utils/addContextId';

export function runTour(tour: Tour, nextRunOptions?: TourRunOptions) {
    if (window.TourMe?.status()?.status === 'idle') {
        window.TourMe?.run(tour, nextRunOptions);
    }
}

// When using TourMe -  For internal navigation we need to add the contextId to the url
export function redirector(
    url: string,
    target?: string,
    windowFeatures?: string,
) {
    let external = false;
    try {
        const urlParsed = new URL(url);
        // Can only be external if it actually goes to a different origin
        external = urlParsed.origin !== window.location.origin;
    } catch (error) {
        console.warn(
            `Could not parse URL "${url}" for TourMe to redirect: ${error}`,
        );
    }

    if (!external) {
        // Ensure contextId when navigating inside application
        url = addContextId(url, getContextId() ?? '');
    }

    if (target) {
        window.open(url, target, windowFeatures ?? 'noopener, noreferrer');
    } else {
        window.location.href = url;
    }
}

// By default our tourme-core library uses document.querySelector.
// We need to override this to also look in the shadowRoot of our custom elements.
export function getElement<E extends Element = Element>(
    selector: string | undefined,
): E | null {
    if (!selector) {
        return null;
    }

    const spacesuitRoot =
        document.querySelector('spacesuit-layout')?.shadowRoot;
    const helpRoot = spacesuitRoot?.querySelector('tripletex-help')?.shadowRoot;
    const roots = [document, spacesuitRoot, helpRoot];

    for (const root of roots) {
        const element = root?.querySelector<E>(selector);

        if (element) {
            return element;
        }
    }

    return null;
}

// See TRIP-37017 and TRIP-34378. This is special-cased for GoldShark
// Remove this code when the tour is no longer needed
export function runGoldSharkRackBeatTourMe(
    menu: MenuDTO,
    currentExpanded: string,
) {
    if (currentExpanded === 'menu-product') {
        const productMenuItem: MenuItemDTO | undefined = menu.menuItems.find(
            (menuItem) => menuItem.testId === 'menu-product',
        );

        if (
            productMenuItem?.subMenuItems.find(
                (subMenu) => subMenu.testId === 'menu-products-rackbeat-news',
            )
        ) {
            runTour(GoldSharkRackbeatTour as Tour, { testMode: false });
        }
    }
}
