import { useEffect, useState } from 'react';
import { useFetch } from '../../hooks/useFetch';
import { ProfileDTO } from './types';
import { isNarrowScreen } from '../../utils/url';
import { useCurrentRelativeUrl } from '../../hooks/useCurrentRelativeUrl';

export type UseProfileReturn = {
    profile: ProfileDTO | null;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useProfile(): UseProfileReturn {
    const [open, setOpen] = useState(false);
    const [fetchUrl, setFetchUrl] = useState(
        `/v2/internal/profile/simple?narrowScreen=${isNarrowScreen().toString()}`,
    );
    const { data } = useFetch<ProfileDTO>(fetchUrl);

    const currentRelativeUrl = useCurrentRelativeUrl();

    useEffect(() => {
        setFetchUrl(
            `/v2/internal/profile/simple?narrowScreen=${isNarrowScreen().toString()}`,
        );
    }, [currentRelativeUrl]);

    const profile = data ?? null;

    return {
        profile,
        open,
        setOpen,
    };
}
