import { createAPIRequest, createAPIResponse } from '@tlx/astro-shared';

export const signCompanyLicense = async () => {
    const request = createAPIRequest('/v2/license/sign/tlxLicense', {
        method: 'PUT',
        headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
    });

    const response = await fetch(request);

    return createAPIResponse(request, response);
};

export const signUserLicense = async () => {
    const request = createAPIRequest('/v2/license/sign/userTerms', {
        method: 'PUT',
        headers: {
            'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
    });
    const response = await fetch(request);

    return createAPIResponse(request, response);
};

export const signMarketingConsent = async (value: boolean) => {
    const request = createAPIRequest(
        `/v2/license/sign/marketingConsent?consent=${value}`,
        {
            method: 'PUT',
            headers: {
                'content-type':
                    'application/x-www-form-urlencoded;charset=utf-8',
            },
        },
    );
    const response = await fetch(request);

    return createAPIResponse(request, response);
};
