import React, { ReactNode, useContext, useState } from 'react';

const SearchLoadMoreContext = React.createContext<{
    loadMoreCategory: string | undefined;
    setLoadMoreCategory: (category: string | undefined) => void;
}>({
    loadMoreCategory: undefined,
    setLoadMoreCategory: () => {
        /* Do nothing */
    },
});
SearchLoadMoreContext.displayName = 'SearchLoadMoreContext';

export function SearchLoadMoreContextProvider({
    children,
}: {
    children: ReactNode;
}) {
    const [loadMoreCategory, setLoadMoreCategory] = useState<
        string | undefined
    >(undefined);

    return (
        <SearchLoadMoreContext.Provider
            value={{ loadMoreCategory, setLoadMoreCategory }}
        >
            {children}
        </SearchLoadMoreContext.Provider>
    );
}

export function useSearchLoadMoreContext() {
    const { loadMoreCategory, setLoadMoreCategory } = useContext(
        SearchLoadMoreContext,
    );

    return {
        isLoadMorePage: loadMoreCategory !== undefined,
        loadMoreCategory,
        setLoadMoreCategory,
    };
}

export function useSearchLoadMoreCheck(category?: string) {
    const { loadMoreCategory } = useContext(SearchLoadMoreContext);
    return {
        shouldNotRenderItself:
            loadMoreCategory !== undefined && loadMoreCategory !== category,
    };
}

export function useSearchSkeleton() {
    const { loadMoreCategory } = useContext(SearchLoadMoreContext);

    return {
        skeletonSize: loadMoreCategory !== undefined ? 1 : 3,
    };
}
