import { useIntl } from 'react-intl';
import {
    GoBackButton,
    LoadMoreButton,
    SearchResult,
    SearchResultItem,
    SearchResultItemField,
    SearchResultItemIcon,
    SearchResultItemLink,
    SearchResultItems,
    SearchResultItemTitle,
    SearchResultSkeleton,
    SearchResultTitle,
    SearchResultTitleLink,
} from '../SearchContainer';
import { UseSearchCategoryReturn } from '../types';
import { useResultTitleTranslation } from '../useResultTitleTranslation';
import { getUrlWithContext } from '../util';
import { purchaseOrderCategory, PurchaseOrderDTO } from './types';

export function PurchaseOrderResult(
    props: UseSearchCategoryReturn<PurchaseOrderDTO>,
) {
    const translation = useResultTitleTranslation('text_purchase_order');

    const { data, hasMore, loading, loadMore, goBack } = props;

    const hasData = data !== undefined && data.length > 0;
    if (!hasData && !loading) {
        return null;
    }

    return (
        <SearchResult category={purchaseOrderCategory}>
            <SearchResultTitle>
                <div className="atl-flex atl-items-center">
                    <GoBackButton onGoBack={goBack} />
                    {translation.title}
                </div>
                <SearchResultTitleLink
                    href={getUrlWithContext('/execute/purchaseOrderNew')}
                >
                    {translation.overviewLinkText}
                </SearchResultTitleLink>
            </SearchResultTitle>
            <SearchResultItems>
                {data?.map((item: PurchaseOrderDTO) => (
                    <PurchaseOrderResultItem key={item.id} data={item} />
                ))}
                {loading && <SearchResultSkeleton />}
                {!loading && hasMore && (
                    <LoadMoreButton
                        category={purchaseOrderCategory}
                        data-testid={`search-show-more`}
                        onLoadMore={loadMore}
                    />
                )}
            </SearchResultItems>
        </SearchResult>
    );
}

export function PurchaseOrderResultItem({ data }: { data: PurchaseOrderDTO }) {
    const url = getUrlWithContext('/execute/purchaseOrderMenu', {
        orderOutId: data.id.toString(),
    });
    const { formatMessage } = useIntl();
    return (
        <SearchResultItem>
            <SearchResultItemIcon className="tlx-search__result-purchaseorder-icon">
                assignment
            </SearchResultItemIcon>
            <SearchResultItemLink href={url} category={purchaseOrderCategory}>
                <SearchResultItemTitle>
                    {`${formatMessage({
                        id: 'text_number_short',
                    })} ${data.number}`}
                </SearchResultItemTitle>
                <SearchResultItemField translationKey={'text_supplier'}>
                    {data.supplier?.name}
                </SearchResultItemField>
                <SearchResultItemField translationKey={'text_project'}>
                    {data.project?.displayName}
                </SearchResultItemField>
            </SearchResultItemLink>
        </SearchResultItem>
    );
}
