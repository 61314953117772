import type { BrowserPlugin } from '@snowplow/browser-tracker-core';
import { buildStructEvent, PayloadBuilder } from '@snowplow/tracker-core';
import { ACCOUNT_HELP_SEARCH, AccountHelpEvent } from '../../events';

/* This plugin tracks search keys sent in the account help search field in Tripletex. 
Users can search for account suggestions using descriptive keywords such as 'electricity' to 
find a suitable account for a posting. 
If there are no matches found for the search key, we track it as a structured Snowplow event. */

export function AccountHelpPlugin(): BrowserPlugin {
    return {
        activateBrowserPlugin: (tracker) => {
            const handleEvent: EventListener = (event) => {
                const payloadBuilder =
                    getPayloadBuilderFromAccountHelpEvent(event);

                if (payloadBuilder) {
                    tracker.core.track(payloadBuilder);
                }
            };

            window.addEventListener(ACCOUNT_HELP_SEARCH, handleEvent);
        },
    };
}

export function getPayloadBuilderFromAccountHelpEvent(
    event: Event,
): PayloadBuilder | void {
    if (!isAccountHelpEvent(event)) {
        return;
    }

    return buildStructEvent({
        category: 'account-help',
        action: 'search',
        property: event.detail.query,
    });
}

function isAccountHelpEvent(event: Event): event is AccountHelpEvent {
    return event.type === ACCOUNT_HELP_SEARCH;
}
