import { HTMLAttributes } from 'react';
import { LayoutElement } from './components/Layout/LayoutElement';

declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace JSX {
        interface IntrinsicElements {
            'spacesuit-layout': HTMLAttributes<LayoutElement>;
        }
    }

    interface Window {
        SPACESUIT_VERSION?: string;
    }
}

// @ts-expect-error Injected by Vite (vite.config.js)
window.SPACESUIT_VERSION = __SPACESUIT__VERSION__;
window.customElements.define('spacesuit-layout', LayoutElement);
