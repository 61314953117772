import {
    Button,
    Group,
    Input,
    Label,
    Modal,
    ModalCloseButton,
    ModalTitle,
} from '@tlx/atlas';
import { FavoriteData } from './useFavorites';
import { FavoriteDTO } from '../MainMenu/types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getCurrentRelativeUrl } from '../../utils/url';

type AddFavoriteModalProps = {
    open: boolean;
    openerRef?: React.RefObject<HTMLButtonElement>;
    closeFavoriteModal(): void;
    createFavorite(data: FavoriteData): Promise<void>;
};

// Heuristic: When we open the favorite modal, try to detect the name of the
// current page so that we can pre-fill the name in the input field.
function useDetectPageTitle(modalIsOpen: boolean): string {
    const [detectedName, setDetectedName] = useState('');
    useEffect(() => {
        if (modalIsOpen) {
            setDetectedName(
                document.querySelector<HTMLElement>('[data-tlx-title]')?.dataset
                    ?.tlxTitle ?? '',
            );
        }
    }, [modalIsOpen]);
    return detectedName;
}

export function AddFavoriteModal(props: AddFavoriteModalProps) {
    const detectedName = useDetectPageTitle(props.open);

    async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (e.target instanceof HTMLFormElement) {
            const name =
                new FormData(e.target).get('favoriteName')?.toString() ?? '';
            e.target.reset();

            const pageUrl = getCurrentRelativeUrl(
                window.location.href,
                window.origin,
            );
            await props.createFavorite({
                rank: 0,
                name,
                pageUrl,
            });
            props.closeFavoriteModal();
        }
    }

    if (!props.open) {
        return null;
    }

    return (
        <Modal
            open={props.open}
            openerRef={props.openerRef}
            onClose={props.closeFavoriteModal}
        >
            <ModalCloseButton />
            <ModalTitle>
                <FormattedMessage id="text_add_to_favourites" />
            </ModalTitle>
            {props.open && (
                <form autoComplete="off" onSubmit={onSubmit}>
                    <Group>
                        <Label htmlFor="favoriteName">
                            <FormattedMessage id="text_name" />
                        </Label>
                        <Input
                            autoFocus
                            defaultValue={detectedName}
                            data-testid="favorite-name-input"
                            type="text"
                            name="favoriteName"
                            className="atl-w-full"
                            required
                        />
                    </Group>
                    <p className="atl-text-sm">
                        <FormattedMessage id="text_add_to_favourites_information" />
                    </p>
                    <div className="atl-flex atl-gap-8">
                        <Button
                            type="submit"
                            variant="primary"
                            data-testid="saveFavorite"
                        >
                            <FormattedMessage id="button_save" />
                        </Button>
                        <Button
                            variant="secondary"
                            data-testid="cancelFavoriteModal"
                            onClick={props.closeFavoriteModal}
                        >
                            <FormattedMessage id="button_cancel" />
                        </Button>
                    </div>
                </form>
            )}
        </Modal>
    );
}

type ModifyFavoriteModalProps = {
    open: boolean;
    favorite: FavoriteDTO;
    openerRef?: React.RefObject<HTMLButtonElement>;
    closeFavoriteModal(): void;
    updateFavorite(id: number, data: FavoriteData): Promise<void>;
    deleteFavorite(id: number): Promise<void>;
};
export function ModifyFavoriteModal(props: ModifyFavoriteModalProps) {
    async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        if (e.target instanceof HTMLFormElement) {
            const name =
                new FormData(e.target).get('favoriteName')?.toString() ?? '';
            e.target.reset();

            await props.updateFavorite(props.favorite.id, {
                rank: 0,
                name,
                pageUrl: props.favorite.pageUrl,
            });
            props.closeFavoriteModal();
        }
    }

    async function onClickDelete() {
        await props.deleteFavorite(props.favorite.id);
        props.closeFavoriteModal();
    }

    if (!props.open) {
        return null;
    }

    return (
        <Modal
            openerRef={props.openerRef}
            open={props.open}
            onClose={props.closeFavoriteModal}
        >
            <ModalCloseButton />
            <ModalTitle>
                <FormattedMessage id="text_change_favourite" />
            </ModalTitle>
            {props.open && (
                <form autoComplete="off" onSubmit={onSubmit}>
                    <Group>
                        <Label htmlFor="favoriteName">
                            <FormattedMessage id="text_name" />
                        </Label>
                        <Input
                            autoFocus
                            data-testid="favorite-name-input"
                            type="text"
                            name="favoriteName"
                            defaultValue={props.favorite.name}
                            className="atl-w-full"
                            required
                        />
                    </Group>
                    <p className="atl-text-sm">
                        <FormattedMessage id="text_change_favourite_information" />
                    </p>
                    <div className="atl-flex atl-gap-8">
                        <Button
                            type="submit"
                            variant="primary"
                            data-testid="saveFavorite"
                        >
                            <FormattedMessage id="button_save" />
                        </Button>
                        <Button
                            data-testid="cancelFavoriteModal"
                            variant="secondary"
                            onClick={props.closeFavoriteModal}
                        >
                            <FormattedMessage id="button_cancel" />
                        </Button>
                        <Button
                            className="atl-ml-auto"
                            data-testid="deleteFavorite"
                            variant="tertiary"
                            onClick={onClickDelete}
                        >
                            <FormattedMessage id="button_delete" />
                        </Button>
                    </div>
                </form>
            )}
        </Modal>
    );
}
