import { UseSearchCategoryReturn } from '../types';

import {
    GoBackButton,
    LoadMoreButton,
    SearchResult,
    SearchResultItem,
    SearchResultItemField,
    SearchResultItemIcon,
    SearchResultItemLink,
    SearchResultItems,
    SearchResultItemTitle,
    SearchResultSkeleton,
    SearchResultTitle,
    SearchResultTitleLink,
} from '../SearchContainer';
import { useResultTitleTranslation } from '../useResultTitleTranslation';
import { getUrlWithContext } from '../util';
import { supplierCategory, SupplierDTO } from './types';

export function SupplierResult(props: UseSearchCategoryReturn<SupplierDTO>) {
    const translation = useResultTitleTranslation('text_vendors');

    const { data, hasMore, loading, loadMore, goBack } = props;

    const hasData = data !== undefined && data.length > 0;
    if (!hasData && !loading) {
        return null;
    }

    return (
        <SearchResult category={supplierCategory}>
            <SearchResultTitle>
                <div className="atl-flex atl-items-center">
                    <GoBackButton onGoBack={goBack} />
                    {translation.title}
                </div>
                <SearchResultTitleLink
                    href={getUrlWithContext(`/execute/listCustomers`, {
                        customerType: '2',
                    })}
                >
                    {translation.overviewLinkText}
                </SearchResultTitleLink>
            </SearchResultTitle>
            <SearchResultItems>
                {data?.map((item: SupplierDTO) => (
                    <SupplierResultItem key={item.id} data={item} />
                ))}
                {loading && <SearchResultSkeleton />}
                {!loading && hasMore && (
                    <LoadMoreButton
                        data-testid={`search-show-more`}
                        onLoadMore={loadMore}
                        category={supplierCategory}
                    />
                )}
            </SearchResultItems>
        </SearchResult>
    );
}

export function SupplierResultItem({ data }: { data: SupplierDTO }) {
    const url = getUrlWithContext('/execute/customerMenu', {
        customerId: data.id.toString(),
    });
    return (
        <SearchResultItem>
            <SearchResultItemIcon className="tlx-search__result-supplier-icon">
                people
            </SearchResultItemIcon>
            <SearchResultItemLink href={url} category={supplierCategory}>
                <SearchResultItemTitle>{data.name}</SearchResultItemTitle>
                <SearchResultItemField
                    translationKey={'text_organisation_number_short'}
                >
                    {data.organizationNumber}
                </SearchResultItemField>
                <SearchResultItemField
                    translationKey={'text_vendor_number_short'}
                >
                    {data.supplierNumber}
                </SearchResultItemField>
            </SearchResultItemLink>
        </SearchResultItem>
    );
}
