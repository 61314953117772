import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EXPAND_MENU_ITEM } from '../../events';
import { runGoldSharkRackBeatTourMe } from '../../hooks/useTourMe';
import { UseFavoritesReturn } from '../Favorites/useFavorites';
import { Link } from '../Link/Link';
import { ExpandCollapse } from './ExpandCollapse';
import { getCleanUrl, isMenuItemActive } from './isMenuItemActive';
import { FavoriteMenuDTO, MenuDTO, MenuItemDTO } from './types';

export function MainMenuContainer({ children }: { children: React.ReactNode }) {
    return (
        <nav className="tlx-mainmenu tlx-mainmenu--open">
            <ul className="tlx-mainmenu-list" role="menu">
                {children}
            </ul>
        </nav>
    );
}

export function MainMenu({
    menu,
    favorites,
}: {
    menu: MenuDTO;
    favorites: UseFavoritesReturn;
}) {
    const currentPath = getCleanUrl(window.location.href);
    const activeSubmenu = menu.menuItems.find((item) =>
        isMenuItemActive(item, currentPath),
    );

    // Updated on mouse click (before navigates)
    const [activeLink, setActiveLink] = useState(currentPath);

    const [currentExpanded, setCurrentExpanded] = useState(
        activeSubmenu !== undefined ? activeSubmenu.testId : '',
    );

    runGoldSharkRackBeatTourMe(menu, currentExpanded);
    useNotifyMenuItemExpanded(currentExpanded);

    return (
        <>
            {favorites.favorites.error !== undefined && (
                <FavoritesError error={favorites.favorites.error} />
            )}
            {favorites.favorites.data === undefined &&
                favorites.favorites.error === undefined && <FavoritesLoading />}
            {favorites.favorites.data !== undefined && (
                <Favorites
                    favorites={favorites.favorites.data}
                    deleteFavorite={favorites.deleteFavorite}
                    currentExpanded={currentExpanded}
                    setCurrentExpanded={setCurrentExpanded}
                />
            )}
            {menu.menuItems.map((menuItem: MenuItemDTO, index: number) => {
                return (
                    <MenuItem
                        key={index}
                        menuItem={menuItem}
                        currentExpanded={currentExpanded}
                        currentPath={currentPath}
                        activeLink={activeLink}
                        setCurrentExpanded={setCurrentExpanded}
                        setActiveLink={setActiveLink}
                    />
                );
            })}
        </>
    );
}

export function MainMenuLoading() {
    return (
        <div className="tlx-mainmenu--show-delayed">
            {[100, 70, 140, 110, 110, 130, 80, 90].map((width, index) => (
                <div
                    key={index}
                    className="tlx-mainmenu__menu-item-header tlx-mainmenu__menu-item-header--loading"
                >
                    <div className="tlx-mainmenu__menu-item-header__icon tlx-mainmenu__menu-item-header__icon--skeleton"></div>
                    <span className="tlx-mainmenu__menu-item-header__text">
                        <div
                            className="tlx-mainmenu__menu-item-header__text__skeleton atl-rounded"
                            style={{
                                width,
                            }}
                        />
                    </span>
                </div>
            ))}
        </div>
    );
}

export function MainMenuError({ error }: { error: Error }) {
    return <div>Main menu error: {error.message}</div>;
}

function FavoritesError({ error }: { error: Error }) {
    return <div>Error loading favorites: {error.message}</div>;
}

function FavoritesLoading() {
    return <FormattedMessage id="text_loading" />;
}

export function Favorites({
    favorites,
    deleteFavorite,
    currentExpanded,
    setCurrentExpanded,
}: {
    favorites: FavoriteMenuDTO;
    deleteFavorite(id: number): Promise<void>;
    currentExpanded: string;
    setCurrentExpanded: (id: string) => void;
}) {
    const { formatMessage } = useIntl();
    const [activeId, setActiveId] = useState<number | undefined>(undefined);

    const expanded = currentExpanded === 'menu-favorites';

    if (!favorites.shouldShow) {
        return null;
    }

    return (
        <li className="tlx-mainmenu__list-item">
            <button
                type="button"
                className="tlx-mainmenu__menu-item-header tlx-mainmenu__border-on-focus-visible"
                data-testid="menu-favorites"
                onClick={() =>
                    setCurrentExpanded(expanded ? '' : 'menu-favorites')
                }
            >
                <i className="tlx-mainmenu__menu-item-header__icon tlx-mainmenu__menu-item-header__icon--favorites">
                    star
                </i>
                <span className="tlx-mainmenu__menu-item-header__text">
                    <FormattedMessage id="text_favourites" />
                </span>
            </button>
            <ExpandCollapse expanded={expanded}>
                {favorites.favoriteList.length === 0 && (
                    <div className="tlx-mainmenu__no-favorites">
                        <FormattedMessage id="text_no_favourites_chosen" />
                    </div>
                )}
                {favorites.favoriteList.length > 0 && (
                    <ul
                        className={classNames(
                            'tlx-mainmenu__submenu__list',
                            'tlx-mainmenu__submenu__list--favorites',
                        )}
                    >
                        {favorites.favoriteList.map((favorite, idx) => {
                            return (
                                <li
                                    key={idx}
                                    className="tlx-mainmenu__submenu__list-item"
                                >
                                    <Link
                                        id={'tt-favourite' + favorite.id}
                                        className={classNames(
                                            'tlx-mainmenu__submenu__list-item__link',
                                            activeId === favorite.id
                                                ? 'tlx-mainmenu__submenu__list-item__link--active'
                                                : '',
                                        )}
                                        data-rank={favorite.rank}
                                        data-id={favorite.id}
                                        data-testid={
                                            'menu-favourite-' + favorite.id
                                        }
                                        href={favorite.pageUrl}
                                        onClick={() => setActiveId(favorite.id)}
                                    >
                                        <button
                                            className="tlx-mainmenu__submenu__menu-item__delete-button"
                                            aria-label={formatMessage({
                                                id: 'button_delete',
                                            })}
                                            onClick={async (event) => {
                                                event.stopPropagation();
                                                event.preventDefault();
                                                await deleteFavorite(
                                                    favorite.id,
                                                );
                                            }}
                                        >
                                            <i
                                                aria-hidden="true"
                                                className="tlx-mainmenu__submenu__menu-item__delete-button__icon"
                                            >
                                                delete_outline
                                            </i>
                                        </button>
                                        <i
                                            aria-hidden="true"
                                            className="tlx-mainmenu__submenu__menu-item__dot"
                                        >
                                            &bull;
                                        </i>
                                        {favorite.name}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </ExpandCollapse>
        </li>
    );
}

export function MenuItem({
    menuItem,
    currentExpanded,
    currentPath,
    activeLink,
    setCurrentExpanded,
    setActiveLink,
}: {
    menuItem: MenuItemDTO;
    currentExpanded: string;
    currentPath: string;
    activeLink: string;
    setCurrentExpanded: (id: string) => void;
    setActiveLink: (url: string) => void;
}) {
    const expanded = currentExpanded === menuItem.testId;

    return (
        <li className={`tlx-mainmenu__list-item`}>
            {menuItem.url !== '' ? (
                <Link
                    href={menuItem.url}
                    data-testid={menuItem.testId}
                    className="tlx-mainmenu__menu-item-header tlx-mainmenu__border-on-focus-visible"
                    onClick={() => setActiveLink(menuItem.url)}
                    aria-current={
                        menuItem.url === activeLink ? 'page' : undefined
                    }
                    data-restore-filter={
                        // Home page does not need to remember urlParams per session
                        menuItem.testId !== 'menu-home' ? 'true' : undefined
                    }
                    aria-label={menuItem.title}
                >
                    <i
                        aria-hidden="true"
                        className={`tlx-mainmenu__menu-item-header__icon tlx-mainmenu__menu-item-header__icon--${menuItem.theme}`}
                    >
                        {menuItem.icon}
                    </i>
                    <span className="tlx-mainmenu__menu-item-header__text">
                        {menuItem.title}
                    </span>
                </Link>
            ) : (
                <>
                    <button
                        type="button"
                        data-testid={menuItem.testId}
                        className={classNames(
                            'tlx-mainmenu__menu-item-header',
                            'tlx-mainmenu__border-on-focus-visible',
                        )}
                        onClick={() => {
                            if (expanded) {
                                setCurrentExpanded('');
                            } else {
                                setCurrentExpanded(menuItem.testId);

                                // If a menu item contains a single sub menu item, use its URL and save a click
                                if (menuItem.subMenuItems.length === 1) {
                                    const firstItem = menuItem.subMenuItems[0];

                                    // Only navigate if the orphan sub menu item has a URL and no sub menu items
                                    if (
                                        firstItem.url === '' &&
                                        firstItem.subMenuItems.length > 0
                                    ) {
                                        return;
                                    }

                                    setActiveLink(firstItem.url);

                                    const navigateEvent = new CustomEvent(
                                        'tlx:navigate',
                                        {
                                            detail: { href: firstItem.url },
                                            cancelable: true,
                                        },
                                    );

                                    window.dispatchEvent(navigateEvent);

                                    if (!navigateEvent.defaultPrevented) {
                                        window.location.href = firstItem.url;
                                    }
                                }
                            }
                        }}
                        aria-expanded={expanded}
                        aria-label={menuItem.title}
                    >
                        <i
                            aria-hidden="true"
                            className={`tlx-mainmenu__menu-item-header__icon tlx-mainmenu__menu-item-header__icon--${menuItem.theme}`}
                        >
                            {menuItem.icon}
                        </i>
                        <span className="tlx-mainmenu__menu-item-header__text">
                            {menuItem.title}
                        </span>
                    </button>
                    <ExpandCollapse expanded={expanded}>
                        <ul
                            className={classNames(
                                'tlx-mainmenu__submenu__list',
                            )}
                        >
                            {menuItem.subMenuItems.map(
                                (subMenuItem: MenuItemDTO, index: number) => {
                                    if (subMenuItem.subMenuItems.length === 0) {
                                        return (
                                            <li
                                                key={index}
                                                className="tlx-mainmenu__submenu__list-item"
                                            >
                                                <Link
                                                    className={classNames(
                                                        'tlx-mainmenu__border-on-focus-visible',
                                                        'tlx-mainmenu__submenu__list-item__link',
                                                        activeLink ===
                                                            subMenuItem.url
                                                            ? 'tlx-mainmenu__submenu__list-item__link--active'
                                                            : '',
                                                    )}
                                                    onClick={() =>
                                                        setActiveLink(
                                                            subMenuItem.url,
                                                        )
                                                    }
                                                    href={subMenuItem.url}
                                                    data-testid={
                                                        subMenuItem.testId
                                                    }
                                                    data-restore-filter
                                                    aria-current={
                                                        subMenuItem.url ===
                                                        activeLink
                                                            ? 'page'
                                                            : undefined
                                                    }
                                                    aria-label={
                                                        subMenuItem.title
                                                    }
                                                >
                                                    <i
                                                        aria-hidden="true"
                                                        className="tlx-mainmenu__submenu__menu-item__dot"
                                                    >
                                                        &bull;
                                                    </i>
                                                    {subMenuItem.title}
                                                </Link>
                                            </li>
                                        );
                                    } else {
                                        return (
                                            <SubMenuItem
                                                key={index}
                                                subMenuItem={subMenuItem}
                                                currentPath={currentPath}
                                                activeLink={activeLink}
                                                setActiveLink={setActiveLink}
                                            />
                                        );
                                    }
                                },
                            )}
                        </ul>
                    </ExpandCollapse>
                </>
            )}
        </li>
    );
}

function SubMenuItem({
    subMenuItem,
    currentPath,
    activeLink,
    setActiveLink,
}: {
    subMenuItem: MenuItemDTO;
    currentPath: string;
    activeLink: string;
    setActiveLink: (url: string) => void;
}) {
    const [subExpanded, setSubExpanded] = useState(
        isMenuItemActive(subMenuItem, currentPath),
    );

    return (
        <li className="tlx-mainmenu__submenu__list-item">
            <button
                type="button"
                className="tlx-mainmenu__submenu__menu-item-header tlx-mainmenu__border-on-focus-visible"
                data-testid={subMenuItem.testId}
                onClick={() => setSubExpanded(!subExpanded)}
                aria-current={
                    subMenuItem.url === activeLink ? 'page' : undefined
                }
                aria-expanded={subExpanded}
                aria-label={subMenuItem.title}
            >
                <i
                    className={classNames(
                        'tlx-mainmenu__submenu__menu-item__expand-arrow',
                        subExpanded
                            ? 'tlx-mainmenu__submenu__menu-item__expand-arrow--expanded'
                            : '',
                    )}
                >
                    arrow_right
                </i>
                <span className="tlx-mainmenu__submenu__menu-item-header__text">
                    {subMenuItem.title}
                </span>
            </button>
            <ExpandCollapse expanded={subExpanded}>
                <ul
                    className={classNames(
                        'tlx-mainmenu__submenu__submenu__list',
                    )}
                >
                    {subMenuItem.subMenuItems.map((subSubMenuItem, index) => (
                        <li
                            key={index}
                            className="tlx-mainmenu__submenu__submenu__list-item"
                        >
                            <Link
                                className={classNames(
                                    'tlx-mainmenu__border-on-focus-visible',
                                    'tlx-mainmenu__submenu__submenu__list-item__link',
                                    activeLink === subSubMenuItem.url
                                        ? 'tlx-mainmenu__submenu__submenu__list-item__link--active'
                                        : '',
                                )}
                                href={subSubMenuItem.url}
                                data-testid={subSubMenuItem.testId}
                                data-restore-filter
                                onClick={() =>
                                    setActiveLink(subSubMenuItem.url)
                                }
                                aria-current={
                                    subSubMenuItem.url === activeLink
                                        ? 'page'
                                        : undefined
                                }
                                aria-label={subSubMenuItem.title}
                            >
                                {subSubMenuItem.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </ExpandCollapse>
        </li>
    );
}

// Notify when menu item is expanded
function useNotifyMenuItemExpanded(id: string) {
    useEffect(() => {
        if (id === '') {
            return;
        }

        window.dispatchEvent(
            new CustomEvent(EXPAND_MENU_ITEM, {
                detail: { id },
            }),
        );
    }, [id]);
}
