import { useEffect, useState } from 'react';
import { getCurrentRelativeUrl } from '../utils/url';

export function useCurrentRelativeUrl(): string {
    const [currentRelativeUrl, setCurrentRelativeUrl] = useState(
        getCurrentRelativeUrl(window.location.href, window.origin),
    );

    // Improvement: Use the new appHistory API (when it's ready) and listen to history events
    // so we get immediate results, instead of polling. https://github.com/WICG/app-history
    useEffect(() => {
        const poll = setInterval(() => {
            setCurrentRelativeUrl(
                getCurrentRelativeUrl(window.location.href, window.origin),
            );
        }, 400);
        return () => clearInterval(poll);
    }, []);

    return currentRelativeUrl;
}
