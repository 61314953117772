import { useIntl } from 'react-intl';
import {
    GoBackButton,
    LoadMoreButton,
    SearchResult,
    SearchResultItem,
    SearchResultItemField,
    SearchResultItemIcon,
    SearchResultItemLink,
    SearchResultItems,
    SearchResultItemTitle,
    SearchResultSkeleton,
    SearchResultTitle,
    SearchResultTitleLink,
} from '../SearchContainer';
import { UseSearchCategoryReturn } from '../types';
import { useResultTitleTranslation } from '../useResultTitleTranslation';
import { getUrlWithContext } from '../util';
import { invoiceCategory, InvoiceDTO } from './types';

export function InvoiceResult(props: UseSearchCategoryReturn<InvoiceDTO>) {
    const translation = useResultTitleTranslation('text_invoices');

    const { data, hasMore, loading, loadMore, goBack } = props;

    const hasData = data !== undefined && data.length > 0;
    if (!hasData && !loading) {
        return null;
    }

    return (
        <SearchResult category={invoiceCategory}>
            <SearchResultTitle>
                <div className="atl-flex atl-items-center">
                    <GoBackButton onGoBack={goBack} />
                    {translation.title}
                </div>
                <SearchResultTitleLink
                    href={getUrlWithContext('/execute/listInvoices')}
                >
                    {translation.overviewLinkText}
                </SearchResultTitleLink>
            </SearchResultTitle>
            <SearchResultItems>
                {data?.map((item: InvoiceDTO) => (
                    <InvoiceResultItem key={item.id} data={item} />
                ))}
                {loading && <SearchResultSkeleton />}
                {!loading && hasMore && (
                    <LoadMoreButton
                        data-testid={`search-show-more`}
                        category={invoiceCategory}
                        onLoadMore={loadMore}
                    />
                )}
            </SearchResultItems>
        </SearchResult>
    );
}

export function InvoiceResultItem({ data }: { data: InvoiceDTO }) {
    const url = getUrlWithContext('/execute/invoiceMenu', {
        invoiceId: data.id.toString(),
    });
    const { formatMessage } = useIntl();
    return (
        <SearchResultItem>
            <SearchResultItemIcon className="tlx-search__result-invoice-icon">
                content_copy
            </SearchResultItemIcon>
            <SearchResultItemLink href={url} category={invoiceCategory}>
                <SearchResultItemTitle>
                    {`${formatMessage({
                        id: 'text_invoice_number_short',
                    })}: ${data.invoiceNumber}`}
                </SearchResultItemTitle>
                <SearchResultItemField translationKey={'text_customer'}>
                    {data.customer?.name}
                </SearchResultItemField>
                <SearchResultItemField translationKey={'text_amount'}>
                    {data.amount}
                </SearchResultItemField>
            </SearchResultItemLink>
        </SearchResultItem>
    );
}
