import { CompanyDTO } from './types';

export function getFilteredCompanies(
    companies: CompanyDTO[],
    query: string,
): CompanyDTO[] {
    return companies.filter((company) =>
        company.displayName
            .toLocaleLowerCase()
            .includes(query.trim().toLocaleLowerCase()),
    );
}
