import type { BrowserPlugin } from '@snowplow/browser-tracker-core';
import {
    buildSelfDescribingEvent,
    PayloadBuilder,
    SelfDescribingJson,
} from '@snowplow/tracker-core';
import { navigation } from '../navigation-api-ponyfill';

const MINIMUM_DURATION_SECONDS = 3;

/**
 * Describes which menu the user is interacting with. Because multiple menu items share the url path, this context makes it easier to identify where in the application the user is.
 */
interface SessionEvent extends SelfDescribingJson {
    schema: 'iglu:no.tripletex/event_session/jsonschema/1-0-0';
    data: {
        /**
         * A timestamp of when the session started
         */
        sessionStartTstamp: string;

        /**
         * A timestamp of when the session ended
         */
        sessionEndTstamp: string;

        /**
         * The time spent on the page rounded to the nearest number of seconds. Has a minimum (inclusive) duration of 3 seconds
         */
        sessionDuration: number;
    };
}

export function SessionPlugin(): BrowserPlugin {
    let startAt: Date | null = null;

    return {
        activateBrowserPlugin: (tracker) => {
            const startSession = () => {
                if (startAt) {
                    endSession();
                }

                startAt = new Date();
            };

            const endSession = () => {
                if (!startAt) {
                    console.warn(
                        `SessionPlugin: Missing startAt when ending session`,
                    );
                    return;
                }

                const payloadBuilder = getPayloadBuilderFromDateRange(
                    startAt,
                    new Date(),
                );

                if (payloadBuilder) {
                    tracker.core.track(payloadBuilder);
                }

                startAt = null;
            };

            const handleVisibilityChange: EventListener = () => {
                if (document.visibilityState === 'visible') {
                    // Start a new session if user switches back to this tab or switches back to this window
                    startSession();
                } else {
                    // End session if user switches to another tab or minimizes the window
                    endSession();
                }
            };

            const handleNavigate: NavigateEventListener = () => {
                // Start a new session when URL changes
                startSession();
            };

            navigation.addEventListener('navigate', handleNavigate);
            document.addEventListener(
                'visibilitychange',
                handleVisibilityChange,
            );

            // Start session on page load
            startSession();
        },
    };
}

export function getPayloadBuilderFromDateRange(
    startAt: Date,
    endAt: Date,
): PayloadBuilder | void {
    if (getDurationInSeconds(startAt, endAt) < MINIMUM_DURATION_SECONDS) {
        return;
    }

    return buildSelfDescribingEvent({
        event: getSessionEvent(startAt, endAt),
    });
}

export function getSessionEvent(startAt: Date, endAt: Date): SessionEvent {
    return {
        schema: 'iglu:no.tripletex/event_session/jsonschema/1-0-0',
        data: {
            sessionStartTstamp: startAt.toJSON(),
            sessionEndTstamp: endAt.toJSON(),
            sessionDuration: getDurationInSeconds(startAt, endAt),
        },
    };
}

function getDurationInSeconds(startAt: Date, endAt: Date): number {
    return Math.round((endAt.getTime() - startAt.getTime()) / 1000);
}
