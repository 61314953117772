export type EmployeeDTO = {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumberMobile?: string;
    employeeNumber: string;
    department?: {
        name: string;
    };
};

export const employeeCategory = 'employee';
