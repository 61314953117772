import {
    addTracker,
    BrowserPlugin,
    SharedState,
    TrackerConfiguration,
} from '@snowplow/browser-tracker-core';
import { version as SNOWPLOW_VERSION } from '@snowplow/tracker-core';
import './api';
import { ButtonPlugin } from './plugins/button';
import { GlobalContextsPlugin } from './plugins/global-contexts';
import { HelpCenterPlugin } from './plugins/help-center';
import { HotkeysPlugin } from './plugins/hotkeys';
import { InputPlugin } from './plugins/input';
import { LinkPlugin } from './plugins/link';
import { PageViewPlugin } from './plugins/page-view';
import { SelectPlugin } from './plugins/select';
import { SessionPlugin } from './plugins/session';
import { TextAreaPlugin } from './plugins/textarea';
import { TripletexCavesDropdownPlugin } from './plugins/tlx-caves';
import { TripletexDropdownReduxFormPlugin } from './plugins/tlx-dropdown-redux-form';
import { TripletexPeriodSelectorPlugin } from './plugins/tlx-period-selector';
import { TripletexSectionPlugin } from './plugins/tlx-section';
import { TripletexTableSelectionPlugin } from './plugins/tlx-table-selection';
import { TourMePlugin } from './plugins/tour-me';
import { ZendeskPlugin } from './plugins/zendesk';
import { fetchUserConsent } from './user-consent';
import { AccountHelpPlugin } from './plugins/account-help';

export async function initializeTracker() {
    const consent = await fetchUserConsent();

    // Ensure we always have consent before initializing our tracker
    if (!consent) {
        return;
    }

    // Some of our plugins are async, so we need to wait for them to be ready
    const plugins: BrowserPlugin[] = await Promise.all([
        ButtonPlugin(),
        GlobalContextsPlugin(),
        HelpCenterPlugin(),
        HotkeysPlugin(),
        InputPlugin(),
        LinkPlugin(),
        PageViewPlugin(),
        SelectPlugin(),
        SessionPlugin(),
        TextAreaPlugin(),
        TourMePlugin(),
        TripletexCavesDropdownPlugin(),
        TripletexPeriodSelectorPlugin(),
        TripletexDropdownReduxFormPlugin(),
        TripletexTableSelectionPlugin(),
        TripletexSectionPlugin(),
        ZendeskPlugin(),
        AccountHelpPlugin(),
    ]);

    /**
     * Snowplow's default addTracker includes a bunch of legacy handling we don't want.
     * Especially 'beforeunload' that prevents BFA cache from working.
     *
     * @see https://github.com/snowplow/snowplow-javascript-tracker/blob/master/libraries/browser-tracker-core/src/state.ts
     */
    const isProduction = document.location.host === 'tripletex.no';
    const trackerId = 'spacesuit';
    const version = `js-${SNOWPLOW_VERSION}`;
    const endpoint = isProduction
        ? 'https://snowplow.tripletex.no'
        : 'https://snowplow-test.visma.com';
    const state = new SharedState();
    const configuration: TrackerConfiguration = {
        encodeBase64: false, // Probably not needed
        appId: 'tripletex',
        platform: 'web',
        respectDoNotTrack: true,
        eventMethod: 'beacon',
        stateStorageStrategy: 'localStorage', // We have enough cookies as it is
        plugins,
        contexts: {
            webPage: true,
            session: true,
        },
    };

    addTracker(trackerId, trackerId, version, endpoint, state, configuration);
}
