import { offset, shift, useFloating } from '@floating-ui/react';
import {
    Button,
    Icon,
    PopupMenu,
    PopupMenuItemButton,
    PopupMenuItemLink,
    Portal,
    usePopupMenu,
} from '@tlx/atlas';
import classNames from 'classnames';
import React, {
    CSSProperties,
    forwardRef,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TopbarButton } from '../Topbar/Topbar';
import { useComposedRefs } from '../Topbar/useComposedRefs';
import { Link } from '../Link/Link';
import { Timeago } from './Timeago';
import { LoadMoreTarget } from './LoadMoreTarget';
import { NotificationDTO } from './types';
import { Sanitize } from '../Sanitize/Sanitize';

function useDesktopNotificationPermission(): {
    hasPermission: boolean;
    requestPermission: () => void;
} {
    const [hasPermission, setHasPermission] = useState(
        'Notification' in window &&
            window.Notification.permission === 'granted',
    );

    return {
        hasPermission,
        requestPermission() {
            if ('Notification' in window) {
                window.Notification.requestPermission().then((status) => {
                    setHasPermission(status === 'granted');
                });
            }
        },
    };
}

export type TopbarNotificationsProps = {
    notifications: NotificationDTO[];
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    hasMore: boolean;
    loadMore: () => void;
    hasUnreadNotifications: boolean;
    notificationSettingsUrl: string;
    onEmptyNotifications: () => void;
};

export function TopbarNotifications({
    notifications,
    isOpen,
    setIsOpen,
    hasMore,
    loadMore,
    hasUnreadNotifications,
    notificationSettingsUrl,
    onEmptyNotifications,
}: TopbarNotificationsProps) {
    const { formatMessage } = useIntl();
    const floating = useFloating({
        placement: 'bottom-start',
        // The 11px value is need to align the popup with the other popup on Topbar
        middleware: [shift({ padding: 11 }), offset(11)],
    });

    const popupPositioningStyle: CSSProperties = {
        position: floating.strategy,
        top: floating.y ?? '',
        left: floating.x ?? '',
    };

    const buttonRef = useRef<HTMLButtonElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);

    const combinedButtonRefs = useComposedRefs(
        buttonRef,
        floating.refs.setReference,
    );
    const combinedPopupRefs = useComposedRefs(
        popupRef,
        floating.refs.setFloating,
    );

    function onBlur(
        event: React.FocusEvent<HTMLButtonElement | HTMLDivElement>,
    ) {
        const clickInsidePopup = popupRef?.current?.contains(
            event.relatedTarget,
        );

        const clickInsideButton = buttonRef?.current?.contains(
            event.relatedTarget,
        );

        if (!clickInsidePopup && !clickInsideButton) {
            setIsOpen(false);
        }
    }

    useLayoutEffect(() => {
        if (isOpen) {
            floating.update();
            popupRef.current?.focus({ preventScroll: true });
        }
    }, [isOpen, notifications]);

    useEffect(() => {
        function handler() {
            setIsOpen(false);
        }

        if (isOpen) {
            window.addEventListener('resize', handler);
            return () => {
                window.removeEventListener('resize', handler);
            };
        }
    }, [isOpen]);

    return (
        <>
            <TopbarButton
                label={formatMessage({ id: 'text_notifications' })}
                icon={'notifications_none'}
                data-testid={'header-notifications-button'}
                notify={hasUnreadNotifications}
                onClick={() => setIsOpen(!isOpen)}
                ref={combinedButtonRefs}
                onBlur={onBlur}
            />
            <Portal>
                <TopbarNotificationsPopup
                    style={popupPositioningStyle}
                    hidden={!isOpen}
                    onBlur={onBlur}
                    ref={combinedPopupRefs}
                    notifications={notifications}
                    notificationSettingsUrl={notificationSettingsUrl}
                    onEmptyNotifications={onEmptyNotifications}
                    hasMore={hasMore}
                    loadMore={loadMore}
                />
            </Portal>
        </>
    );
}

export const TopbarNotificationsPopup = forwardRef(function HelpCenterPopup(
    {
        hidden,
        style,
        onBlur,
        notifications,
        notificationSettingsUrl,
        onEmptyNotifications,
        hasMore,
        loadMore,
    }: {
        hidden?: boolean;
        style?: CSSProperties;
        onBlur?: React.FocusEventHandler<HTMLDivElement>;
        notifications: NotificationDTO[];
        notificationSettingsUrl: string;
        onEmptyNotifications: () => void;
        hasMore: boolean;
        loadMore: () => void;
    },
    ref: React.Ref<HTMLDivElement>,
) {
    const { formatMessage } = useIntl();

    const popupMenu = usePopupMenu();
    const notificationPermission = useDesktopNotificationPermission();

    return (
        <section
            className={classNames(
                'tlx-topbar-notifications__notifications-popup atl-bg-white atl-rounded atl-pb-16',
                hidden ? 'atl-hidden' : undefined,
            )}
            style={style}
            tabIndex={-1}
            ref={ref}
            onBlur={onBlur}
        >
            <div className="atl-flex atl-p-16">
                <h2 className="atl-text-lg atl-font-medium atl-my-8">
                    {formatMessage({ id: 'text_notifications' })}
                </h2>
                <Button
                    {...popupMenu.openerButtonProps}
                    className="atl-ml-auto"
                    data-testid="notifications-popup-context-menu-button"
                    aria-label="Notification settings"
                    variant="icon"
                >
                    <Icon>more_vert</Icon>
                </Button>
                <PopupMenu {...popupMenu.popupMenuProps}>
                    <PopupMenuItemButton
                        data-testid="empty-notifications-button"
                        onClick={onEmptyNotifications}
                    >
                        <Icon>done</Icon>
                        <span>
                            <FormattedMessage id="text_notification_empty_list" />
                        </span>
                    </PopupMenuItemButton>
                    {notificationPermission.hasPermission ? null : (
                        <PopupMenuItemButton
                            data-testid="allow-desktop-notifications"
                            onClick={notificationPermission.requestPermission}
                        >
                            <Icon>picture_in_picture</Icon>
                            <span>
                                <FormattedMessage id="text_notification_allow_desktop" />
                            </span>
                        </PopupMenuItemButton>
                    )}
                    <PopupMenuItemLink
                        data-testid="notification-settings-link"
                        href={notificationSettingsUrl}
                    >
                        <Icon>settings</Icon>
                        <span>
                            <FormattedMessage id="text_notification_settings" />
                        </span>
                    </PopupMenuItemLink>
                </PopupMenu>
            </div>
            {notifications.length > 0 ? (
                <ul className="tlx-topbar-notifications__notifications-list">
                    {notifications.map((notification, index) => (
                        <TopbarNotification
                            notification={notification}
                            key={index}
                        />
                    ))}
                </ul>
            ) : (
                <p className="atl-px-16">
                    {formatMessage({ id: 'text_no_notifications' })}
                </p>
            )}
            <LoadMoreTarget hasMore={hasMore} loadMore={loadMore} />
        </section>
    );
});

const categories = {
    BANK: 'account_balance',
    VOUCHER: 'receipt',
    BUSINESS: 'business',
    DOCUMENT: 'credit_card',
    DONE: 'done',
    EXTERNAL: 'open_in_new',
    SUPPORT: 'contact_phone',
    TRAVEL_REPORT: 'flight_takeoff',
    EMPLOYEE_EXPENSE: 'credit_card',
    HOURLIST: 'timer',
    WARNING: 'warning',
    DEFAULT: 'feedback',
    PROJECT: 'business_center',
};

function getNotificationIcon(notification: NotificationDTO): string {
    return (
        categories[notification.category as keyof typeof categories] ??
        categories[notification.type as keyof typeof categories] ??
        categories.DEFAULT
    );
}

function TopbarNotification({
    notification,
}: {
    notification: NotificationDTO;
}) {
    return (
        <li>
            <Link
                href={addCurrentContextId(notification.link)}
                className="atl-p-16 atl-flex atl-gap-24 tlx-topbar-notifications__notification"
            >
                <Icon className="tlx-topbar-notifications__notification-icon">
                    {getNotificationIcon(notification)}
                </Icon>
                <div className="atl-text-sm tlx-topbar-notifications__wrap-anywhere">
                    <h4 className="atl-m-0 atl-text-base atl-font-medium tlx-topbar-notifications__wrap-anywhere">
                        {notification.title}
                    </h4>
                    <Sanitize htmlContent={notification.message} />
                </div>
                <div className="atl-ml-auto atl-text-right atl-text-grey-80 atl-text-sm">
                    <Timeago date={notification.date} />
                </div>
            </Link>
        </li>
    );
}

function addCurrentContextId(href: string): string {
    const url = new URL(href, window.location.origin);

    if (url.origin !== window.location.origin) {
        return href;
    }

    if (url.searchParams.has('contextId')) {
        return href;
    }

    const contextId = new URLSearchParams(window.location.search).get(
        'contextId',
    );

    if (contextId === null) {
        console.warn('No contextId found in current URL');

        return href;
    }

    url.searchParams.set('contextId', contextId);

    return url.toString();
}
