import type { BrowserPlugin } from '@snowplow/browser-tracker-core';
import {
    buildFormFocusOrChange,
    FormFocusOrChangeEvent,
    PayloadBuilder,
} from '@snowplow/tracker-core';
import { getClasses, getElementId, getEventTarget, getFormId } from '../utils';

export function TripletexCavesDropdownPlugin(): BrowserPlugin {
    return {
        activateBrowserPlugin: (tracker) => {
            const handleChange: EventListener = (event) => {
                const payloadBuilder = getPayloadBuilderFromChangeEvent(event);

                if (payloadBuilder) {
                    tracker.core.track(payloadBuilder);
                }
            };

            document.addEventListener('change', handleChange);
        },
    };
}

export function getPayloadBuilderFromChangeEvent(
    event: Event,
): PayloadBuilder | void {
    const input = getEventTarget(event);

    if (isCavesDropdownInput(input)) {
        return buildFormFocusOrChange(getInputFormChangeEvent(input));
    }
}

export function getInputFormChangeEvent(
    input: HTMLInputElement,
): FormFocusOrChangeEvent {
    return {
        schema: 'change_form',
        formId: getFormId(input),
        elementId: getElementId(input),
        nodeName: 'SELECT',
        elementClasses: getClasses(input),
        value: input.value,
    };
}

function isCavesDropdownInput(
    input: Element | null,
): input is HTMLInputElement {
    return input !== null && input.matches('.caves input[type="hidden"]');
}
