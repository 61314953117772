export type ContactDTO = {
    id: number;
    firstName: string;
    lastName: string;
    customer?: {
        name: string;
    };
    email: string;
};

export const contactCategory = 'contact';
