class NavigateEventPonyfill extends Event implements NavigateEvent {
    private _destination: NavigationDestination;
    private _downloadRequest: string | null;

    constructor(destination: NavigationDestination) {
        super('navigate');

        this._destination = destination;
        this._downloadRequest = null;
    }

    get destination() {
        return this._destination;
    }

    get downloadRequest() {
        return this._downloadRequest;
    }
}

class NavigationAPIPonyfill implements Navigation {
    private listeners = new Set<NavigateEventListener>();
    private intervalId: number | undefined;
    private from: string | undefined;
    private to = window.location.href;

    constructor(private intervalDelay = 100) {}

    private handleNavigation() {
        this.from = this.to;
        this.to = window.location.href;

        if (this.to !== this.from) {
            const event = new NavigateEventPonyfill({
                url: this.to,
            });

            for (const listener of this.listeners) {
                listener(event);
            }
        }
    }

    addEventListener(_type: 'navigate', listener: NavigateEventListener) {
        this.listeners.add(listener);

        if (this.listeners.size === 1) {
            this.intervalId = window.setInterval(
                // We need to bind the function to the class instance, otherwise
                // `this` will point to the global object.
                this.handleNavigation.bind(this),
                this.intervalDelay,
            );
        }
    }

    removeEventListener(_type: 'navigate', listener: NavigateEventListener) {
        this.listeners.delete(listener);

        if (this.listeners.size === 0) {
            window.clearInterval(this.intervalId);
        }
    }
}

export const navigation =
    typeof window.navigation !== 'undefined'
        ? window.navigation
        : new NavigationAPIPonyfill();
