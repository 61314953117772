import { ListResponse, fetcher } from '@tlx/astro-shared';
import useSWR from 'swr';
import { useActiveDocumentationComponent } from '../../hooks/useActiveDocumentationComponent';
import { getLocalStorageItem, setLocalStorageItem } from '../../utils/storage';
import { CalloutDTO } from './types';

export type UseCalloutsReturn = {
    ready: boolean;
    callouts: CalloutDTO[];
    cancelCallout(id: number): void;
};

const CANCELLED_CALLOUTS_LOCAL_STORAGE_KEY = 'spacesuit-cancelled-callouts';

export function useCallouts(): UseCalloutsReturn {
    return useLocallyFilteredCallouts();
}

function useLocallyFilteredCallouts() {
    const url = useCalloutsUrl();
    const { data, mutate, isLoading } = useSWR<ListResponse<CalloutDTO>>(
        url,
        fetcher,
    );

    // Other tabs might cancel callouts, so we need to fetch the latest from local storage
    const canceledCalloutIds = getCanceledCalloutIds();
    const callouts = data
        ? data.values.filter(
              (callout) => !canceledCalloutIds.includes(callout.id),
          )
        : [];

    const cancelCallout = (id: number) => {
        const nextCallouts = callouts.filter((callout) => callout.id !== id);
        const optimisticData: ListResponse<CalloutDTO> = {
            fullResultSize: nextCallouts.length,
            from: 0,
            count: nextCallouts.length,
            values: nextCallouts,
        };

        setCanceledCalloutIds([...canceledCalloutIds, id]);
        mutate(optimisticData, {
            revalidate: false,
        });
    };

    return {
        ready: !isLoading,
        callouts,
        cancelCallout,
    };
}

function getCanceledCalloutIds(): number[] {
    return getLocalStorageItem<number[]>(
        CANCELLED_CALLOUTS_LOCAL_STORAGE_KEY,
        [],
    );
}

function setCanceledCalloutIds(ids: number[]): void {
    setLocalStorageItem(CANCELLED_CALLOUTS_LOCAL_STORAGE_KEY, ids);
}

function useCalloutsUrl(): string | undefined {
    const documentationComponent = useActiveDocumentationComponent();

    if (documentationComponent === undefined) {
        return undefined;
    }

    return `/v2/web/callout?documentationComponent=${documentationComponent}`;
}
