import { Icon } from '@tlx/atlas';

const isMac = navigator.platform.match(/(Mac)/i);

const OsKey = isMac ? () => <span>&#8984;</span> : () => <>ctrl</>;

const PageUpKey = isMac ? () => <span>&#x21DE;</span> : () => <>Page Up</>;

const PageDownKey = isMac ? () => <span>&#x21DF;</span> : () => <>Page Down</>;

const AltKey = isMac ? () => <span>&#8984;</span> : () => <>alt</>;

const TabKey = isMac
    ? () => <Icon>&#xE31C;</Icon>
    : () => <span>&#x21B9;</span>;

const ScrollDownKeys = isMac
    ? () => [<span>&#8984;</span>, <Icon>&#xE313;</Icon>]
    : () => ['Page Down'];

const ScrollUpKeys = isMac
    ? () => [<span>&#8984;</span>, <Icon>&#xE316;</Icon>]
    : () => ['Page Up'];

type ShortcutDictionary = {
    id: string;
    shortcuts: ShortcutDefinition[];
};
type ShortcutDefinition = { keys: (string | JSX.Element)[]; id: string };

export const GlobalKeyboardShortcuts: ShortcutDictionary = {
    id: 'text_global_shortcut',
    shortcuts: [
        {
            keys: ['m'],
            id: 'text_shortcut_hide_show_menu',
        },
        {
            keys: ['f'],
            id: 'text_shortcut_search',
        },
        {
            keys: ['d'],
            id: 'text_shortcut_expand_collapse_section',
        },
        {
            keys: ['?'],
            id: 'text_shortcut_show_hide_shortcut',
        },
        {
            keys: ['v'],
            id: 'text_shortcut_hide_pdf_preview',
        },
        {
            keys: ['c'],
            id: 'text_shortcut_open_company_chooser',
        },
        {
            keys: [<OsKey />, 'f'],
            id: 'text_shortcut_search_page',
        },
        {
            keys: [<OsKey />, 's'],
            id: 'text_shortcut_save',
        },
        {
            keys: [<OsKey />, 'Enter'],
            id: 'button_refresh',
        },
        {
            keys: [<TabKey />],
            id: 'text_shortcut_next',
        },
        {
            keys: [<span>&#8679;</span>, <TabKey />],
            id: 'text_shortcut_previous',
        },
        {
            keys: [<AltKey />, <Icon>&#xE314;</Icon>],
            id: 'text_previous_page',
        },
        {
            keys: [<AltKey />, <Icon>&#xE315;</Icon>],
            id: 'text_next_page',
        },
        {
            keys: [...ScrollDownKeys()],
            id: 'text_scroll_to_bottom',
        },
        {
            keys: [...ScrollUpKeys()],
            id: 'text_scroll_to_top',
        },
    ],
};

export const AdvancedVoucherKeyboardShortcuts: ShortcutDictionary = {
    id: 'text_advanced_voucher',
    shortcuts: [
        {
            keys: ['Enter'],
            id: 'text_shortcut_next_input',
        },
        {
            keys: ['+'],
            id: 'text_shortcut_new_row',
        },
    ],
};

export const InboxVoucherKeyboardShortcuts: ShortcutDictionary = {
    id: 'text_inbox_voucher',
    shortcuts: [
        {
            keys: [<Icon>&#xE316;</Icon>],
            id: 'text_shortcut_next_voucher',
        },
        {
            keys: [<Icon>&#xE313;</Icon>],
            id: 'text_shortcut_previous_voucher',
        },
        {
            keys: [<Icon>&#xE256;</Icon>],
            id: 'text_shortcut_select_voucher',
        },
        {
            keys: ['r'],
            id: 'text_shortcut_register_all_selected_vouchers',
        },
        {
            keys: [<OsKey />, 'a'],
            id: 'text_shortcut_select_all_vouchers',
        },
        {
            keys: ['esc'],
            id: 'text_shortcut_deselect_vouchers',
        },
        {
            keys: ['d'],
            id: 'text_shortcut_expand_collapse_voucher_row',
        },
        {
            keys: [<OsKey />, 'd'],
            id: 'text_shortcut_expand_collapse_all_voucher_rows',
        },
        {
            keys: [<span>&#8679;</span>],
            id: 'text_shortcut_select_multiple_vouchers',
        },
        {
            keys: [<OsKey />, 'v'],
            id: 'text_shortcut_paste_voucher_file',
        },
    ],
};

export const TravelAndExpenseKeyboardShortcuts: ShortcutDictionary = {
    id: 'text_travel_reports_or_employee_expenses_short',
    shortcuts: [
        {
            keys: ['+', 'R'],
            id: 'text_new_travel_report',
        },
        {
            keys: ['+', 'U'],
            id: 'text_new_employee_expense2',
        },
    ],
};

export const VoucherQueueKeyboardShortcuts: ShortcutDictionary = {
    id: 'text_voucher_queue',
    shortcuts: [
        {
            keys: ['end'],
            id: 'text_shortcut_to_last_voucher',
        },
        {
            keys: ['home'],
            id: 'text_shortcut_to_first_voucher',
        },
        {
            keys: [PageDownKey()],
            id: 'text_shortcut_next_voucher',
        },
        {
            keys: [PageUpKey()],
            id: 'text_shortcut_previous_voucher',
        },
    ],
};

export const DropdownMenuKeyboardShortcuts: ShortcutDictionary = {
    id: 'text_tlx_select',
    shortcuts: [
        {
            keys: [<Icon>&#xE313;</Icon>],
            id: 'text_shortcut_open_select',
        },
        {
            keys: ['abc...'],
            id: 'text_shortcut_open_select_with_input',
        },
        {
            keys: ['esc'],
            id: 'text_shortcut_close',
        },
    ],
};

export const PeriodKeyboardShortcuts: ShortcutDictionary = {
    id: 'text_period_selector',
    shortcuts: [
        {
            keys: ['Enter'],
            id: 'text_shortcut_select_period',
        },
        {
            keys: [<Icon>&#xE315;</Icon>],
            id: 'text_shortcut_next_period',
        },
        {
            keys: [<Icon>&#xE314;</Icon>],
            id: 'text_shortcut_previous_period',
        },
        {
            keys: [<Icon>&#xE313;</Icon>, <Icon>&#xE316;</Icon>],
            id: 'text_shortcut_cycle_through_period_types',
        },
        {
            keys: [<span>&#8679;</span>, <Icon>&#xE315;</Icon>],
            id: 'text_shortcut_increase_period_one_increment',
        },
        {
            keys: [<span>&#8679;</span>, <Icon>&#xE314;</Icon>],
            id: 'text_shortcut_decrease_period_one_increment',
        },
    ],
};

export const DateKeyboardShortcuts: ShortcutDictionary = {
    id: 'text_date_picker',
    shortcuts: [
        {
            keys: ['t'],
            id: 'text_shortcut_date_today',
        },
        {
            keys: [<Icon>&#xE313;</Icon>],
            id: 'text_shortcut_next_day',
        },
        {
            keys: [<Icon>&#xE316;</Icon>],
            id: 'text_shortcut_previous_day',
        },
        {
            keys: [PageDownKey()],
            id: 'text_shortcut_next_month',
        },
        {
            keys: [PageUpKey()],
            id: 'text_shortcut_previous_month',
        },
    ],
};
