export function isNarrowScreen(): boolean {
    return (
        new URLSearchParams(window.location.search).get('narrowScreen') ===
        'true'
    );
}

export function getCurrentRelativeUrl(url: string, base: string) {
    const result = new URL(url, base);
    return result.pathname + result.search + result.hash;
}

// Create an absolute URL from a relative URL and a base URL
export function resolveUrl(baseUrl: string, relativeUrl: string) {
    const separator =
        baseUrl.endsWith('/') || relativeUrl.startsWith('/') ? '' : '/';

    return `${baseUrl}${separator}${relativeUrl}`;
}
