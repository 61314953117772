import {
    add,
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    isBefore,
} from 'date-fns';

export type TimeLeft = {
    remainingDays: number;
    remainingHours: number;
    remainingMinutes: number;
};
export function getRemainingTime(end: Date, now: Date): TimeLeft {
    if (isBefore(end, now)) {
        // Ensure that we don't get negative values.
        // If the trial has expired, we just show 0/0/0
        return {
            remainingDays: 0,
            remainingHours: 0,
            remainingMinutes: 0,
        };
    }

    const remainingDays = differenceInDays(end, now);
    const added = add(now, { days: remainingDays });
    const remainingHours = differenceInHours(end, added, {
        roundingMethod: 'floor',
    });
    const addedHours = add(added, { hours: remainingHours });
    const remainingMinutes = differenceInMinutes(end, addedHours, {
        roundingMethod: 'floor',
    });

    return {
        remainingDays,
        remainingHours,
        remainingMinutes,
    };
}
