import {
    GoBackButton,
    LoadMoreButton,
    SearchResult,
    SearchResultItem,
    SearchResultItemField,
    SearchResultItemIcon,
    SearchResultItemLink,
    SearchResultItems,
    SearchResultItemTitle,
    SearchResultSkeleton,
    SearchResultTitle,
    SearchResultTitleLink,
} from '../SearchContainer';
import { UseSearchCategoryReturn } from '../types';
import { useResultTitleTranslation } from '../useResultTitleTranslation';
import { getUrlWithContext } from '../util';
import {
    customerCategory,
    CustomerCategoryDTO,
    CustomerCategoryId,
    CustomerDTO,
} from './types';

export function CustomerResult(props: UseSearchCategoryReturn<CustomerDTO>) {
    const translation = useResultTitleTranslation('text_customers');

    const { data, hasMore, loading, loadMore, goBack } = props;

    const hasData = data !== undefined && data.length > 0;
    if (!hasData && !loading) {
        return null;
    }

    return (
        <SearchResult category={customerCategory}>
            <SearchResultTitle>
                <div className="atl-flex atl-items-center">
                    <GoBackButton onGoBack={goBack} />
                    {translation.title}
                </div>
                <SearchResultTitleLink
                    href={getUrlWithContext('/execute/listCustomers', {
                        customerType: '1',
                    })}
                >
                    {translation.overviewLinkText}
                </SearchResultTitleLink>
            </SearchResultTitle>
            <SearchResultItems>
                {data?.map((item: CustomerDTO) => (
                    <CustomerResultItem key={item.id} data={item} />
                ))}
                {loading && <SearchResultSkeleton />}
                {!loading && hasMore && (
                    <LoadMoreButton
                        data-testid={`search-show-more`}
                        onLoadMore={loadMore}
                        category={customerCategory}
                    />
                )}
            </SearchResultItems>
        </SearchResult>
    );
}

export function CustomerResultItem({ data }: { data: CustomerDTO }) {
    const url = getUrlWithContext('/execute/customerMenu', {
        customerId: data.id.toString(),
    });
    return (
        <SearchResultItem>
            <SearchResultItemIcon className="tlx-search__result-customer-icon">
                people
            </SearchResultItemIcon>
            <SearchResultItemLink href={url} category={customerCategory}>
                <SearchResultItemTitle>{data.name}</SearchResultItemTitle>
                <SearchResultItemField
                    translationKey={'text_organisation_number_short'}
                >
                    {data.organizationNumber}
                </SearchResultItemField>
                <SearchResultItemField
                    translationKey={'text_customer_number_short'}
                >
                    {data.customerNumber}
                </SearchResultItemField>
                <CustomerResultCategoryField category={data.category3} />
            </SearchResultItemLink>
        </SearchResultItem>
    );
}

/**
 * According to the legacy customer listing, these are the possible values for customer category.
 *
 * @see listCustomers.jsp
 */
export const CUSTOMER_CATEGORY_NAMES: Record<number, string | undefined> = {
    [CustomerCategoryId.TEST]: 'Test',
    [CustomerCategoryId.GRATIS]: 'Gratis',
    [CustomerCategoryId.BETALING]: 'Betalende',
    [CustomerCategoryId.AGRO]: 'Agro',
    [CustomerCategoryId.MAMUT]: 'Mamut',
};

function CustomerResultCategoryField({
    category,
}: {
    category?: CustomerCategoryDTO | null;
}) {
    if (!category) {
        return null;
    }

    const name = CUSTOMER_CATEGORY_NAMES[category.id];

    if (name === undefined) {
        return null;
    }

    return (
        <SearchResultItemField translationKey="text_category">
            {name}
        </SearchResultItemField>
    );
}
