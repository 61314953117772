/* eslint-disable @typescript-eslint/no-explicit-any */
// Copied from https://github.com/reach/reach-ui/blob/develop/packages/utils/src/compose-refs.ts
import { useCallback } from 'react';

type AssignableRef<ValueType> =
    | { bivarianceHack(instance: ValueType | null): void }['bivarianceHack']
    | React.MutableRefObject<ValueType | null>;

export function useComposedRefs<RefValueType = any>(
    ...refs: (AssignableRef<RefValueType> | null | undefined)[]
) {
    return useCallback((node: any) => {
        for (const ref of refs) {
            assignRef(ref, node);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, refs);
}

export function assignRef<RefValueType = any>(
    ref: AssignableRef<RefValueType> | null | undefined,
    value: any,
) {
    if (ref == null) {
        return;
    } else if (ref instanceof Function) {
        ref(value);
    } else {
        try {
            ref.current = value;
        } catch (error) {
            throw new Error(`Cannot assign value "${value}" to ref "${ref}"`);
        }
    }
}
