import { useApiSearch } from '../useSearch';
import { InvoiceDTO } from './types';

const endpoint = `/v2/invoice/query`;
const searchParams = new URLSearchParams({
    fields: 'id,invoiceNumber,customer(name),amount',
});

export function useInvoiceSearch(query: string) {
    return useApiSearch<InvoiceDTO>(endpoint, query, searchParams);
}
