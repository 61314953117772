import type { BrowserPlugin } from '@snowplow/browser-tracker-core';
import {
    buildFormFocusOrChange,
    FormFocusOrChangeEvent,
    PayloadBuilder,
} from '@snowplow/tracker-core';
import { getClasses, getElementId, getEventTarget, getFormId } from '../utils';

export function TextAreaPlugin(): BrowserPlugin {
    return {
        activateBrowserPlugin: (tracker) => {
            const handleChange: EventListener = (event) => {
                const payloadBuilder = getPayloadBuilderFromChangeEvent(event);

                if (payloadBuilder) {
                    tracker.core.track(payloadBuilder);
                }
            };

            document.addEventListener('change', handleChange);
        },
    };
}

export function getPayloadBuilderFromChangeEvent(
    event: Event,
): PayloadBuilder | void {
    const textarea = getEventTarget(event);

    if (textarea instanceof HTMLTextAreaElement) {
        return buildFormFocusOrChange(getTextAreaFormChangeEvent(textarea));
    }
}

export function getTextAreaFormChangeEvent(
    textarea: HTMLTextAreaElement,
): FormFocusOrChangeEvent {
    return {
        schema: 'change_form',
        formId: getFormId(textarea),
        elementId: getElementId(textarea),
        nodeName: textarea.nodeName,
        elementClasses: getClasses(textarea),
        value: null,
    };
}
