import { ResponseWrapper, fetcher } from '@tlx/astro-shared';
import { useCallback, useEffect } from 'react';
import useSWRImmutable from 'swr/immutable';
import { ZendeskOpenChatEvent } from '../../events';
import { useZendesk } from '../../zendesk/useZendesk';

type SpacesuitMetaDTO = {
    helpCenterBaseUrl: string;
};

export type UseHelpCenterReturn = {
    baseUrl: string | null;
    isChatAvailable: boolean;
    isChatOpen: boolean;
    isChatting: boolean;
    unreadCount: number;
    openChat(): void;
};

export function useHelpCenter(): UseHelpCenterReturn {
    const zendesk = useZendesk();
    const { data } = useSWRImmutable<ResponseWrapper<SpacesuitMetaDTO>>(
        '/v2/internal/spacesuit/meta',
        fetcher,
    );

    // Open chat when we receive the zendesk:open-chat event
    useEffect(() => {
        const handleOpenChat = (event: ZendeskOpenChatEvent) => {
            zendesk.openChat(event.detail.initialChat, event.detail.tags);
        };

        window.addEventListener('zendesk:open-chat', handleOpenChat);

        return () => {
            window.removeEventListener('zendesk:open-chat', handleOpenChat);
        };
    }, [zendesk]);

    // Open chat when active chat button is clicked
    const openChat = useCallback(() => {
        zendesk.openChat(null, []);
    }, [zendesk]);

    return {
        baseUrl: data ? data.value.helpCenterBaseUrl : null,
        isChatAvailable: zendesk.isAvailable,
        isChatOpen: zendesk.isOpen,
        isChatting: zendesk.isChatting,
        unreadCount: zendesk.unreadCount,
        openChat,
    };
}
