import { TopbarNotificationsProps } from './TopbarNotifications';
import { notifications, SpacesuitNotificationMeta } from './notifications';
import { getPageTitleWithNewAmount } from './pageTitle';
import { useMessagesFromServer } from './useMessagesFromServer';
import { useFetch } from '../../hooks/useFetch';
import { useEffect, useState } from 'react';

function useNotificationCount() {
    const [count, setCount] = useState(0);

    useEffect(() => {
        function countHandler(event: CustomEvent<{ count: number }>) {
            const count = event.detail.count;
            setCount(count);
        }

        // @ts-expect-error We need to type this event
        window.addEventListener('tlx:notification:count', countHandler);
        return () => {
            // @ts-expect-error We need to type this event
            window.removeEventListener('tlx:notification:count', countHandler);
        };
    }, []);

    useEffect(() => {
        window.document.title = getPageTitleWithNewAmount(
            window.document.title,
            count,
        );
    }, [count]);

    return {
        count,
        resetCount() {
            setCount(0);
        },
    };
}

function useNotificationSettingsUrl() {
    const { data } = useFetch<SpacesuitNotificationMeta>(
        '/v2/event/notification/spacesuit',
    );
    return data?.notificationSettingsUrl ?? '';
}

export function useTopbarNotifications(): TopbarNotificationsProps {
    const [isOpen, setIsOpen] = useState(false);

    const { count, resetCount } = useNotificationCount();
    const { messages, hasMore, loadMore, reset, empty } =
        useMessagesFromServer();

    const notificationSettingsUrl = useNotificationSettingsUrl();

    const hasUnreadNotifications = count > 0;

    useEffect(() => {
        notifications.init();
    }, []);

    function doSetIsOpen(isOpen: boolean) {
        setIsOpen(isOpen);
        if (isOpen) {
            resetCount();
            reset();
        }
    }

    return {
        notifications: messages,
        isOpen,
        setIsOpen: doSetIsOpen,
        hasMore,
        loadMore,
        hasUnreadNotifications,
        notificationSettingsUrl,
        onEmptyNotifications: empty,
    };
}
