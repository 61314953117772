import type { BrowserPlugin } from '@snowplow/browser-tracker-core';
import {
    buildSelfDescribingEvent,
    PayloadBuilder,
    SelfDescribingJson,
} from '@snowplow/tracker-core';
import { getElementId, getEventTarget, getFormId } from '../utils';

/**
 * A button click event describes an interaction with a button on a page (excluding hyperlinks inside elements disguised as buttons, which are tracked as link clicks)
 */
export interface ButtonClickEvent extends SelfDescribingJson {
    schema: 'iglu:no.tripletex/event_button_click/jsonschema/1-0-0';
    data: {
        /**
         * An id identifying the button on the page. The elementId is not necessarily unique across Tripletex.
         */
        elementId: string;

        /**
         * An id identifying the parent form that contains the button.
         */
        formId?: string;

        /**
         * The name of the table in the Tripletex database that this button interacts with. The row/record id can be found from the modelId property.
         */
        modelName?: string;

        /**
         * An id of a row/record in the Tripletex database that this button interacts with. The table name can be found from the modelName property.
         */
        modelId?: number;
    };
}

export function ButtonPlugin(): BrowserPlugin {
    return {
        activateBrowserPlugin: (tracker) => {
            const handleClick: EventListener = (event) => {
                const payloadBuilder = getPayloadBuilderFromClickEvent(event);

                if (payloadBuilder) {
                    tracker.core.track(payloadBuilder);
                }
            };

            document.addEventListener('click', handleClick, {
                capture: true,
            });
        },
    };
}

export function getPayloadBuilderFromClickEvent(
    event: Event,
): PayloadBuilder | void {
    // Some buttons in Tripletex are programmatically invoked
    if (!event.isTrusted) {
        return;
    }

    const button = getEventTarget(event)?.closest('button');

    if (button instanceof HTMLButtonElement) {
        return buildSelfDescribingEvent({ event: getButtonClickEvent(button) });
    }
}

export function getButtonClickEvent(element: HTMLElement): ButtonClickEvent {
    return {
        schema: 'iglu:no.tripletex/event_button_click/jsonschema/1-0-0',
        data: {
            elementId: getElementId(element),
            formId: getFormId(element),
        },
    };
}
