import { useEffect } from 'react';
import { getKeyboardEventKeybinding } from '../utils/getKeyboardEventKeybinding';

export function useKeyboardShortcut(keyBinding: string, action: () => void) {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            // We might be inside a Shadow DOM so we can't use event.target
            const target = event.composedPath()[0];

            if (!(target instanceof HTMLElement)) {
                return;
            }

            // Ignore editable elements
            if (isEditableElement(target)) {
                return;
            }

            if (keyBinding === getKeyboardEventKeybinding(event)) {
                action();

                // we do this to avoid side-effects
                // (e.g. prevent the letter 'f' from being typed into the search input field )
                event.preventDefault();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [action]);
}

function isEditableElement(element: HTMLElement): boolean {
    return (
        element instanceof HTMLInputElement ||
        element instanceof HTMLTextAreaElement ||
        element.isContentEditable ||
        element.getAttribute('role') === 'listbox'
    );
}
