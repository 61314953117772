import { CompanyDTO } from './types';
import { useApiSearch } from '../useSearch';

const endpoint = `/v2/company/query`;
const searchParams = new URLSearchParams({
    fields: 'id,name,organizationNumber',
});

export function useCompanySearch(query: string) {
    return useApiSearch<CompanyDTO>(endpoint, query, searchParams);
}
