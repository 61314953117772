import type { BrowserPlugin } from '@snowplow/browser-tracker-core';
import {
    buildSelfDescribingEvent,
    PayloadBuilder,
} from '@snowplow/tracker-core';
import { getButtonClickEvent } from './button';

export function TripletexPeriodSelectorPlugin(): BrowserPlugin {
    return {
        activateBrowserPlugin: (tracker) => {
            const handleClick: EventListener = (event) => {
                const payloadBuilder = getPayloadBuilderFromClickEvent(event);

                if (payloadBuilder) {
                    tracker.core.track(payloadBuilder);
                }
            };

            document.addEventListener('click', handleClick);
        },
    };
}

export function getPayloadBuilderFromClickEvent(
    event: Event,
): PayloadBuilder | void {
    const button = event.target;

    if (isTripletexPeriodSelectorButton(button)) {
        return buildSelfDescribingEvent({ event: getButtonClickEvent(button) });
    }
}

function isTripletexPeriodSelectorButton(
    button: EventTarget | null,
): button is HTMLElement {
    return (
        button instanceof HTMLElement && button.classList.contains('tlx-period')
    );
}
