import { getContextId } from '@tlx/astro-shared';
import { Button, CloseIcon, InfoIcon } from '@tlx/atlas';
import { useIntl } from 'react-intl';
import { addContextId } from '../../utils/addContextId';
import { BannerDTO, BannerProps } from './types';

function BannerContent(props: { banner: BannerDTO; onClick: () => void }) {
    const { formatMessage } = useIntl();
    return (
        <section className="atl-bg-grey-100 atl-text-white atl-p-24">
            <div className="atl-flex">
                <div className="tlx-banner__info-wrapper atl-flex atl-items-center atl-justify-center">
                    <InfoIcon size={'large'} className={'tlx-banner__icon'} />
                </div>
                <div>
                    <h2 className="atl-text-xl atl-font-medium atl-m-0">
                        {props.banner.title}
                    </h2>
                    <p className="atl-text-base atl-text-white atl-font-normal atl-m-0">
                        {props.banner.link && props.banner.cancellable ? (
                            <a
                                href={addContextId(
                                    props.banner.link,
                                    getContextId() ?? '',
                                )}
                                className="atl-text-white"
                            >
                                {props.banner.message}
                            </a>
                        ) : (
                            <span>{props.banner.message}</span>
                        )}
                    </p>
                </div>
                <div className="tlx-banner__cancel-button">
                    {props.banner.cancellable && (
                        <Button
                            variant="secondary"
                            aria-label={formatMessage({
                                id: 'button_cancel',
                            })}
                            data-testid="cancel-banner-button"
                            onClick={props.onClick}
                        >
                            <CloseIcon />
                        </Button>
                    )}
                </div>
            </div>
        </section>
    );
}

export function Banner(props: BannerProps) {
    const banner = props.banner;

    return (
        <div key={banner.id}>
            {banner.link && !banner.cancellable ? (
                <a href={addContextId(banner.link, getContextId() ?? '')}>
                    <BannerContent
                        banner={banner}
                        onClick={() => props.onCancelBanner(banner.id)}
                    />
                </a>
            ) : (
                <BannerContent
                    banner={banner}
                    onClick={() => props.onCancelBanner(banner.id)}
                />
            )}
        </div>
    );
}
