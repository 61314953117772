import type { BrowserPlugin } from '@snowplow/browser-tracker-core';
import { buildStructEvent, PayloadBuilder } from '@snowplow/tracker-core';
import { AI_ASSISTANT, AssistantEvent, HELP_CENTER_SEARCH } from '../../events';

export function HelpCenterPlugin(): BrowserPlugin {
    return {
        activateBrowserPlugin: (tracker) => {
            const handleEvent: EventListener = (event) => {
                const payloadBuilder =
                    getPayloadBuilderFromHelpCenterEvent(event);

                if (payloadBuilder) {
                    tracker.core.track(payloadBuilder);
                }
            };

            window.addEventListener(AI_ASSISTANT, handleEvent);
            window.addEventListener(HELP_CENTER_SEARCH, handleEvent);
        },
    };
}

export function trackAssistantEvent(action: string) {
    window.dispatchEvent(
        new CustomEvent(AI_ASSISTANT, {
            detail: { action },
        }),
    );
}

export function getPayloadBuilderFromHelpCenterEvent(
    event: Event,
): PayloadBuilder | void {
    if (isAssistantEvent(event)) {
        return buildStructEvent({
            category: 'ai-assistant',
            action: event.detail.action,
        });
    }

    if (isHelpCenterEvent(event)) {
        return buildStructEvent({
            category: 'help-center',
            action: 'open',
        });
    }
}

function isHelpCenterEvent(event: Event) {
    return event.type === HELP_CENTER_SEARCH;
}

function isAssistantEvent(event: Event): event is AssistantEvent {
    return event.type === AI_ASSISTANT;
}
