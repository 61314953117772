import { BrowserPlugin } from '@snowplow/browser-tracker-core';
import {
    buildFormFocusOrChange,
    FormFocusOrChangeEvent,
    PayloadBuilder,
} from '@snowplow/tracker-core';
import { getClasses, getElementId, getFormId } from '../utils';

type DropdownChangeEvent = CustomEvent<{ value: string | undefined }>;

export function TripletexDropdownReduxFormPlugin(): BrowserPlugin {
    return {
        activateBrowserPlugin: (tracker) => {
            const handleChange: EventListener = (event) => {
                const payloadBuilder = getPayloadBuilderFromChangeEvent(event);

                if (payloadBuilder) {
                    tracker.core.track(payloadBuilder);
                }
            };

            document.addEventListener('txr-dropdown:change', handleChange);
        },
    };
}

export function getPayloadBuilderFromChangeEvent(
    event: Event,
): PayloadBuilder | void {
    if (!isDropdownChangeEvent(event)) {
        return;
    }

    const select = event.target;

    if (isReduxDropdown(select)) {
        return buildFormFocusOrChange(
            getReduxDropdownChangeEvent(select, event.detail.value),
        );
    }
}

export function getReduxDropdownChangeEvent(
    select: HTMLDivElement,
    value: string | undefined,
): FormFocusOrChangeEvent {
    return {
        schema: 'change_form',
        formId: getFormId(select),
        elementId: getElementId(select),
        nodeName: 'SELECT',
        elementClasses: getClasses(select),
        value: value ?? null,
    };
}

function isDropdownChangeEvent(event: Event): event is DropdownChangeEvent {
    return event.type === 'txr-dropdown:change';
}

function isReduxDropdown(
    element: EventTarget | null,
): element is HTMLDivElement {
    return (
        element instanceof HTMLDivElement &&
        element.classList.contains('txr-dropdown__field')
    );
}
