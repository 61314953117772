import { MenuItemDTO } from './types';

// Check if the current menu item is active, or if one of its descendants is active
export function isMenuItemActive(
    item: MenuItemDTO,
    currentPath: string,
): boolean {
    const itemURL = new URL(item.url, window.location.origin);
    const currentURL = new URL(currentPath, window.location.origin);

    // Check that the pathname of the menu item URL/definition is the same as the current URL
    const isPathnameMatching = itemURL.pathname === currentURL.pathname;

    // Check that every query parameter that is present in the menu item URL/definition
    // has the same value in the current URL. All other query parameters in the current URL
    // is ignored.
    const isQueryParamsMatching = Array.from(itemURL.searchParams.keys()).every(
        (key) => {
            return (
                currentURL.searchParams.get(key) ===
                itemURL.searchParams.get(key)
            );
        },
    );

    // Edge case: on empty URL, no menu item should be active
    if (currentURL.pathname === '/') {
        return false;
    }

    return (
        (isPathnameMatching && isQueryParamsMatching) ||
        item.subMenuItems.some((subItem) =>
            isMenuItemActive(subItem, currentPath),
        )
    );
}

export function getCleanUrl(originalUrl: string): string {
    const cleanUrl = new URL(originalUrl, window.location.origin);

    if (isYearEndMenuUrl(cleanUrl.pathname)) {
        cleanUrl.searchParams.delete('year');
        cleanUrl.searchParams.delete('currentStep');
    }

    if (isTravelsAndExpensesUrl(cleanUrl.pathname)) {
        cleanUrl.searchParams.delete('periodStart');
        cleanUrl.searchParams.delete('periodEnd');
    }

    if (isProjectOverviewUrl(cleanUrl.pathname)) {
        cleanUrl.searchParams.delete('isReadyForInvoicing');
    }

    return cleanUrl.pathname + cleanUrl.search;
}

/**
 * Year end menu items are React pages that immediately redirect to a new URL
 * containing the year and current step. For the purposes of determining which
 * menu item is active, we want to ignore these parameters.
 */
function isYearEndMenuUrl(pathname: string): boolean {
    return pathname.startsWith('/execute/yearEnd');
}

/**
 * Travels and expenses menu items are React pages that immediately redirect to a new URL
 * containing start and end period parameters. For the purposes of determining which
 * menu item is active, we want to ignore these parameters.
 */
function isTravelsAndExpensesUrl(pathname: string): boolean {
    return pathname.startsWith('/execute/travelsAndExpenses');
}

/**
 * The project overview page is a React page that immediately redirects to a new URL
 * containing the isReadyForInvoicing parameter. For the purposes of determining which
 * menu item is active, we want to ignore this parameter.
 */
function isProjectOverviewUrl(pathname: string): boolean {
    return pathname.startsWith('/execute/projectOverviewPage');
}
