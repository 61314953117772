import { ResponseWrapper, fetcher } from '@tlx/astro-shared';

export async function fetchUserConsent(): Promise<boolean> {
    try {
        const data = await fetcher<ResponseWrapper<boolean>>(
            '/v2/snowplow/userSnowplowConsent',
        );

        return data.value;
    } catch (error) {
        return false;
    }
}
