export type OrderOfferDTO = {
    id: number;
    number: string;
    offerDate: string;
    customer?: {
        name: string;
    };
};

export const orderOfferCategory = 'order/offer';
