import {
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalLayout,
    ModalTitle,
} from '@tlx/atlas';
import { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { OPEN_KEYBOARD_SHORTCUTS } from '../../events';
import { useKeyboardShortcut } from '../../hooks/useKeyboardShortcut';
import { keyboardCommands } from '../../utils/keyboardCommands';
import {
    AdvancedVoucherKeyboardShortcuts,
    DateKeyboardShortcuts,
    DropdownMenuKeyboardShortcuts,
    GlobalKeyboardShortcuts,
    InboxVoucherKeyboardShortcuts,
    PeriodKeyboardShortcuts,
    TravelAndExpenseKeyboardShortcuts,
    VoucherQueueKeyboardShortcuts,
} from './GlobalKeyboardShortcutsDictionary';

export function KeyboardShortcutsDialog() {
    const [isOpen, setIsOpen] = useState(false);

    useKeyboardShortcut(keyboardCommands.toggleKeyboardShortcutsModal, () => {
        setIsOpen(true);
    });

    useGlobalEventListener(setIsOpen);

    return (
        <KeyboardShortcutsModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
        />
    );
}

export function KeyboardShortcutsModal({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: () => void;
}) {
    const { formatMessage } = useIntl();

    return (
        <Modal open={isOpen} onClose={onClose}>
            <ModalLayout>
                <ModalTitle>
                    {formatMessage({ id: 'text_keyboard_shortcuts' })}
                </ModalTitle>
                <ModalCloseButton></ModalCloseButton>
                <ModalContent>
                    <div className="atl-flex atl-gap-32 atl-flex-wrap">
                        <div className="atl-flex atl-flex-col atl-gap-32">
                            {/* Global Keys */}
                            <KeyboardShortcutsSection
                                title={formatMessage({
                                    id: GlobalKeyboardShortcuts.id,
                                })}
                            >
                                <dl className="tlx-keyboard-shortcuts-dialog__definition-list">
                                    {GlobalKeyboardShortcuts.shortcuts.map(
                                        (shortcut) => (
                                            <Fragment key={shortcut.id}>
                                                <KeyboardShortcut>
                                                    {shortcut.keys.map(
                                                        (k, idx) => (
                                                            <Key key={idx}>
                                                                {k}
                                                            </Key>
                                                        ),
                                                    )}
                                                </KeyboardShortcut>
                                                <KeyboardShortcutDescription>
                                                    {formatMessage({
                                                        id: shortcut.id,
                                                    })}
                                                </KeyboardShortcutDescription>
                                            </Fragment>
                                        ),
                                    )}
                                </dl>
                            </KeyboardShortcutsSection>
                        </div>

                        <div className="atl-flex atl-flex-col atl-gap-32">
                            {/* Inbox Voucher Keys */}
                            <KeyboardShortcutsSection
                                title={formatMessage({
                                    id: InboxVoucherKeyboardShortcuts.id,
                                })}
                            >
                                <dl className="tlx-keyboard-shortcuts-dialog__definition-list">
                                    {InboxVoucherKeyboardShortcuts.shortcuts.map(
                                        (shortcut) => (
                                            <Fragment key={shortcut.id}>
                                                <KeyboardShortcut>
                                                    {shortcut.keys.map(
                                                        (k, idx) => (
                                                            <Key key={idx}>
                                                                {k}
                                                            </Key>
                                                        ),
                                                    )}
                                                </KeyboardShortcut>
                                                <KeyboardShortcutDescription>
                                                    {formatMessage({
                                                        id: shortcut.id,
                                                    })}
                                                </KeyboardShortcutDescription>
                                            </Fragment>
                                        ),
                                    )}
                                </dl>
                            </KeyboardShortcutsSection>
                            {/* Advanced Voucher Keys */}
                            <KeyboardShortcutsSection
                                title={formatMessage({
                                    id: AdvancedVoucherKeyboardShortcuts.id,
                                })}
                            >
                                <dl className="tlx-keyboard-shortcuts-dialog__definition-list">
                                    {AdvancedVoucherKeyboardShortcuts.shortcuts.map(
                                        (shortcut) => (
                                            <Fragment key={shortcut.id}>
                                                <KeyboardShortcut>
                                                    {shortcut.keys.map(
                                                        (k, idx) => (
                                                            <Key key={idx}>
                                                                {k}
                                                            </Key>
                                                        ),
                                                    )}
                                                </KeyboardShortcut>
                                                <KeyboardShortcutDescription>
                                                    {formatMessage({
                                                        id: shortcut.id,
                                                    })}
                                                </KeyboardShortcutDescription>
                                            </Fragment>
                                        ),
                                    )}
                                </dl>
                            </KeyboardShortcutsSection>
                        </div>

                        <div className="atl-flex atl-flex-col atl-gap-32">
                            {/* Voucher Queue Keys */}
                            <KeyboardShortcutsSection
                                title={formatMessage({
                                    id: VoucherQueueKeyboardShortcuts.id,
                                })}
                            >
                                <dl className="tlx-keyboard-shortcuts-dialog__definition-list">
                                    {VoucherQueueKeyboardShortcuts.shortcuts.map(
                                        (shortcut) => (
                                            <Fragment key={shortcut.id}>
                                                <KeyboardShortcut>
                                                    {shortcut.keys.map(
                                                        (k, idx) => (
                                                            <Key key={idx}>
                                                                {k}
                                                            </Key>
                                                        ),
                                                    )}
                                                </KeyboardShortcut>
                                                <KeyboardShortcutDescription>
                                                    {formatMessage({
                                                        id: shortcut.id,
                                                    })}
                                                </KeyboardShortcutDescription>
                                            </Fragment>
                                        ),
                                    )}
                                </dl>
                            </KeyboardShortcutsSection>

                            {/* Period Keys */}
                            <KeyboardShortcutsSection
                                title={formatMessage({
                                    id: PeriodKeyboardShortcuts.id,
                                })}
                            >
                                <dl className="tlx-keyboard-shortcuts-dialog__definition-list">
                                    {PeriodKeyboardShortcuts.shortcuts.map(
                                        (shortcut) => (
                                            <Fragment key={shortcut.id}>
                                                <KeyboardShortcut>
                                                    {shortcut.keys.map(
                                                        (k, idx) => (
                                                            <Key key={idx}>
                                                                {k}
                                                            </Key>
                                                        ),
                                                    )}
                                                </KeyboardShortcut>
                                                <KeyboardShortcutDescription>
                                                    {formatMessage({
                                                        id: shortcut.id,
                                                    })}
                                                </KeyboardShortcutDescription>
                                            </Fragment>
                                        ),
                                    )}
                                </dl>
                            </KeyboardShortcutsSection>
                        </div>

                        <div className="atl-flex atl-flex-col atl-gap-32">
                            {/* Travel and Expense Keys */}
                            <KeyboardShortcutsSection
                                title={formatMessage({
                                    id: TravelAndExpenseKeyboardShortcuts.id,
                                })}
                            >
                                <dl className="tlx-keyboard-shortcuts-dialog__definition-list">
                                    {TravelAndExpenseKeyboardShortcuts.shortcuts.map(
                                        (shortcut) => (
                                            <Fragment key={shortcut.id}>
                                                <KeyboardShortcut>
                                                    {shortcut.keys.map(
                                                        (k, idx) => (
                                                            <Key key={idx}>
                                                                {k}
                                                            </Key>
                                                        ),
                                                    )}
                                                </KeyboardShortcut>
                                                <KeyboardShortcutDescription>
                                                    {formatMessage({
                                                        id: shortcut.id,
                                                    })}
                                                </KeyboardShortcutDescription>
                                            </Fragment>
                                        ),
                                    )}
                                </dl>
                            </KeyboardShortcutsSection>

                            {/* Date Keys */}
                            <KeyboardShortcutsSection
                                title={formatMessage({
                                    id: DateKeyboardShortcuts.id,
                                })}
                            >
                                <dl className="tlx-keyboard-shortcuts-dialog__definition-list">
                                    {DateKeyboardShortcuts.shortcuts.map(
                                        (shortcut) => (
                                            <Fragment key={shortcut.id}>
                                                <KeyboardShortcut>
                                                    {shortcut.keys.map(
                                                        (k, idx) => (
                                                            <Key key={idx}>
                                                                {k}
                                                            </Key>
                                                        ),
                                                    )}
                                                </KeyboardShortcut>
                                                <KeyboardShortcutDescription>
                                                    {formatMessage({
                                                        id: shortcut.id,
                                                    })}
                                                </KeyboardShortcutDescription>
                                            </Fragment>
                                        ),
                                    )}
                                </dl>
                            </KeyboardShortcutsSection>

                            {/* Dropdown Menu Keys */}
                            <KeyboardShortcutsSection
                                title={formatMessage({
                                    id: DropdownMenuKeyboardShortcuts.id,
                                })}
                            >
                                <dl className="tlx-keyboard-shortcuts-dialog__definition-list">
                                    {DropdownMenuKeyboardShortcuts.shortcuts.map(
                                        (shortcut) => (
                                            <Fragment key={shortcut.id}>
                                                <KeyboardShortcut>
                                                    {shortcut.keys.map(
                                                        (k, idx) => (
                                                            <Key key={idx}>
                                                                {k}
                                                            </Key>
                                                        ),
                                                    )}
                                                </KeyboardShortcut>
                                                <KeyboardShortcutDescription>
                                                    {formatMessage({
                                                        id: shortcut.id,
                                                    })}
                                                </KeyboardShortcutDescription>
                                            </Fragment>
                                        ),
                                    )}
                                </dl>
                            </KeyboardShortcutsSection>
                        </div>
                    </div>
                </ModalContent>
            </ModalLayout>
        </Modal>
    );
}

export function KeyboardShortcutsSection({
    title,
    children,
}: {
    title: string;
    children: React.ReactNode;
}) {
    return (
        <section className="atl-text-base">
            <h3 className="atl-text-xl atl-font-medium atl-text-center">
                {title}
            </h3>
            {children}
        </section>
    );
}

export function KeyboardShortcut({ children }: { children: React.ReactNode }) {
    return (
        <dt className="tlx-keyboard-shortcuts-dialog__definition-list__dt atl-inline-flex atl-gap-4 atl-justify-end atl-items-center atl-ml-auto">
            {children}
        </dt>
    );
}

export function Key({
    title,
    children,
}: {
    title?: string;
    children: React.ReactNode;
}) {
    return (
        <kbd
            className="atl-bg-grey-10 atl-rounded atl-py-0 atl-px-8 tlx-keyboard-shortcuts-dialog__definition-list__kbd"
            title={title}
        >
            {children}
        </kbd>
    );
}

export function KeyboardShortcutDescription({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <dd className="tlx-keyboard-shortcuts-dialog__definition-list__dd">
            {children}
        </dd>
    );
}

function useGlobalEventListener(setIsOpen: (isOpen: boolean) => void) {
    useEffect(() => {
        const handleOpenEvent = () => setIsOpen(true);

        window.addEventListener(OPEN_KEYBOARD_SHORTCUTS, handleOpenEvent);

        return () => {
            window.removeEventListener(
                OPEN_KEYBOARD_SHORTCUTS,
                handleOpenEvent,
            );
        };
    }, [setIsOpen]);
}
