import { useIntl } from 'react-intl';

export function useResultTitleTranslation(displayKey: string) {
    const { formatMessage } = useIntl();
    const overviewLinkText = `${formatMessage({
        id: 'text_go_to_x',
    })} ${formatMessage({ id: displayKey }).toLowerCase()}`;

    const title = formatMessage({ id: displayKey });
    return { title, overviewLinkText };
}
