// Use the URLSearchParams API.
// Adds a context ID to the url.
// For URLs without a hostname, use the current window hostname.
// It should not replace and already existing context ID.
// If the url is pointing to an external site (e.g. https://www.google.com),
// we should not add a context id.
export function addContextId(url: string, contextId: string) {
    const urlObj = new URL(url, window.location.origin);
    if (
        urlObj.origin === window.location.origin &&
        !urlObj.searchParams.has('contextId')
    ) {
        urlObj.searchParams.set('contextId', contextId);
    }
    return urlObj.toString();
}
