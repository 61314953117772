import classNames from 'classnames';

export function Layout({
    sidebarExpanded,
    children,
}: {
    sidebarExpanded?: boolean;
    children: React.ReactNode;
}) {
    return (
        <div
            className={classNames('tlx-layout', {
                'tlx-layout--sidebar-expanded': sidebarExpanded,
            })}
            data-testid="layout"
        >
            {children}
        </div>
    );
}

export function LayoutTopbar({ children }: { children: React.ReactNode }) {
    return <div className="tlx-layout__topbar atl-root">{children}</div>;
}

export function LayoutGlobalMessages({
    children,
}: {
    children?: React.ReactNode;
}) {
    return (
        <div className="tlx-layout__global-messages atl-root">{children}</div>
    );
}

export function LayoutCallouts({ children }: { children?: React.ReactNode }) {
    return (
        <div className="tlx-layout__callouts atl-root" data-testid="callouts">
            {children}
        </div>
    );
}

export function LayoutMain({ children }: { children: React.ReactNode }) {
    return <div className="tlx-layout__main">{children}</div>;
}

export function LayoutScrollContainer({
    children,
}: {
    children: React.ReactNode;
}) {
    return <div className="tlx-layout__scroll-container">{children}</div>;
}

export function LayoutSidebar({ children }: { children: React.ReactNode }) {
    return (
        <div
            className="tlx-layout__sidebar tlx-layout-hover-scrollbar atl-root"
            data-testid="desktop-menu"
        >
            {children}
        </div>
    );
}

export function LayoutContent({ children }: { children: React.ReactNode }) {
    return <div className="tlx-layout__content">{children}</div>;
}

export function LayoutActionBar({ children }: { children?: React.ReactNode }) {
    return <div className="tlx-layout__actionbar">{children}</div>;
}
