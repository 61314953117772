import { UseSearchCategoryReturn } from '../types';

import {
    GoBackButton,
    LoadMoreButton,
    SearchResult,
    SearchResultItem,
    SearchResultItemField,
    SearchResultItemIcon,
    SearchResultItemLink,
    SearchResultItems,
    SearchResultItemTitle,
    SearchResultSkeleton,
    SearchResultTitle,
    SearchResultTitleLink,
} from '../SearchContainer';
import { useResultTitleTranslation } from '../useResultTitleTranslation';
import { getUrlWithContext } from '../util';
import { productCategory, ProductDTO } from './types';

export function ProductResult(props: UseSearchCategoryReturn<ProductDTO>) {
    const translation = useResultTitleTranslation('text_product');

    const { data, hasMore, loading, loadMore, goBack } = props;

    const hasData = data !== undefined && data.length > 0;
    if (!hasData && !loading) {
        return null;
    }

    return (
        <SearchResult category={productCategory}>
            <SearchResultTitle>
                <div className="atl-flex atl-items-center">
                    <GoBackButton onGoBack={goBack} />
                    {translation.title}
                </div>
                <ProductSearchResultTitleLink />
            </SearchResultTitle>
            <SearchResultItems>
                {data?.map((item: ProductDTO) => (
                    <ProductResultItem key={item.id} data={item} />
                ))}
                {loading && <SearchResultSkeleton />}
                {!loading && hasMore && (
                    <LoadMoreButton
                        category={productCategory}
                        data-testid={`search-show-more`}
                        onLoadMore={loadMore}
                    />
                )}
            </SearchResultItems>
        </SearchResult>
    );
}

export function ProductResultItem({ data }: { data: ProductDTO }) {
    const url = getUrlWithContext('/execute/productMenu', {
        productId: data.id.toString(),
    });
    return (
        <SearchResultItem>
            <SearchResultItemIcon className="tlx-search__result-product-icon">
                folder
            </SearchResultItemIcon>
            <SearchResultItemLink href={url} category={productCategory}>
                <SearchResultItemTitle>{data.name}</SearchResultItemTitle>
                <SearchResultItemField translationKey={'text_product_number'}>
                    {data.number}
                </SearchResultItemField>
            </SearchResultItemLink>
        </SearchResultItem>
    );
}

function ProductSearchResultTitleLink() {
    const translation = useResultTitleTranslation('text_product');
    const url = '/execute/productOverview2';

    return (
        <SearchResultTitleLink href={getUrlWithContext(url)}>
            {translation.overviewLinkText}
        </SearchResultTitleLink>
    );
}
