import { Icon, TestableProps } from '@tlx/atlas';
import classNames from 'classnames';
import React, { forwardRef, ReactNode, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { CompanyChooser } from '../CompanyChooser/CompanyChooser';
import { UseCompanyChooserReturn } from '../CompanyChooser/useCompanyChooser';
import { UseProfileReturn } from '../Profile/useProfile';
import { Profile, ProfileSkeleton } from '../Profile/Profile';
import { offset, shift, useFloating } from '@floating-ui/react';
import { useComposedRefs } from './useComposedRefs';

export function TopbarLogo({ href }: { href?: string }) {
    return (
        <a href={href ?? '/'} className="tlx-topbar__logo">
            <img
                alt="Tripletex"
                data-testid="header-logo"
                src="data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='90.2' height='22' viewBox='0 0 90.2 22'%3e%3cpath d='M55.875,10.476a6.813,6.813,0,0,0-3.337-4.984,6.482,6.482,0,0,0-5-.536A6.6,6.6,0,1,0,51.26,17.627h0a6.227,6.227,0,0,0,3.107-2.014A7.049,7.049,0,0,0,55.926,11.9l-2.953.854a3.282,3.282,0,0,1-2.416,2.447,3.44,3.44,0,0,1-4.322-1.963Zm-10.241.573a3.63,3.63,0,0,1,6.79-1.95Z' fill='%232e384d' /%3e%3cpath d='M77.406,10.476a6.813,6.813,0,0,0-3.337-4.984,6.482,6.482,0,0,0-5-.536A6.6,6.6,0,1,0,72.8,17.627a6.227,6.227,0,0,0,3.107-2.014A7.041,7.041,0,0,0,77.47,11.9l-2.953.854A3.281,3.281,0,0,1,72.1,15.205a3.44,3.44,0,0,1-4.322-1.963Zm-10.241.573A3.63,3.63,0,0,1,73.954,9.1Z' fill='%232e384d' /%3e%3cpath d='M77.33,17.647h3.605l2.826-4.27,2.8,4.27H90.2L85.546,11.1l4.181-5.877H86.185L83.759,9.043,81.332,5.219H77.74L81.93,11.1Z' fill='%232e384d' /%3e%3cpath d='M35.648,11.33a6.474,6.474,0,0,1-6.367,6.58c-.1,0-.2,0-.307,0A6.782,6.782,0,0,1,25.3,16.9V22H22.312V11.892c-.013-.192-.026-.383-.026-.574v-.026a6.681,6.681,0,0,1,13.362.039m-2.992-.025a3.69,3.69,0,0,0-7.376-.235l0,.069h.014v.624a3.691,3.691,0,0,0,7.364-.458' fill='%232e384d' /%3e%3cpath d='M40.437.762H37.68V17.524h2.992V14.872h0V.762Z' fill='%232e384d' /%3e%3cpath d='M16.549,5.1h2.977V17.52H16.549Zm1.433-1.8a1.649,1.649,0,1,0-1.654-1.649h0A1.652,1.652,0,0,0,17.982,3.3' fill='%232e384d' /%3e%3cpath d='M9.807,11.225h0a3.726,3.726,0,0,1,3.734-3.7,4.4,4.4,0,0,1,.716.064V4.776a6.757,6.757,0,0,0-7.443,5.985q-.033.309-.037.619v.026c0,.191.013.382.025.574v5.583H9.819Z' fill='%232e384d' /%3e%3cpath d='M6.073,4.611H2.978V.774H0V17.511H2.978V7.591h3.1Z' fill='%232e384d' /%3e%3cpath d='M63.905,4.611h-3.1V.774H57.832V17.511h2.977V7.591h3.1Z' fill='%232e384d' /%3e%3c/svg%3e"
            />
        </a>
    );
}

export function TopbarContainer({ children }: { children: React.ReactNode }) {
    return <div className="tlx-topbar">{children}</div>;
}

export function TopbarBurgerButton({
    expanded,
    onClick,
}: {
    expanded?: boolean;
    onClick?: React.MouseEventHandler;
}) {
    const { formatMessage } = useIntl();

    return (
        <button
            type="button"
            className="tlx-topbar__burger"
            aria-label={formatMessage({
                id: expanded ? 'text_collapse' : 'text_expand',
            })}
            aria-controls="tlx-sidebar"
            aria-expanded={expanded}
            data-testid="topbar-burger-button"
            onClick={onClick}
        >
            <Icon className="tlx-topbar__burger__icon">menu</Icon>
        </button>
    );
}

export function TopbarButtons({ children }: { children: React.ReactNode }) {
    return <div className="tlx-topbar__buttons">{children}</div>;
}

function useFloatingLabel() {
    return useFloating({
        placement: 'bottom',
        middleware: [shift({ padding: 8 }), offset(4)],
    });
}

export const TopbarButton = forwardRef(function TopbarButton(
    {
        label,
        icon,
        notify,
        onClick,
        onBlur,
        hidden,
        className,
        'data-testid': testId,
    }: {
        label: string;
        icon: string;
        notify?: boolean;
        onClick?: React.MouseEventHandler;
        onBlur?: React.FocusEventHandler;
        hidden?: boolean;
        className?: string;
        ['data-testid']: string;
    } & TestableProps,
    ref: React.ForwardedRef<HTMLButtonElement>,
) {
    const { update, refs, floatingStyles } = useFloatingLabel();

    useEffect(() => {
        update();
    }, [label, update]);

    const buttonRef = useComposedRefs(ref, refs.setReference);

    return (
        <button
            type="button"
            data-testid={testId}
            className={classNames(
                'tlx-topbar__button',
                hidden ? 'tlx-topbar__button--hidden' : '',
                notify ? 'tlx-topbar__button--shake' : '',
                className,
            )}
            aria-label={label}
            onClick={onClick}
            onBlur={onBlur}
            ref={buttonRef}
        >
            <Icon className="tlx-topbar__button__icon">{icon}</Icon>
            <div
                ref={refs.setFloating}
                style={floatingStyles}
                className="tlx-topbar__button__label"
                // hide from aria; the button already has an aria-label.
                aria-hidden="true"
            >
                {label}
            </div>
        </button>
    );
});

export function TopbarSearch({ children }: { children?: ReactNode }) {
    return <div className="tlx-topbar__search">{children}</div>;
}

export function TopbarCompanyChooser({
    companies,
    currentCompany,
    hasAccessToMultipleCompanies,
    open,
    setOpen,
}: UseCompanyChooserReturn) {
    if (!currentCompany) {
        return null;
    }

    return (
        <div className="tlx-topbar__company-chooser">
            <CompanyChooser
                companies={companies}
                currentCompany={currentCompany}
                disabled={!hasAccessToMultipleCompanies}
                open={open}
                setOpen={setOpen}
            />
        </div>
    );
}

export function TopBarProfile({ profile, open, setOpen }: UseProfileReturn) {
    if (profile === null) {
        return <ProfileSkeleton />;
    }

    return <Profile profile={profile} open={open} setOpen={setOpen} />;
}

export function DuplicateTabButton() {
    const { formatMessage } = useIntl();
    const { refs, floatingStyles } = useFloatingLabel();

    const label = formatMessage({
        id: 'text_duplicate_tab',
    });

    return (
        <a
            ref={refs.setReference}
            href="#"
            target="_blank"
            className="tlx-topbar__button tlx-topbar__button--duplicate-tab tlx-topbar__button--hide-on-narrow-screen"
            aria-label={label}
            data-testid="duplicate-tab-button"
        >
            <Icon className="tlx-topbar__button__icon">open_in_new</Icon>
            <div
                ref={refs.setFloating}
                style={floatingStyles}
                className="tlx-topbar__button__label"
                // hide from aria; the button already has an aria-label.
                aria-hidden="true"
            >
                {label}
            </div>
        </a>
    );
}
