import { SupportedLocale } from '@tlx/intl';

import en from '../locale/en.json';
import no from '../locale/no.json';

// Allows us to get type safe access to the current locale from useIntl
declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace FormatjsIntl {
        interface IntlConfig {
            locale: SupportedLocale;
        }
    }
}

export type UseIntlMessagesReturn = Record<string, string> | undefined;

export function useIntlMessages(
    locale: SupportedLocale,
): UseIntlMessagesReturn {
    return locale === 'en' ? en : no;
}
