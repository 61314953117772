import { supportedCharacters } from './getCompanyInitials';

export function getCompanyColor(name: string): string {
    const firstCharacter = name.charAt(0);
    const degrees = Math.max(
        0,
        Math.round(
            (360 / supportedCharacters.length) *
                supportedCharacters.indexOf(firstCharacter),
        ),
    );

    return `hsl(${degrees} 37% 87%)`;
}
