import { ProductDTO } from './types';
import { useApiSearch } from '../useSearch';

const endpoint = `/v2/product/query`;
const searchParams = new URLSearchParams({
    fields: 'id,name,number',
    isSupplierProduct: 'false',
    isInactive: 'false',
});

export function useProductSearch(query: string) {
    return useApiSearch<ProductDTO>(endpoint, query, searchParams);
}
