import { useApiSearch } from '../useSearch';
import { CustomerDTO } from './types';

const endpoint = `/v2/customer/query`;
const searchParams = new URLSearchParams({
    fields: 'id,name,organizationNumber,customerNumber,category3(id)',
});

export function useCustomerSearch(query: string) {
    return useApiSearch<CustomerDTO>(endpoint, query, searchParams);
}
