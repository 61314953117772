import { UseFavoritesReturn } from '../Favorites/useFavorites';
import { TopbarButton } from './Topbar';
import { useIntl } from 'react-intl';
import {
    AddFavoriteModal,
    ModifyFavoriteModal,
} from '../Favorites/AddFavoriteModal';
import { forwardRef, useRef } from 'react';

const FavoriteTopbarButton = forwardRef(function FavoriteTopbarButton(
    {
        isCurrentFavorite,
        onClickAddFavorite,
        onClickModifyFavorite,
    }: {
        isCurrentFavorite: boolean;
        onClickAddFavorite: React.MouseEventHandler;
        onClickModifyFavorite: React.MouseEventHandler;
    },
    ref: React.Ref<HTMLButtonElement>,
) {
    const { formatMessage } = useIntl();

    const labelKey = isCurrentFavorite
        ? 'text_favourite'
        : 'text_add_to_favourites';

    const icon = isCurrentFavorite ? 'star' : 'star_border';

    function onClick(event: React.MouseEvent) {
        if (isCurrentFavorite) {
            onClickModifyFavorite(event);
        } else {
            onClickAddFavorite(event);
        }
    }

    return (
        <TopbarButton
            ref={ref}
            className="tlx-topbar__button--favorites"
            data-testid="header-favourite-button"
            label={formatMessage({ id: labelKey })}
            icon={icon}
            onClick={onClick}
        />
    );
});

export function FavoriteButton(props: UseFavoritesReturn) {
    const openerRef: React.RefObject<HTMLButtonElement> =
        useRef<HTMLButtonElement>(null);
    return (
        <>
            <FavoriteTopbarButton
                isCurrentFavorite={props.isCurrentFavorite.data === true}
                onClickAddFavorite={props.onClickAddFavorite}
                onClickModifyFavorite={props.onClickModifyFavorite}
                ref={openerRef}
            />
            <AddFavoriteModal
                open={props.isAddFavoriteModalShown}
                openerRef={openerRef}
                createFavorite={props.createFavorite}
                closeFavoriteModal={props.closeFavoriteModal}
            />
            {props.currentFavorite !== undefined && (
                <ModifyFavoriteModal
                    open={props.isModifyFavoriteModalShown}
                    openerRef={openerRef}
                    favorite={props.currentFavorite}
                    closeFavoriteModal={props.closeFavoriteModal}
                    updateFavorite={props.updateFavorite}
                    deleteFavorite={props.deleteFavorite}
                />
            )}
        </>
    );
}
