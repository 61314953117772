import {
    HTTPError,
    ListResponse,
    ResponseWrapper,
    createAPIRequest,
    createAPIResponse,
} from '@tlx/astro-shared';
import { BANNERS_UPDATE } from '../../events';
import { NotificationDTO } from './types';

type UnreadMessage = {
    batch: boolean;
    notificationId: number;
    unreadCount: number;
};

// See UnreadCountDTO.java
type UnreadCountDTO = {
    count: number;
    readCursor: number;
};

// See SpacesuitNotificationMeta.java
export type SpacesuitNotificationMeta = {
    sseUrl: string;
    clientName: string;
    notificationSettingsUrl: string;
};

type Events = {
    NEW_UNREAD_MESSAGE: UnreadMessage;
    MESSAGES_READ: number;
    BANNER_PING: { id: number };
};

function getRandomInt(min: number, max: number) {
    return Math.floor(Math.floor(max - min) * Math.random()) + min;
}

// Run a method with a random delay.
export function setTimeoutRandom(
    method: () => void,
    minimumTime: number,
    maximumTime: number,
) {
    setTimeout(method, getRandomInt(minimumTime, maximumTime));
}

async function getNotificationsMeta(): Promise<SpacesuitNotificationMeta> {
    const request = createAPIRequest('/v2/event/notification/spacesuit');
    const response = await fetch(request);
    const { value } = await createAPIResponse<
        ResponseWrapper<SpacesuitNotificationMeta>
    >(request, response);
    return value;
}

async function showBrowserNotification(notificationId: number) {
    if (!('Notification' in window) || Notification.permission !== 'granted') {
        return;
    }

    const notification = await fetchNotification(notificationId);

    if (!notification) {
        return;
    }

    const content: NotificationOptions = {
        tag: String(notification.id), // only show one notification per notificationId
        icon: '/favicon.ico',
        body: notification.message,
    };

    setTimeout(function () {
        const n = new Notification(notification.title, content);
        n.onclick = function () {
            window.focus();
            const navigateEvent = new CustomEvent('tlx:navigate', {
                detail: {
                    href: notification.link,
                },
                cancelable: true,
            });
            window.dispatchEvent(navigateEvent);
            if (!navigateEvent.defaultPrevented) {
                window.location.href = notification.link;
            }
        };
    });
}

async function fetchNotification(id: number): Promise<NotificationDTO | null> {
    try {
        const request = createAPIRequest('/v2/event/notification/' + id);
        const response = await fetch(request);
        const data = await createAPIResponse<ResponseWrapper<NotificationDTO>>(
            request,
            response,
        );

        return data.value;
    } catch (error) {
        // Ignore notifications that no longer exist
        if (error instanceof HTTPError && error.response.status === 404) {
            return null;
        }

        throw error;
    }
}

function dispatchCustomNotificationEvent<K extends keyof Events>(
    type: K,
    body: Events[K],
) {
    window.dispatchEvent(
        new CustomEvent('tlx:notification:custom', {
            detail: { type, body },
        }),
    );
}

export const notifications = (function () {
    let readCursor = 0;
    let lowestReadCursor = 0;

    const EVENT_NEW_UNREAD_MESSAGE = 'NEW_UNREAD_MESSAGE';
    function handleEventUnreadMessage(data: UnreadMessage) {
        const notificationId = data.notificationId;
        const unreadCount = data.unreadCount;
        const batch = data.batch;

        // "batch" means lots of clients get a notification from the backend at once.
        // We use a random timeout to prevent all these clients from talking to the API at the same time.
        if (batch) {
            const minimumTime = 0;
            const maximumTime = 300000;
            setTimeoutRandom(
                function () {
                    if (notificationId > readCursor) {
                        // only update cursor, set counts and show browser notification when the message is newer than the
                        // most recently shown notification, to prevent delayed messages overriding other notifications depending on order.
                        readCursor = notificationId;
                        setUnreadCount(unreadCount);
                        showBrowserNotification(notificationId);
                    }
                },
                minimumTime,
                maximumTime,
            );
        } else {
            if (notificationId > readCursor) {
                readCursor = notificationId;
                setUnreadCount(unreadCount);
            }
            // non-batched notifications will (in theory) never have notificationId <= readCursor, but it should still
            // make sense to show a browser notification as it would most likely have happened in the span of a few ms.
            showBrowserNotification(notificationId);
        }
    }

    const EVENT_MESSAGES_READ = 'MESSAGES_READ';
    function handleEventMessagesRead(data: number) {
        if (data > readCursor) {
            setUnreadCount(0);
            readCursor = data;
        }
    }

    const EVENT_BANNER_PING = 'BANNER_PING';
    function handleEventBannerPing() {
        document.dispatchEvent(new CustomEvent(BANNERS_UPDATE));
    }

    function setUnreadCount(unreadCount: number) {
        window.dispatchEvent(
            new CustomEvent('tlx:notification:count', {
                detail: {
                    count: unreadCount,
                },
            }),
        );
    }

    async function fetchCount() {
        const request = createAPIRequest('/v2/event/notification/>unreadCount');
        const response = await window.fetch(request);
        const { value: data } = await createAPIResponse<
            ResponseWrapper<UnreadCountDTO>
        >(request, response);

        if (data.count > 0) {
            setUnreadCount(data.count);
        }
        readCursor = data.readCursor;
    }

    async function updateHiddenCursor() {
        window.fetch(
            createAPIRequest(
                '/v2/event/notification/:updateHiddenCursor?cursor=' +
                    readCursor,
                {
                    method: 'PUT',
                },
            ),
        );
    }

    async function getMessagesFromServer(
        oldestId?: number,
    ): Promise<ListResponse<NotificationDTO>> {
        const params = new URLSearchParams({
            count: '10',
            hidden: 'false',
        });
        if (oldestId !== undefined) {
            params.set('beforeId', String(oldestId));
        }

        const request = createAPIRequest(
            '/v2/event/notification?' + params.toString(),
        );
        const response = await fetch(request);

        const data = await createAPIResponse<ListResponse<NotificationDTO>>(
            request,
            response,
        );
        for (let a = 0; a < data.values.length; a++) {
            readCursor = Math.max(readCursor, data.values[a].id);
            lowestReadCursor = Math.min(lowestReadCursor, data.values[a].id);
        }

        window.fetch(
            createAPIRequest(
                '/v2/event/notification/:updateReadCursor?cursor=' + readCursor,
                { method: 'PUT' },
            ),
        );

        return data;
    }

    async function init() {
        try {
            const { sseUrl } = await getNotificationsMeta();
            if (sseUrl !== '') {
                const eventSource = new EventSource(sseUrl, {
                    withCredentials: true, // Send CSRF and JSESSIONID cookies
                });

                eventSource.addEventListener('error', () => {
                    // Local SSE server is probably not running
                    if (sseUrl.includes('localhost')) {
                        eventSource.close();
                    }
                });

                eventSource.addEventListener(
                    'rabbit_event',
                    function (event: MessageEvent) {
                        const data = JSON.parse(event.data);
                        switch (data.type) {
                            case EVENT_NEW_UNREAD_MESSAGE:
                                handleEventUnreadMessage(data.body);
                                break;
                            case EVENT_MESSAGES_READ:
                                handleEventMessagesRead(data.body);
                                break;
                            case EVENT_BANNER_PING:
                                handleEventBannerPing();
                                break;
                            default:
                                dispatchCustomNotificationEvent(
                                    data.type,
                                    data.body,
                                );
                                break;
                        }
                    },
                );
            }

            fetchCount(); // fetch unread count immediately instead of waiting for connect in case RabbitMQ becomes unresponsive
        } catch (e) {
            console.error('Could not initialize rabbit', e);
        }
    }

    return {
        getMessagesFromServer: getMessagesFromServer,
        updateHiddenCursor: updateHiddenCursor,
        init: init,
    };
})();
