type VoucherType = {
    id: number;
};

export type VoucherDTO = {
    id: number;
    number: number;
    year: number;
    description: string;
    voucherType: VoucherType | null;
    urlDetails: string;
};

export const voucherCategory = 'voucher';
