import { useIntl } from 'react-intl';
import {
    GoBackButton,
    LoadMoreButton,
    SearchResult,
    SearchResultItem,
    SearchResultItemIcon,
    SearchResultItems,
    SearchResultItemTitle,
    SearchResultSkeleton,
    SearchResultTitle,
    SearchResultItemLink,
} from '../SearchContainer';
import { UseSearchCategoryReturn } from '../types';
import { zendeskHelpCategory, ZendeskHelpResultDTO } from './types';

export function ZendeskHelpResult(
    props: UseSearchCategoryReturn<ZendeskHelpResultDTO>,
) {
    const { formatMessage } = useIntl();
    const displayKey = 'text_help_and_support';
    const title = formatMessage({ id: displayKey });

    const { data, hasMore, loading, loadMore, goBack } = props;

    const hasData = data !== undefined && data.length > 0;
    if (!hasData && !loading) {
        return null;
    }

    return (
        <SearchResult category={zendeskHelpCategory}>
            <SearchResultTitle>
                <div className="atl-flex atl-items-center">
                    <GoBackButton onGoBack={goBack} />
                    {title}
                </div>
            </SearchResultTitle>
            <SearchResultItems>
                {data?.map((item: ZendeskHelpResultDTO) => (
                    <ZendeskHelpResultItem key={item.id} data={item} />
                ))}
                {loading && <SearchResultSkeleton />}
                {!loading && hasMore && (
                    <LoadMoreButton
                        data-testid={`search-show-more`}
                        onLoadMore={loadMore}
                        category={zendeskHelpCategory}
                    />
                )}
            </SearchResultItems>
        </SearchResult>
    );
}

export function ZendeskHelpResultItem({
    data,
}: {
    data: ZendeskHelpResultDTO;
}) {
    return (
        <SearchResultItem>
            <SearchResultItemIcon className="tlx-search__result-help-icon">
                help_outline
            </SearchResultItemIcon>
            <SearchResultItemLink
                target="_blank"
                rel="noopener"
                href={data.url}
                category={zendeskHelpCategory}
            >
                <SearchResultItemTitle>{data.title}</SearchResultItemTitle>
            </SearchResultItemLink>
        </SearchResultItem>
    );
}
