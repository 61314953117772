export type TravelExpenseDTO = {
    id: number;
    travelDetails?: {
        destination: string;
        departureDate: string;
        purpose: string;
    };
    employee?: {
        firstName: string;
        lastName: string;
    };
    number: number;
    amount: number;
};

export const travelExpenseCategory = 'travelExpense';
