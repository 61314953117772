export function createLink(href: string) {
    if (document.head.querySelector(`link[href="${href}"]`) !== null) {
        // This font has already been loaded
        return;
    }
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = href;
    document.head.appendChild(link);
}
