import type { BrowserPlugin } from '@snowplow/browser-tracker-core';
import {
    buildLinkClick,
    buildSelfDescribingEvent,
    LinkClickEvent,
    PayloadBuilder,
} from '@snowplow/tracker-core';
import { getClasses, getElementId, getEventTarget } from '../utils';
import { getButtonClickEvent } from './button';

export function LinkPlugin(): BrowserPlugin {
    return {
        activateBrowserPlugin: (tracker) => {
            const handleClick: EventListener = (event) => {
                const payloadBuilder = getPayloadBuilderFromClickEvent(event);

                if (payloadBuilder) {
                    tracker.core.track(payloadBuilder);
                }
            };

            document.addEventListener('click', handleClick, {
                capture: true,
            });
        },
    };
}

export function getPayloadBuilderFromClickEvent(
    event: Event,
): PayloadBuilder | void {
    // Some buttons in Tripletex are programmatically invoked
    if (!event.isTrusted) {
        return;
    }

    const link = getEventTarget(event)?.closest('a');

    if (link instanceof HTMLAnchorElement) {
        // Some buttons are wrapped in links, and we probably only want to track buttons
        if (isWrappedButton(link)) {
            return;
        }

        // And some links are buttons in disguise
        if (isButtonLink(link)) {
            return buildSelfDescribingEvent({
                event: getButtonClickEvent(link),
            });
        }

        return buildLinkClick(getLinkClickEvent(link));
    }
}

export function getLinkClickEvent(link: HTMLAnchorElement): LinkClickEvent {
    return {
        elementId: getElementId(link),
        elementClasses: getClasses(link),
        elementTarget: link.getAttribute('target') ?? undefined,
        targetUrl: link.href,
    };
}

function isWrappedButton(link: HTMLAnchorElement) {
    return link.firstElementChild instanceof HTMLButtonElement;
}

/**
 * <tlx:comments /> renders a link without an href.
 * <tlx:caves /> renders a link with an empty hash (#).
 *
 * @see CommentsTag.java
 * @see Caves.java
 */
function isButtonLink(link: HTMLAnchorElement) {
    return link.href === '' || link.href.endsWith('#');
}
