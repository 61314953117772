export type PurchaseOrderDTO = {
    id: number;
    number: string;
    supplier?: {
        name: string;
    };
    project?: {
        displayName: string;
    };
};

export const purchaseOrderCategory = 'purchaseOrder';
