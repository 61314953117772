import { ResponseWrapper, fetcher } from '@tlx/astro-shared';
import { useCallback, useEffect, useReducer, useRef } from 'react';
import useSWRImmutable from 'swr/immutable';
import { ZENDESK_USER_EVENT } from '../events';

type ChatMeta = {
    apiKey: string;
    name: string;
    email: string;
    locale: string;
    departments: string[];
    tags: string[];
};

type State = {
    isAvailable: boolean;
    isOpen: boolean;
    isChatting: boolean;
    unreadCount: number;
};

type Action =
    | { type: 'OPEN' }
    | { type: 'CLOSE' }
    | {
          type: 'CHAT_CONNECTED';
          isAvailable: boolean;
          isOpen: boolean;
          isChatting: boolean;
      }
    | { type: 'CHAT_ENDED' }
    | { type: 'CHAT_UNREAD_MESSAGES'; count: number };

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case 'OPEN':
            return {
                ...state,
                isOpen: true,
                isChatting: true,
                unreadCount: 0,
            };
        case 'CLOSE':
            return { ...state, isOpen: false };
        case 'CHAT_CONNECTED':
            return {
                ...state,
                isAvailable: action.isAvailable,
                isOpen: action.isOpen,
                isChatting: action.isChatting,
            };
        case 'CHAT_ENDED':
            return {
                ...state,
                isChatting: false,
                unreadCount: 0,
            };

        case 'CHAT_UNREAD_MESSAGES':
            return { ...state, unreadCount: action.count };
    }
}

export type UseZendeskReturn = {
    isAvailable: boolean;
    isOpen: boolean;
    isChatting: boolean;
    unreadCount: number;
    openChat(initialChat: string | null, tags: string[]): void;
};

const initialState: State = {
    isAvailable: false,
    isOpen: false,
    isChatting: false,
    unreadCount: 0,
};

export function useZendesk(): UseZendeskReturn {
    const [{ isAvailable, isOpen, isChatting, unreadCount }, dispatch] =
        useReducer(reducer, initialState);
    const meta = useZendeskChatMeta();
    const initialChatRef = useRef<string | null>(null);

    // Load script
    useEffect(() => {
        if (!meta) {
            return;
        }

        if (document.getElementById('ze-snippet')) {
            return;
        }

        const script = document.createElement('script');
        script.id = 'ze-snippet';
        script.src = `https://static.zdassets.com/ekr/snippet.js?key=${meta.apiKey}`;
        script.addEventListener('load', () => {
            window.zE?.('webWidget', 'setLocale', meta.locale);
            window.zE?.('webWidget', 'identify', {
                name: meta.name,
                email: meta.email,
            });

            // Add tags
            window.zE?.('webWidget', 'chat:addTags', meta.tags);

            // Hide default launcher (button)
            window.zE?.('webWidget', 'hide');

            // Handle connection
            window.zE?.('webWidget:on', 'chat:connected', () => {
                // console.debug('Zendesk:', 'Chat connected');

                const departments = getAvailableDepartments(meta.departments);

                window.zE?.(
                    'webWidget',
                    'updateSettings',
                    getSettings(departments),
                );

                const isChatting =
                    window.zE?.('webWidget:get', 'chat:isChatting') === true;
                const isMinimized = getMinimized();

                // Resume chat if ongoing and user has not explicitly minimized chat window
                if (isChatting && !isMinimized) {
                    window.zE?.('webWidget', 'open');
                    window.zE?.('webWidget', 'show');
                }

                dispatch({
                    type: 'CHAT_CONNECTED',
                    isAvailable: departments.length > 0,
                    isChatting,
                    isOpen: !isMinimized,
                });

                // Show widget (chat window) when open
                window.zE?.('webWidget:on', 'open', () => {
                    window.zE?.('webWidget', 'show');

                    setMinimized(false);
                    dispatch({ type: 'OPEN' });
                });

                // Hide default launcher (button) chat is minimized
                const handleClose = () => {
                    window.zE?.('webWidget', 'hide');

                    setMinimized(true);
                    dispatch({ type: 'CLOSE' });
                };

                window.zE?.('webWidget:on', 'close', handleClose);
                window.zE?.('webWidget:on', 'chat:popout', handleClose);

                // Update unread count
                window.zE?.('webWidget:on', 'chat:unreadMessages', (count) => {
                    dispatch({ type: 'CHAT_UNREAD_MESSAGES', count });
                });

                window.zE?.('webWidget:on', 'chat:start', () => {
                    const initialChat = initialChatRef.current;

                    if (initialChat !== null) {
                        window.zE?.('webWidget', 'chat:send', initialChat);
                    }
                });

                window.zE?.('webWidget:on', 'chat:end', () => {
                    dispatch({ type: 'CHAT_ENDED' });
                });
            });

            // Track user events
            window.zE?.('webWidget:on', 'userEvent', (event) => {
                window.dispatchEvent(
                    new CustomEvent<ZendeskUserEvent>(ZENDESK_USER_EVENT, {
                        detail: event,
                    }),
                );
            });
        });

        document.body.appendChild(script);
    }, [meta]);

    const openChat = useCallback(
        (initialChat: string | null, tags: string[]) => {
            initialChatRef.current = initialChat;

            if (tags.length > 0) {
                window.zE?.('webWidget', 'chat:addTags', tags);
            }

            window.zE?.('webWidget', 'open');
            window.zE?.('webWidget', 'show');
        },
        [],
    );

    return {
        isAvailable,
        isOpen,
        isChatting,
        unreadCount,
        openChat,
    };
}

function useZendeskChatMeta(): ChatMeta | undefined {
    const { data } = useSWRImmutable<ResponseWrapper<ChatMeta>>(
        '/v2/internal/zendesk-chat/meta',
        fetcher,
    );

    return data?.value;
}

const minimizedKey = 'spacesuit.chat.minimized';

function getMinimized(): boolean {
    try {
        return localStorage.getItem(minimizedKey) === 'true';
    } catch (error) {
        return false;
    }
}

function setMinimized(minimized: boolean): void {
    try {
        if (minimized) {
            localStorage.setItem(minimizedKey, 'true');
        } else {
            localStorage.removeItem(minimizedKey);
        }
    } catch (error) {
        return;
    }
}

function getAvailableDepartments(enabledDepartments: string[]): string[] {
    const departments: ZendeskDepartment[] =
        window.zE?.('webWidget:get', 'chat:departments') ?? [];

    return enabledDepartments.filter((departmentName) =>
        departments.some(
            (department) =>
                department.name === departmentName &&
                department.status === 'online',
        ),
    );
}

function getSettings(departments: string[]): ZendeskSettings {
    const atlasGrey100 = '#2e384d';
    const atlasBlue100 = '#0a41fa';

    // Pre-select department if there's only a single enabled department
    const select = departments.length === 1 ? departments[0] : undefined;

    return {
        webWidget: {
            offset: {
                horizontal: '16px',
            },
            chat: {
                departments: {
                    enabled: departments,
                    select,
                },
            },
            color: {
                articleLinks: atlasBlue100,
                button: atlasBlue100,
                header: atlasGrey100,
                resultLists: atlasBlue100,
            },
        },
    };
}
