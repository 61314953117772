import type { BrowserPlugin } from '@snowplow/browser-tracker-core';
import {
    buildFormFocusOrChange,
    FormFocusOrChangeEvent,
    PayloadBuilder,
} from '@snowplow/tracker-core';
import { getClasses, getElementId, getEventTarget, getFormId } from '../utils';

export function InputPlugin(): BrowserPlugin {
    return {
        activateBrowserPlugin: (tracker) => {
            const handleChange: EventListener = (event) => {
                const payloadBuilder = getPayloadBuilderFromChangeEvent(event);

                if (payloadBuilder) {
                    tracker.core.track(payloadBuilder);
                }
            };

            document.addEventListener('change', handleChange);
        },
    };
}

export function getPayloadBuilderFromChangeEvent(
    event: Event,
): PayloadBuilder | void {
    const input = getEventTarget(event);

    if (input instanceof HTMLInputElement) {
        if (isSearchInput(input)) {
            return;
        }

        return buildFormFocusOrChange(getInputFormChangeEvent(input));
    }
}

export function getInputFormChangeEvent(
    input: HTMLInputElement,
): FormFocusOrChangeEvent {
    return {
        schema: 'change_form',
        formId: getFormId(input),
        elementId: getElementId(input),
        nodeName: input.nodeName,
        type: getInputType(input),
        elementClasses: getClasses(input),
        value: getInputValue(input),
    };
}

export function getInputType(input: HTMLInputElement): string | null {
    // tlx:input date
    if (input.classList.contains('tlxDateField')) {
        return 'date';
    }

    return input.type;
}

// We don't return values for text inputs, because they might contain sensitive data.
export function getInputValue(input: HTMLInputElement): string | null {
    switch (input.type) {
        case 'checkbox':
            // We are using the specification default: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#value
            return input.checked ? 'on' : null;
        case 'radio':
            return input.checked ? input.value : null;
        default:
            return null;
    }
}

export function isSearchInput(input: HTMLInputElement): boolean {
    if (input.type === 'search') {
        return true;
    }

    // Atlas dropdown
    if (input.classList.contains('atl-dropdown__opener')) {
        return true;
    }

    // tlx:dropdown
    if (input.classList.contains('txr-dropdown__search-input')) {
        return true;
    }

    // tlx:caves
    if (input.classList.contains('caves-searchField')) {
        return true;
    }

    return false;
}
