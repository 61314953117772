import type { BrowserPlugin } from '@snowplow/browser-tracker-core';
import {
    buildFormFocusOrChange,
    FormFocusOrChangeEvent,
    PayloadBuilder,
} from '@snowplow/tracker-core';
import { getClasses, getElementId, getEventTarget, getFormId } from '../utils';

export function SelectPlugin(): BrowserPlugin {
    return {
        activateBrowserPlugin: (tracker) => {
            const handleChange: EventListener = (event) => {
                const payloadBuilder = getPayloadBuilderFromChangeEvent(event);

                if (payloadBuilder) {
                    tracker.core.track(payloadBuilder);
                }
            };

            document.addEventListener('change', handleChange);
        },
    };
}

export function getPayloadBuilderFromChangeEvent(
    event: Event,
): PayloadBuilder | void {
    const select = getEventTarget(event);

    if (select instanceof HTMLSelectElement) {
        return buildFormFocusOrChange(getSelectFormChangeEvent(select));
    }
}

export function getSelectFormChangeEvent(
    select: HTMLSelectElement,
): FormFocusOrChangeEvent {
    return {
        schema: 'change_form',
        formId: getFormId(select),
        elementId: getElementId(select),
        nodeName: select.nodeName,
        elementClasses: getClasses(select),
        value: getSelectValue(select),
    };
}

export function getSelectValue(select: HTMLSelectElement): string | null {
    return Array.from(select.selectedOptions)
        .map((option) => option.value)
        .join(',');
}
