import { ResponseWrapper, fetcher } from '@tlx/astro-shared';
import {
    Button,
    CheckboxGroup,
    Fieldset,
    Input,
    Legend,
    Modal,
    ModalContent,
    ModalFooter,
    ModalLayout,
    ModalTitle,
    RadioGroup,
} from '@tlx/atlas';
import classNames from 'classnames';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useSWR from 'swr';
import {
    signCompanyLicense,
    signMarketingConsent,
    signUserLicense,
} from './data';

export type License = {
    content: string;
};

type TermsOfServiceProps = {
    renderDialog: boolean;
    renderCompanyLicense: boolean;
    companyLicense: License;
    renderUserLicense: boolean;
    userLicense: License;
    askCanSign: boolean;
    askMarketingConsent: boolean;
};

type HandleSubmitTerms = (
    signCompany: boolean,
    signUser: boolean,
    agreeToMarketing: boolean,
) => Promise<void>;

export function TermsOfService() {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string>('');
    const { data } = useSWR<ResponseWrapper<TermsOfServiceProps>>(
        '/v2/termsofservice',
        fetcher,
    );

    const props = data?.value;

    const intl = useIntl();

    if (!props || !props.renderDialog || isSubmitted) {
        return null;
    }

    const handleSubmit: HandleSubmitTerms = async (
        signCompany,
        signUser,
        agreeToMarketing,
    ) => {
        if (isSubmitting) {
            // only allow the button to be clicked once
            return;
        }

        setIsSubmitting(true);
        try {
            if (signCompany) {
                await signCompanyLicense();
            }
            if (signUser) {
                await signUserLicense();
            }
            if (props.askMarketingConsent) {
                await signMarketingConsent(agreeToMarketing);
            }
            setIsSubmitted(true);
        } catch (error) {
            setSubmitError(
                intl.formatMessage({ id: 'text_help_center_no_data_large' }),
            );
        }
    };

    return (
        <TermsOfServiceView
            {...props}
            onSubmit={handleSubmit}
            submitError={submitError}
        />
    );
}

export function TermsOfServiceView({
    companyLicense,
    renderCompanyLicense,
    userLicense,
    renderUserLicense,
    askMarketingConsent,
    askCanSign,
    onSubmit,
    submitError,
}: TermsOfServiceProps & {
    onSubmit: HandleSubmitTerms;
    submitError?: string;
}) {
    const [termsChecked, setTermsChecked] = useState(false);
    const [marketingConsentChecked, setMarketingConsentChecked] =
        useState(false);
    const [canSign, setCanSign] = useState(false);

    const showCompanyTerms = renderCompanyLicense && (canSign || !askCanSign);
    const renderTerms = renderUserLicense || showCompanyTerms;

    const handleSubmit = async () => {
        const signUserTerms = termsChecked && renderUserLicense;
        const signCompanyTerms = termsChecked && showCompanyTerms;

        await onSubmit(
            signCompanyTerms,
            signUserTerms,
            marketingConsentChecked,
        );
    };

    return (
        <Modal className="tlx-terms-of-service" open={true}>
            <ModalLayout>
                <ModalTitle>
                    <FormattedMessage id="text_license" />
                </ModalTitle>
                <ModalContent>
                    <TermsOfServiceMessage
                        renderCompanyLicense={renderCompanyLicense}
                    />
                    {askCanSign && (
                        <AskCanSign canSign={canSign} setCanSign={setCanSign} />
                    )}
                    {renderTerms && (
                        <Terms
                            showCompanyTerms={showCompanyTerms}
                            termsChecked={termsChecked}
                            setTermsChecked={setTermsChecked}
                            userLicense={userLicense}
                            companyLicense={companyLicense}
                            renderUserLicense={renderUserLicense}
                        />
                    )}
                    {askMarketingConsent && (
                        <div
                            className={classNames({
                                'atl-pt-4': renderTerms,
                            })}
                        >
                            <MarketingConsentCheckbox
                                marketingConsentChecked={
                                    marketingConsentChecked
                                }
                                setMarketingConsentChecked={
                                    setMarketingConsentChecked
                                }
                            />
                        </div>
                    )}
                    {submitError && (
                        <div className="atl-text-red-100 atl-pt-24">
                            {submitError}
                        </div>
                    )}
                </ModalContent>
                <ModalFooter>
                    <Button
                        variant="primary"
                        data-testid="terms-of-service-ok-button"
                        disabled={
                            !termsChecked && (!askCanSign || renderUserLicense)
                        }
                        onClick={handleSubmit}
                    >
                        <FormattedMessage id="text_complete2" />
                    </Button>
                </ModalFooter>
            </ModalLayout>
        </Modal>
    );
}

function MarketingConsentCheckbox({
    marketingConsentChecked,
    setMarketingConsentChecked,
}: {
    marketingConsentChecked: boolean;
    setMarketingConsentChecked: (value: boolean) => void;
}) {
    return (
        <Fieldset>
            <CheckboxGroup>
                <Input
                    type="checkbox"
                    id="marketingConsent"
                    data-testid="terms-of-service-marketing-consent-checkbox"
                    checked={marketingConsentChecked}
                    onChange={() =>
                        setMarketingConsentChecked(!marketingConsentChecked)
                    }
                />
                <label htmlFor="marketingConsent">
                    <FormattedMessage id="text_marketing_consent" />
                </label>
            </CheckboxGroup>
        </Fieldset>
    );
}

function Terms({
    showCompanyTerms,
    termsChecked,
    setTermsChecked,
    userLicense,
    companyLicense,
    renderUserLicense,
}: {
    showCompanyTerms: boolean;
    termsChecked: boolean;
    setTermsChecked: (value: boolean) => void;
    userLicense: License;
    companyLicense: License;
    renderUserLicense: boolean;
}) {
    return (
        <>
            <div className="atl-font-medium atl-pt-24">
                <FormattedMessage id="text_do_you_approve" />
            </div>
            {renderUserLicense && (
                <a
                    className="atl-block"
                    href={userLicense.content}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FormattedMessage id="text_user_terms" />
                </a>
            )}
            {showCompanyTerms && (
                <>
                    <a
                        className="atl-block"
                        href="https://www.tripletex.no/agreement/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FormattedMessage id="text_payment_conditions" />
                    </a>
                    <a
                        className="atl-block"
                        href={companyLicense.content}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FormattedMessage id="text_license" />
                    </a>
                </>
            )}
            <Fieldset className="atl-pt-24">
                <CheckboxGroup>
                    <Input
                        type="checkbox"
                        id="terms"
                        data-testid="terms-of-service-agreement-checkbox"
                        checked={termsChecked}
                        onChange={() => setTermsChecked(!termsChecked)}
                    />
                    <label htmlFor="terms">
                        <FormattedMessage id="text_agreement_licenses" />
                    </label>
                </CheckboxGroup>
            </Fieldset>
        </>
    );
}

function AskCanSign({
    canSign,
    setCanSign,
}: {
    canSign: boolean;
    setCanSign: (value: boolean) => void;
}) {
    return (
        <Fieldset className="atl-pt-24">
            <Legend>
                <FormattedMessage id="text_can_you_sign" />
            </Legend>
            <RadioGroup>
                <Input
                    type="radio"
                    id="canSign"
                    checked={canSign}
                    onChange={() => setCanSign(true)}
                    data-testid="terms-of-service-can-sign-yes-radio"
                />
                <label htmlFor="canSign">
                    <FormattedMessage id="text_yes" />
                </label>
            </RadioGroup>
            <RadioGroup>
                <Input
                    type="radio"
                    id="canSign"
                    checked={!canSign}
                    onChange={() => setCanSign(false)}
                    data-testid="terms-of-service-can-sign-no-radio"
                />
                <label htmlFor="canSign">
                    <FormattedMessage id="text_no" />
                </label>
            </RadioGroup>
        </Fieldset>
    );
}

function TermsOfServiceMessage({
    renderCompanyLicense,
}: {
    renderCompanyLicense: boolean;
}) {
    if (renderCompanyLicense) {
        const doubleBr = (
            <>
                <br />
                <br />
            </>
        );

        return (
            <FormattedMessage
                id="spacesuit_text_please_sign_license_custom"
                values={{
                    link: (
                        <a
                            href="https://www.tripletex.no/personvernerklaering/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            her
                        </a>
                    ),
                    br: doubleBr,
                    br2: doubleBr,
                }}
            />
        );
    }

    return (
        <FormattedMessage id="spacesuit_text_please_sign_license_not_admin" />
    );
}
